import { History, Transition } from 'history';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchSystemFilterLabels } from '../adapters/api/systems';
import { FilterKey, MenuItem, SystemDisplayState } from '../ComponentLibrary/src';
import { defaultBaseNotifications } from '../context/User';
import { Fault, FaultSeverity, NewNotifications, Notifications, State } from '../types';
import { SUBSCRIPTION_STATUSES } from '../util/constants';

export const appendClassProps = (className?: string | string[]): string => {
  if (Array.isArray(className)) {
    return className.reduce((final: string, name: string) => {
      if (name) {
        final = final + ' ' + name;
      }
      return final;
    }, '');
  }
  return className ? ' ' + className : '';
};

export const roundTo = (num: number, decimals: number): number => {
  return parseFloat(num.toFixed(decimals));
};

export const isSelected = (filter: FilterKey[], property: string, value: string | number): boolean => {
  const propertyEntry = filter.find((item) => item.id === property);
  const valueEntry = propertyEntry?.selectedValues?.find((val: MenuItem) => val.id === value);
  return valueEntry?.selected || false;
};

// TODO:
export const setDataUrlParams = (chartSources: { [x: string]: number }): void => {
  if (Object.keys(chartSources).length) {
    const newSearch = new URLSearchParams();
    for (const key of Object.keys(chartSources)) {
      newSearch.append('dataSource', key);
    }

    if (`?${newSearch.toString()}` !== document.location.search) {
      window.history.pushState(
        null,
        '',
        `${window.location.pathname}${newSearch.toString() ? '?' + newSearch.toString() : ''}`,
      );
    }
  }

  return;
};

export const warrantyToDropdownString = (warranty?: Date | string | number | null): string | number | null => {
  if (!warranty) return null;
  if (typeof warranty === 'number' && warranty > 0 && warranty <= 3) return warranty;
  return 'manual';
};

export const warrantySelectionToDate = (
  warrantySelection: string | number | null,
  warrantyExpirationDate?: Date | string,
  warrantyStandardDate?: Date | string,
  commissionDate?: Date | string,
): Date | undefined | string => {
  if (!warrantySelection) return warrantyStandardDate;
  if (warrantySelection === 'manual') return warrantyExpirationDate || new Date();
  if (!warrantyStandardDate || !commissionDate || typeof warrantySelection !== 'number') return;
  const baseDate = DateTime.fromJSDate(new Date(warrantyStandardDate as string));
  const _commissionDate = DateTime.fromJSDate(new Date(commissionDate as string));
  return DateTime.max(baseDate, _commissionDate).setZone('UTC+0').plus({ years: warrantySelection }).toJSDate();
};

/**
 * Strip time off date and convert to EOD for display purposes
 * Note: This may display the wrong time if user is in a timezone +UTC
 * But worst case, they get a free day of subscription
 * @param { Date | string | null } dateStr
 * @param { boolean } reverse
 */
export const toLocalTimezone = (dateStr?: Date | string | null, reverse?: boolean): Date | string | undefined => {
  if (dateStr === 'unlimited') return 'unlimited';
  if (!dateStr) return;

  try {
    if (DateTime.isDateTime(dateStr)) dateStr = dateStr.toISO();
    else if (typeof dateStr !== 'string') dateStr = DateTime.fromJSDate(dateStr).toISO();

    // remove timezone
    const result = DateTime.fromFormat(dateStr.replace(/T(.*)/, ''), 'yyyy-MM-dd');
    if (reverse) {
      // e.g. 2021-01-28T23:59:59.999Z
      return result.toUTC().endOf('day').toISO();
    }
    // e.g. 2021-01-28 23:59:59.999-7:00
    return result.endOf('day').toJSDate();
  } catch {
    return;
  }
};

/**
 * Strip time off date and convert to EOD UTC
 * @param {*} dateStr
 */
export const toUtc = (dateStr?: Date | string | null): string | undefined => {
  return toLocalTimezone(dateStr, true) as string | undefined;
};

export function formatDisplayState(state: string | number): {
  id: SystemDisplayState;
  label: string;
} {
  let label, id;
  // REFACTORME: could move this logic to the backend
  switch (state) {
    case State.enabled:
    case State.activating:
    case State.goToStandby:
      label = 'Enabled';
      id = SystemDisplayState.enabled;
      break;
    case State.disabled:
    case State.deactivating:
    case State.unknownSetup:
    case State.fillSeqInProcess:
    case State.fillSeqAborted:
    case State.fillSeqDone:
      label = 'Disabled';
      id = SystemDisplayState.disabled;
      break;
    case State.faulted:
      label = 'Fault';
      id = SystemDisplayState.faulted;
      break;
    case State.standby:
    case State.goToStandby:
      label = 'Standby';
      id = SystemDisplayState.standby;
      break;
    default:
      label = 'Unknown';
      id = SystemDisplayState.unknown;
      break;
  }

  return {
    id,
    label,
  };
}

// Download a file generated on the client side
export function download({
  filename,
  extension,
  content,
}: {
  filename: string;
  extension: string;
  content: string;
}): void {
  const element = document.createElement('a');
  const BOM = '%EF%BB%BF'; // Byte order mark - So the unicode characters are interpreted correctly by Excel (e.g. °)
  element.setAttribute(
    'href',
    `data:text/${extension === 'txt' ? 'plain' : extension};charset=utf-8,` + BOM + encodeURIComponent(content),
  );
  element.setAttribute('download', `${filename}.${extension}`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    toast('Copied to clipboard', { type: 'success' });
  } catch (err) {
    toast('Error copying to clipboard', {
      type: 'error',
    });
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string): void {
  if (!text) toast('Error copying to clipboard', { type: 'error' });
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      toast('Copied to clipboard', { type: 'success' });
    },
    () => {
      toast('Error copying to clipboard', {
        type: 'error',
      });
    },
  );
}

export function sortActiveFaults(faultOne: Fault, faultTwo: Fault): number {
  const rankFaultSeverity = (fault: Fault): number => {
    if (fault.severity === FaultSeverity.serviceRequired || fault.severity === FaultSeverity.internalFault) return 0;
    if (fault.severity === FaultSeverity.userClearableLock) return 1;
    if (fault.severity === FaultSeverity.autoSystemRestart) return 2;
    if (fault.severity === FaultSeverity.warning) return 3;
    if (fault.severity === FaultSeverity.info) return 4;
    if (fault.severity === FaultSeverity.external) return 5;
    return 0;
  };

  return rankFaultSeverity(faultOne) - rankFaultSeverity(faultTwo);
}

export const formatValue = (value?: number, maxFractionDigits?: number): string => {
  return value !== undefined ? parseFloat(value.toFixed(maxFractionDigits ?? 2)).toString() : '';
};

export async function loadOptionLabels(filter: FilterKey[]): Promise<FilterKey[]> {
  const staticLabels = ['sysId', 'fpcConfig.systemType', 'stats.state', 'systemDescription', 'model', 'productFamily'];
  // and populate the labels of the first selected option

  const labelsToFetch: Record<string, string[]> = {};
  for (const filterKey of filter) {
    if (!filterKey.selectedValues?.length) continue;

    if (staticLabels.includes(filterKey.id as string)) {
      filterKey.selectedValues = filterKey.selectedValues.map((value) => {
        value.label = value.id?.toString() ?? '';
        return value;
      });
    } else if (filterKey.id === 'subscriptionStatus') {
      filterKey.selectedValues = filterKey.selectedValues.map((value) => {
        value.label = SUBSCRIPTION_STATUSES[value.id as keyof typeof SUBSCRIPTION_STATUSES];
        return value;
      });
    } else {
      labelsToFetch[filterKey.id?.toString() ?? ''] = filterKey.selectedValues.map(
        (value) => value.id?.toString() ?? '',
      );
    }
  }
  if (!Object.keys(labelsToFetch).length) {
    return filter;
  } else {
    return fetchSystemFilterLabels(labelsToFetch).then((options) => {
      const newFilter = cloneDeep(filter).map((key) => {
        if (key.selectedValues?.length) {
          key.selectedValues = key.selectedValues
            ?.map((value) => {
              const option = options?.find((option) => option.id === value.id);
              if (option) {
                value.label = option.label;
              } else if (!staticLabels.includes(key.id as string)) {
                // remove keys to which the user may not have access or do not exist
                return null;
              }
              return value;
            })
            // remove keys to which the user may not have access or do not exist
            .filter((value) => value !== null) as MenuItem[];
        }
        return key;
      });
      return newFilter;
    });
  }
}

export const metersToFeet = (meters: number): number => {
  return meters * 3.28084;
};

type ExtendNavigator = Navigator & Pick<History, 'block'>;
export function useBlocker(blocker: (tx: Transition) => void, when = true): void {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export function usePrompt(message: string, when = true): void {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
}

export function usePromptUnsaved(when = true): void {
  const { t } = useTranslation('translation');

  usePrompt(t('Dirty Prompt'), when);
}

export function flattenNotifications(notifications?: Notifications): NewNotifications {
  const orgs: string[] = [];
  const sites: string[] = [];
  let systems: string[] = [];

  if (notifications)
    for (const org of notifications.orgs) {
      if (org.allSites) {
        orgs.push(org._id);
      } else {
        if (org.systems) systems = systems.concat(org.systems.map((sys) => sys.sysId));
        if (org.sites) {
          for (const site of org.sites) {
            if (site.allSystems) {
              sites.push(site._id);
            } else if (site.systems) {
              systems = systems.concat(site.systems.map((sys) => sys.sysId));
            }
          }
        }
      }
    }

  return {
    ...(notifications ?? defaultBaseNotifications),
    orgs,
    sites,
    systems,
  };
}

/**
 * A typesafe way to set value of properties on an object using dynamic keys
 * See https://www.typescriptlang.org/docs/handbook/2/generics.html#using-type-parameters-in-generic-constraints
 * @param obj - Object to mutate
 * @param key - field on the object to set
 * @param value - value to set on the field
 */
export function setValueTypesafe<Type>(obj: Type, key: keyof Type, value: Type[keyof Type]): void {
  obj[key] = value;
}
