import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../../../adapters/api';
import { patchActionLog, postSystemActionLog } from '../../../adapters/api/systems/actionLogs';
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  FormSave,
  FormStatus,
  Radio,
  Text,
  TextArea,
  TextType,
  Variant,
} from '../../../ComponentLibrary/src';
import ImageManager from '../../../components/ImageManager';
import { usePageContext } from '../../../components/Page';
import { AuthContext } from '../../../context/Auth';
import { SystemsContext } from '../../../context/Systems';
import { useMobile, useSetDocumentTitle } from '../../../hooks';
import { Image } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';

export default function EditActionLog(): JSX.Element {
  const user = Api.getUser() || {};
  const serverTime = Api.getServerTime();
  const { sysId, actionLogId } = useParams();
  const { hasPermissions } = useContext(AuthContext);
  const newMode = actionLogId === 'new';
  const title = newMode ? 'New Action Log' : 'Edit Action Log';
  useSetDocumentTitle(title);
  const navigate = useNavigate();
  const { getActionLog, getSystem, setActionLog, actionLog, system } = useContext(SystemsContext);
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [newActionLog, setNewActionLog] = useState(actionLog?.content);
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();
  const [internal, setInternal] = useState<boolean | undefined>(user.isInternal);
  const [acknowledge, setAcknowledge] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState<Image[]>([]);
  const [actionDate, setActionDate] = useState<Date>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation(['action_logs', 'translation']);
  const isMobile = useMobile();

  const enforcePermissions = useCallback(() => {
    if (newMode) {
      if (hasPermissions(PERMISSIONS.dashboard.actionLogs.add)) return;
    } else {
      const isSelf = actionLog?.email === user?.email;
      const canEdit = isSelf
        ? hasPermissions(PERMISSIONS.dashboard.ownActionLogs.update)
        : hasPermissions(PERMISSIONS.dashboard.othersActionLogs.update);

      if (canEdit) return;
    }

    toast(t('No Access'), { type: toast.TYPE.ERROR });
    navigate(`/systems/${sysId}/actionlogs`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPermissions, navigate, sysId, actionLog]);
  // Check user level and mode.
  const showImageManager = useMemo((): boolean | undefined => {
    return loading || !newMode || (newMode && hasPermissions(PERMISSIONS.dashboard.actionLogs.uploadPhotos));
  }, [loading, newMode, hasPermissions]);
  // Check org level
  const orgAllows = useMemo((): boolean | undefined => {
    return loading || !system?.org || system?.org.allowImages;
  }, [loading, system]);

  const scrollToError = useCallback(() => {
    const [errorEl] = document.querySelectorAll('div[data-id="error-message"]');
    if (errorEl) {
      errorEl.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, []);

  useEffect(() => {
    const promises = [
      getSystem({
        sysId: sysId || '',
        fetchSummit: false,
        onFail: ({ response }: AxiosError) => {
          if (response?.status === 403 || response?.status === 402) {
            navigate(`/systems/${sysId}`);
          }
        },
      }),
    ];

    if (!newMode) {
      promises.push(getActionLog(actionLogId as string) as Promise<void>);
    }

    Promise.all(promises).finally(() => {
      setLoading(false);
    });

    return () => {
      setActionLog(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMode, sysId]);

  useEffect(() => {
    if (newMode || actionLog) enforcePermissions();
    setNewActionLog(actionLog?.content);
    setImages([...(actionLog?.images || [])]);
    if (newMode) {
      setInternal(user.isInternal || false);
    } else {
      if (actionLog?.actionDate) {
        setActionDate(new Date(actionLog.actionDate));
      }
      setInternal(actionLog?.internal);
    }
  }, [actionLog, user.isInternal, newMode, enforcePermissions]);

  useEffect(() => {
    if (errorMessage) {
      scrollToError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const newImagesAdded = useCallback((newImages: Image[], oldImages: Image[]): boolean => {
    return JSON.stringify(oldImages) !== JSON.stringify(newImages);
  }, []);

  const handleSaveActionLog = () => {
    setFormStatus('loading');
    const contentChanged = newActionLog !== actionLog?.content;
    const imagesChanged = newImagesAdded(images, actionLog?.images || []);

    if (!actionDate) {
      setFormStatus('dirty');
      return setErrorMessage('Action date is required');
    }

    let aDate = undefined;
    aDate = DateTime.fromJSDate(actionDate).setZone('utc', { keepLocalTime: true }).endOf('day');
    if (aDate.toMillis() > serverTime.setZone('utc', { keepLocalTime: true }).endOf('day').toMillis()) {
      setFormStatus('dirty');
      return setErrorMessage('Action date cannot be in the future');
    } else {
      aDate = aDate.toISO();
    }

    if (newMode) {
      if (confirmExternal() && sysId) {
        return postSystemActionLog(sysId, aDate, internal, newActionLog, images).then((response) => {
          if (response) {
            const r = response as { insertedId: string };
            toast(t('action_log_saved'), { type: toast.TYPE.SUCCESS });
            navigate(`/systems/${sysId}/actionlogs?focusedItem=${r.insertedId}`);
          }
        });
      } else {
        // User set to external but did not accept.
        toast(t('must_acknowledge_external'), { type: toast.TYPE.WARNING });
        setFormStatus('dirty');
      }
    } else {
      if (confirmExternal() && actionLogId) {
        patchActionLog(
          actionLogId,
          internal,
          contentChanged ? newActionLog : undefined,
          imagesChanged ? images : undefined,
          aDate,
        ).then((response) => {
          if (response) {
            toast(t('action_log_saved'), { type: toast.TYPE.SUCCESS });
            navigate(`/systems/${sysId}/actionlogs?focusedItem=${actionLogId}`);
          } else {
            setFormStatus(undefined);
          }
        });
      } else {
        // User set to external but did not accept.
        toast(t('must_acknowledge_external'), { type: toast.TYPE.WARNING });
        setFormStatus('dirty');
      }
    }
  };

  const confirmExternal = () => {
    let confirmed;
    if (user.isInternal && !internal) confirmed = confirm(t('external_message'));

    if (internal) {
      // Action log marked as internal true
      return user.isInternal;
    } else {
      return (user.isInternal && confirmed) || !user.isInternal;
    }
  };

  const handleCancelSaveActionLog = () => {
    if (newMode) {
      history.back();
    } else {
      setFormStatus(undefined);
      setNewActionLog(actionLog?.content);
      setInternal(actionLog?.internal);
      setAcknowledge(false);
      setImages(actionLog?.images || []);
      if (actionLog?.actionDate) {
        setActionDate(new Date(actionLog.actionDate));
      }
      setErrorMessage('');
    }
  };

  const handleChangeActionLog = (actionLog: string) => {
    setNewActionLog(actionLog);
    setFormStatus('dirty');
  };

  const handleChangeImagesList = (list: Image[]) => {
    setImages(list);
    setFormStatus('dirty');
  };

  const handleIsExternal = (checked: unknown) => {
    if (!newMode) setFormStatus('dirty');

    setInternal(!!checked);
  };

  const handleError = (filename: string, error: Error) => {
    const toastMessage = (
      <>
        <div>
          <strong>{t('upload_image_error', { filename })}</strong>
        </div>
        {error && <div>{error.message}</div>}
      </>
    );
    toast(toastMessage, {
      type: toast.TYPE.ERROR,
      autoClose: false,
    });
  };

  const handleActionDate = (value?: Date) => {
    if (value) {
      setActionDate(value);
      setErrorMessage(undefined);
      setFormStatus('dirty');
    }
  };

  useEffect(() => {
    setTitle(title);
    setBreadcrumbs([
      { text: 'Systems', href: '/systems' },
      { text: sysId as string, href: `/systems/${sysId}` },
      { text: 'Action Logs' },
    ]);
    setScrollable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, sysId]);

  return (
    <Card className="lg:w-1/2 m-4">
      <Form preventDefault onSubmit={handleSaveActionLog} className="flex flex-col w-full gap-4">
        <Text wrap overflow="" type={TextType.h4}>
          {t('action_date')}
        </Text>
        <div className="flex flex-row gap-2">
          <DatePicker
            className={`${isMobile ? 'w-full' : 'flex-1'}`}
            tooltip={t('action_date_tooltip')}
            date={actionDate}
            onChange={handleActionDate}
            loading={loading}
            data-pwid="action-log-action-date"
            maxDate={serverTime.toJSDate()}
            errorMessage={errorMessage}
            revealErrorMessage
            disabled={loading || formStatus === 'loading'}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleActionDate(serverTime.toJSDate());
            }}
            variant={Variant.secondaryFilled}
            size={isMobile ? 'normal' : 'large'}
            data-pwid="action-log-action-date-today"
            disabled={loading || formStatus === 'loading'}
          >
            {t('Today')}
          </Button>
        </div>
        <Text wrap overflow="" type={TextType.h4}>
          {t('content')}
        </Text>
        <TextArea
          autoFocus
          rows={10}
          className="w-full"
          onChangeValue={handleChangeActionLog}
          value={newActionLog}
          loading={loading}
          hideErrorSection
          disabled={loading || formStatus === 'loading'}
          data-pwid="action-log-content"
        />
        {user.isInternal && (
          <React.Fragment>
            <Text wrap className="mt-4" overflow="" type={TextType.h4}>
              {t('visibility')}
            </Text>
            <Radio.Group
              name="action-log-visibility"
              onChange={handleIsExternal}
              value={!!internal}
              disabled={loading || formStatus === 'loading'}
            >
              <Radio
                value={true}
                label={t('internal')}
                tooltip={t('visibility_help')}
                data-pwid="internal-visibility-button"
              />
              <Radio value={false} label={t('external')} data-pwid="external-visibility-button" />
            </Radio.Group>
          </React.Fragment>
        )}
        {orgAllows ? (
          showImageManager && (
            <>
              {hasPermissions(PERMISSIONS.dashboard.actionLogs.uploadPhotos) && (
                <React.Fragment>
                  <Text wrap className="mt-4" overflow="" type={TextType.h4}>
                    {t('upload_images')}
                  </Text>
                  {!loading && (
                    <Checkbox
                      checked={acknowledge}
                      onChangeValue={setAcknowledge}
                      label={t('upload_terms')}
                      tooltip={t('must_check_checkbox')}
                    />
                  )}
                </React.Fragment>
              )}
              <ImageManager
                imagesList={images}
                systemId={sysId as string}
                setImagesList={handleChangeImagesList}
                handleError={handleError}
                handleLoading={() => setFormStatus('loading')}
                loading={loading}
                disabled={
                  formStatus === 'loading' ||
                  !hasPermissions(PERMISSIONS.dashboard.actionLogs.uploadPhotos) ||
                  !acknowledge
                }
              />
            </>
          )
        ) : (
          <Text overrideColor className="text-gray-400" wrap>
            {t('does_not_allow_images', { org: system?.org?.name })}
          </Text>
        )}
        <FormSave className="self-end" onCancel={handleCancelSaveActionLog} mode="edit" status={formStatus} />
      </Form>
    </Card>
  );
}
