import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { downloadSystemTimeline, fetchEventFieldKeys } from '../../../adapters/api/systems/timeline';
import {
  Button,
  Filter,
  FilterKey,
  filterToSearchParams,
  HeroIcons,
  IndicatorTier,
  PageHeader,
  searchParamsToFilter,
  Signal,
  Text,
  Variant,
} from '../../../ComponentLibrary/src';
import InfiniteScroll from '../../../components/InfiniteScroll';
import { Pagination } from '../../../components/InfiniteScroll/utils';
import { usePageContext } from '../../../components/Page';
import TabNavigation from '../../../components/System/TabNavigation';
import { TimelineItemType } from '../../../components/System/util';
import StatusIndicator from '../../../components/Systems/StatusIndicator';
import { ITimelineItem, SystemsContext } from '../../../context/Systems';
import { useMobile, useMounted, useSetDocumentTitle } from '../../../hooks';
import i18n from '../../../i18n';
import { Fault } from '../../../types';
import { download } from '../../../util';
import { defaultTimelineFilter, TimelineItemsPretty } from '../../../util/constants';
import { parseSignalStrength } from '../util';
import EventItem from './EventItem';
import { formatFaultDumpCsv } from './util';

const BUFFER_SIZE = 300;
const PAGE_SIZE = 50;

interface EventsQuery {
  sysId: string;
  filter: FilterKey[];
  countPerPage: number;
  sort: { ts: number };
  focusedItem: string;
  onFail?: ({ response }: AxiosError) => void;
}

export default function Timeline(): JSX.Element {
  const { t } = useTranslation('events');
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParamsToFilter(searchParams, defaultTimelineFilter));
  const { getSystemTimeline, system, summitInfo, getSystem } = useContext(SystemsContext);
  const { sysId } = useParams();
  useSetDocumentTitle(`${sysId} | Events`);
  const navigate = useNavigate();
  const isMobile = useMobile();
  const isMounted = useMounted();

  const onFailHandler = useCallback(
    ({ response }: AxiosError) => {
      if (response?.status === 402 || response?.status === 403) {
        navigate(`/systems/${sysId}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [query, setQuery] = useState<EventsQuery>({
    sysId: sysId as string,
    filter: searchParams.get('focusedItem') ? defaultTimelineFilter : filter,
    countPerPage: PAGE_SIZE,
    sort: {
      ts: -1,
    },
    focusedItem: searchParams.get('focusedItem') ?? '',
    onFail: onFailHandler,
  });

  // Re-wire tooltips
  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  });

  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();

  useEffect(() => {
    setTitle('');
    setBreadcrumbs();
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId]);

  const updateFilterAndQuery = useCallback(
    (newFilter: FilterKey[], focusedItem: string) => {
      const { onFail, ...origQuery } = query;
      const newQuery = structuredClone(origQuery);
      setFilter(newFilter);

      newQuery.focusedItem = focusedItem;
      newQuery.filter = newFilter;
      // (updating query triggers a fetch in the infinite scroll hook)
      setQuery({
        ...newQuery,
        onFail,
      });
    },
    [query],
  );

  // set focusedItem or filter when search param changes
  useEffect(() => {
    const urlFilter = searchParamsToFilter(searchParams, filter);

    const focusedItem = searchParams.get('focusedItem') ?? '';

    // if focusedItem exists and changes, update query
    if (focusedItem && query.focusedItem !== focusedItem) {
      updateFilterAndQuery(defaultTimelineFilter, focusedItem);
      return;
    }

    // update state if filter in URL changed, but only after mount or a
    // filter from the URL will trigger a double fetch
    if (isMounted && !isEqual(urlFilter, filter)) {
      updateFilterAndQuery(urlFilter, '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangeFilter = (newFilter: FilterKey[]) => {
    // Clear all search params except the filter. If we add sort, we will want to modify this logic

    const newSearchParams = filterToSearchParams(newFilter);
    setSearchParams(newSearchParams);
  };

  const handleExportFaultDump = (item: ITimelineItem) => {
    const dateString = DateTime.fromISO(item.ts as string)
      .toUTC()
      .toFormat(`yyyyMMdd'T'HHmmss`);
    const filename = sysId + '_' + dateString + '_FAULT_' + (item.data?.fault as Fault)?.faultCode;
    const content = formatFaultDumpCsv(item);
    download({ filename, extension: 'csv', content });
  };

  const updateUrlParams = useCallback(
    (after?: Pagination['after']) => {
      const newSearch = new URLSearchParams(searchParams);
      if (!after) {
        newSearch.delete('after');
      } else {
        newSearch.set('after', after);
      }

      if (`?${newSearch.toString()}` !== document.location.search) {
        setSearchParams(newSearch, {
          replace: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams],
  );

  const updateTimeline = useCallback(
    async (pagination: Pagination): Promise<React.ReactElement[]> => {
      // only get focusedItem on first fetch
      if (!pagination.before && !pagination.after && query.focusedItem) pagination.after = query.focusedItem;
      return getSystemTimeline({ ...pagination, ...query })
        .then((response) => {
          const newItems = response?.events.map((item) => {
            const key = `${new Date(item.ts).getTime()}-${item._id}`;
            return (
              <EventItem
                item={item}
                key={key}
                focused={key === query.focusedItem}
                hasFilter={query.filter.some((filter) => filter.selected)}
                onExportFaultDump={handleExportFaultDump}
              />
            );
          });

          if (newItems?.length) {
            updateUrlParams(pagination.before ? newItems[newItems.length - 1].key?.toString() : pagination.after);
          } else if (pagination.before) {
            updateUrlParams();
          }

          return newItems;
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  useEffect(() => {
    setLoading(true);
  }, [query]);

  useEffect(() => {
    if (sysId) {
      getSystem({
        sysId,
        fetchSummit: true,
        project: ['stats.state', 'site.name', 'subscriptionStatus'],
        onFail: onFailHandler,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId]);

  const handleExport = () => {
    downloadSystemTimeline({
      sysId: sysId as string,
      filter: query.filter,
      sort: {
        ts: -1,
      },
    });
  };
  const actions = (
    <div className="flex flex-row gap-4 items-center justify-between">
      <Button icon={HeroIcons.DownloadIcon} className="w-max" variant={Variant.secondaryFilled} onClick={handleExport}>
        {isMobile ? '' : 'Export'}
      </Button>
    </div>
  );

  const handleLoadOptions = async (key: string, searchTerm?: string, onlySelected?: boolean) => {
    if (key === 'ts') return;
    let { data } = await fetchEventFieldKeys(key, sysId as string, query.filter, onlySelected);

    data = data.map((option) => {
      if (option.id === '') {
        option.label = i18n.t('system:no_event_code') as string;
      } else if (key === 'evtType') {
        option.label = TimelineItemsPretty[option.id as TimelineItemType] as string;
      }
      return option;
    });

    if (searchTerm) {
      data = data.filter((option) => (option.label?.toString() ?? '').toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return {
      data,
      count: data.length,
    };
  };

  const signalStrength = useMemo(() => parseSignalStrength(summitInfo), [summitInfo]);

  return (
    <div
      className={`${isMobile ? 'pt-2 px-2 mb-16' : 'p-4'} flex flex-1 flex-col gap-3 bg-blue-50 overflow-hidden`}
      id="content"
      onScroll={() => window.dispatchEvent(new Event('scrollPage'))}
    >
      <PageHeader
        title={sysId}
        subtitle={loading ? '' : system?.site?.name}
        breadcrumbs={[{ text: 'Systems', href: '/systems' }, { text: sysId ?? '' }]}
        leftComponent={<StatusIndicator system={system} tier={IndicatorTier.two} isLoading={loading} />}
        rightComponent={
          <>
            <Signal signalStrength={signalStrength} isLoading={loading} className="h-6 w-6" />
          </>
        }
        bottomBorder
      >
        <TabNavigation
          sysId={sysId}
          pageSelected="events"
          subscriptionStatus={loading ? '' : system?.subscriptionStatus || ''}
        />
      </PageHeader>
      <div className="flex">
        {/* TODO: refactor to be a controlled component that doesn't read/modify URL */}
        <Filter filter={filter} onChangeFilter={handleChangeFilter} loadOptions={handleLoadOptions} />
        {actions}
      </div>
      <div className={`flex flex-col flex-1 ${isMobile ? 'gap-2 overflow-x-hidden' : 'gap-4'} overflow-hidden`}>
        <InfiniteScroll
          maxBufferSize={BUFFER_SIZE}
          triggerThresholdItems={PAGE_SIZE / 2}
          loadItems={updateTimeline}
          focusedItem={query.focusedItem}
          searchParams={searchParams}
          triggerLoadItems={query}
          noItemsFound={
            <div className="flex flex-col items-center">
              <Text>{t('no_data')}</Text>
            </div>
          }
        />
      </div>
    </div>
  );
}
