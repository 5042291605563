import { toast } from 'react-toastify';

import { ReactSelectOption } from '../ComponentLibrary/src';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;

// TODO: fetch the key from the API (must be auth'ed)
export const gmApiKey = 'AIzaSyDOTSteXjjcqo_j7qKULorTbsCN0AD4l5s';

interface GooglePlacePrediction {
  description: string;
  place_id: string;
}

/**
 * Gets react select options from a google maps autocomplete search
 */
export const autoCompleteLocation = async (input: string): Promise<ReactSelectOption[]> => {
  try {
    if (!input) return [];
    const service = new google.maps.places.AutocompleteService();

    return new Promise((resolve, reject) =>
      service.getQueryPredictions({ input }, (predictions: GooglePlacePrediction[], status: string) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const results = predictions.map((prediction: GooglePlacePrediction) => ({
            value: prediction.place_id,
            label: prediction.description,
          }));
          resolve(results);
        } else {
          reject(status);
        }
      }),
    );
  } catch {
    toast('Error fetching google places results', { type: 'error' });
    return [];
  }
};

interface GooglePlaceDetails {
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}

/**
 * Gets the location details from the Google Maps API for a given place ID
 * @param placeId Google Maps Place ID
 * @returns Location details
 */
export const getPlaceDetails = async (placeId: string): Promise<GooglePlaceDetails | null> => {
  if (!placeId) return null;
  const service = new google.maps.places.PlacesService(document.createElement('div'));

  return new Promise((resolve, reject) =>
    service.getDetails({ placeId }, (result: GooglePlaceDetails, status: string) => {
      if (status !== 'OK') {
        reject(status);
      } else {
        resolve(result);
      }
    }),
  );
};
