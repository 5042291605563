import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, DatePicker, Text, TextType } from '../../../ComponentLibrary/src';
import { AuthContext } from '../../../context/Auth';
import { PERMISSIONS } from '../../../util/constants';
import { SystemDatesProps } from './types';

export const SystemDates: React.FC<SystemDatesProps> = ({
  isMobile,
  isLoading,
  hasSubscriptionExpirationDate,
  hasWarrantyExpirationDate,
  commissionDate,
  shipDate,
  lastAnnualInspectionDate,
  isValidShipAndCommissionDates,
  handleChangeShipDate,
  handleChangeCommissionDate,
  handleChangeLastAnnualInspectionDate,
}) => {
  const { t } = useTranslation(['translation', 'system']);
  const { hasPermissions } = useContext(AuthContext);
  const { update, updateShipDate, updateProductAccess } = PERMISSIONS.dashboard.systems;
  const shipDateErrorMessage = useMemo(() => {
    if (!shipDate && !!commissionDate) return t('system:ship_date_missing_error');

    if (!isValidShipAndCommissionDates()) {
      return t('system:ship_commission_date_invalid');
    }
  }, [shipDate, commissionDate, isValidShipAndCommissionDates, t]);

  const canUpdateShipDate =
    hasWarrantyExpirationDate || hasSubscriptionExpirationDate
      ? hasPermissions([updateShipDate, updateProductAccess])
      : hasPermissions(updateShipDate);

  const canUpdateCommissionDate =
    hasWarrantyExpirationDate || hasSubscriptionExpirationDate
      ? hasPermissions([update, updateProductAccess]) && !!shipDate
      : hasPermissions(update) && !!shipDate;

  return (
    <Card>
      <div className="flex flex-col w-full gap-2">
        <Text type={TextType.h3}>{t('Dates')}</Text>
        <DatePicker
          className={`${isMobile ? 'w-full' : 'flex-1'}`}
          label={t('system:ship_date')}
          tooltip={t('system:ship_date_help_text')}
          date={shipDate}
          onChange={handleChangeShipDate}
          loading={isLoading}
          disabled={!canUpdateShipDate}
          revealErrorMessage={!!shipDateErrorMessage}
          errorMessage={shipDateErrorMessage}
          data-pwid="ship-date-picker"
          data-testid="ship-date-picker"
        />
        <DatePicker
          className={`${isMobile ? 'w-full' : 'flex-1'}`}
          label={t('system:commission_date')}
          tooltip={t('system:commission_date_help_text')}
          date={commissionDate}
          onChange={handleChangeCommissionDate}
          loading={isLoading}
          disabled={!canUpdateCommissionDate}
          hideErrorSection={!canUpdateCommissionDate}
          data-pwid="commission-date-picker"
          data-testid="commission-date-picker"
          helpText={canUpdateCommissionDate ? undefined : t('system:ship_date_missing_error')}
        />
        <DatePicker
          className={`${isMobile ? 'w-full' : 'flex-1'}`}
          label={t('system:last_inspection_date')}
          tooltip={t('system:last_inspection_date_help_text')}
          date={lastAnnualInspectionDate}
          onChange={handleChangeLastAnnualInspectionDate}
          loading={isLoading}
          disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
          data-pwid="last-annual-inspection-date-picker"
          data-testid="last-annual-inspection-date-picker"
        />
      </div>
    </Card>
  );
};
