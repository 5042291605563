import React, { ReactNode, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import Skeleton from 'react-loading-skeleton';

import { Card } from '../Card';
import { Input } from '../Input';
import colors from '../style/colors';
import { HeroIcons } from '../style/heroicons';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { ListItem, SearchableListProps } from './index.types';

function SearchableListItem({
  component,
  id,
  label,
  value,
  loading,
  highlightOnSelected,
  chartSelectEnabled,
  color,
  chartSelectAxis,
  handleClickOption,
}: {
  component?: ReactNode;
  id?: ListItem['id'];
  label?: string | number | null;
  value?: ListItem['id'];
  loading?: boolean;
  highlightOnSelected?: boolean;
  chartSelectEnabled?: boolean;
  color?: string;
  chartSelectAxis?: 1 | 2;
  handleClickOption?: (id: ListItem['id'], chart?: 1 | 2 | undefined) => void;
}) {
  const [chartSelected, setChartSelected] = useState<1 | 2 | undefined>(chartSelectAxis);

  return !!component ? (
    <div
      key={id?.toString() ?? ''}
      className={`${chartSelectEnabled ? 'cursor-pointer' : ''}`}
      onClick={() => {
        if (id !== undefined && handleClickOption) handleClickOption(id);
      }}
      data-pwid={label ?? id?.toString() ?? ''}
    >
      {component}
    </div>
  ) : (
    <div
      key={id?.toString() ?? ''}
      className={`${
        id === value && !loading && highlightOnSelected ? 'text-white bg-blue-800' : 'highlight-color text-blue-800'
      } p-2 flex justify-between`}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Text
            type={TextType.body}
            overrideColor
            wrap
            onClick={() => {
              if (id !== undefined && handleClickOption) {
                if (chartSelectEnabled) {
                  const newChartSelected = chartSelected === 1 ? 2 : chartSelected === 2 ? undefined : 1;
                  setChartSelected(newChartSelected);
                  handleClickOption(id, newChartSelected);
                } else {
                  handleClickOption(id);
                }
              }
            }}
            data-pwid={label?.toString() ?? id?.toString() ?? ''}
          >
            {label?.toString() ?? id?.toString() ?? ''}
          </Text>
          {chartSelectEnabled && (
            <div className="flex gap-2">
              <div
                className={`h-5 w-5 rounded-full cursor-pointer${chartSelected === 1 ? '' : ' opacity-20'}`}
                style={{
                  backgroundColor: chartSelected ? color : colors.blue['800'],
                }}
                onClick={() => {
                  const newChartSelected = chartSelected === 1 ? undefined : 1;
                  setChartSelected(newChartSelected);
                  if (handleClickOption && id !== undefined) handleClickOption(id, newChartSelected);
                }}
              />
              <div
                className={`h-5 w-5 rounded-full cursor-pointer${chartSelected === 2 ? '' : ' opacity-20'}`}
                style={{
                  backgroundColor: chartSelected ? color : colors.blue['800'],
                }}
                onClick={() => {
                  const newChartSelected = chartSelected === 2 ? undefined : 2;
                  setChartSelected(newChartSelected);
                  if (handleClickOption && id !== undefined) handleClickOption(id, newChartSelected);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function DraggableSearchableListItem({
  component,
  id,
  label,
  value,
  draggableType,
  loading,
  highlightOnSelected,
  onDragStateChange,
  handleClickOption,
}: {
  component?: ReactNode;
  id?: ListItem['id'];
  label?: string | number | null;
  value?: ListItem['id'];
  draggableType?: string;
  loading?: boolean;
  highlightOnSelected?: boolean;
  onDragStateChange?: (dragging: boolean) => void;
  handleClickOption?: (id: ListItem['id'], chart?: 1 | 2 | undefined) => void;
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: draggableType || 'SearchableListItem',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      id,
    },
  }));

  useEffect(() => {
    if (onDragStateChange) onDragStateChange(isDragging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return !!component ? (
    <div
      ref={drag}
      key={id?.toString() ?? ''}
      className={`cursor-pointer`}
      onClick={() => {
        if (id !== undefined && handleClickOption) handleClickOption(id);
      }}
      data-pwid={`draggable-${label?.toString() ?? id?.toString() ?? ''}`}
    >
      {component}
    </div>
  ) : (
    <div
      ref={drag}
      key={id?.toString() ?? ''}
      className={`cursor-pointer ${
        id === value && !loading && highlightOnSelected ? 'text-white bg-blue-800' : 'highlight-color text-blue-800'
      } p-2`}
      onClick={() => {
        if (id !== undefined && handleClickOption) handleClickOption(id);
      }}
      data-pwid={`draggable-${label?.toString() ?? id?.toString() ?? ''}`}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Text type={TextType.body} overrideColor>
          {label?.toString() ?? id?.toString() ?? ''}
        </Text>
      )}
    </div>
  );
}

/**
 - Use SearchableList to select from a long list of scrollable options
 */
export const SearchableList: React.FC<SearchableListProps> = ({
  options,
  className,
  value,
  draggable,
  draggableType,
  loading,
  highlightOnSelected = true,
  chartSelectEnabled,
  selectionColors,
  card,
  onDragStateChange,
  onSelect,
  'data-pwid': dataPwId = 'searchable-list',
}: SearchableListProps) => {
  const [displayedOptions, setDisplayedOptions] = useState(options);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setDisplayedOptions(
      searchText
        ? options.filter(({ label }: ListItem) =>
            (label?.toString() ?? '').toLowerCase().includes((searchText as string).toLowerCase()),
          )
        : options,
    );
  }, [searchText, options]);

  const handleChangeSearch = (text: string | number) => {
    setSearchText(text as string);
  };

  const handleClickOption = (id: ListItem['id'], chart?: 1 | 2 | undefined) => {
    onSelect(id, chart);
  };

  const content = loading
    ? [...Array(5)].map((_, i) => <SearchableListItem key={i} loading />)
    : displayedOptions?.map(({ id, label, component, chartSelectAxis }: ListItem) => {
        const color = selectionColors
          ? Array.from(selectionColors).find(([, dataSources]) => dataSources.includes(id?.toString() ?? ''))?.[0]
          : undefined;

        return draggable ? (
          <DraggableSearchableListItem
            key={id?.toString() ?? ''}
            id={id}
            label={label}
            component={component}
            handleClickOption={handleClickOption}
            value={value}
            draggableType={draggableType}
            onDragStateChange={onDragStateChange}
            highlightOnSelected={highlightOnSelected}
          />
        ) : (
          <SearchableListItem
            key={id?.toString() ?? ''}
            id={id}
            label={label}
            component={component}
            handleClickOption={handleClickOption}
            value={value}
            highlightOnSelected={highlightOnSelected}
            chartSelectEnabled={chartSelectEnabled}
            chartSelectAxis={chartSelectAxis}
            color={color}
          />
        );
      });

  return (
    <div
      className={`flex flex-col overflow-hidden${appendClassProps(className)}`}
      data-testid="searchableList"
      data-pwid={dataPwId}
    >
      <Input
        leftIcon={HeroIcons.SearchIcon}
        onChangeValue={handleChangeSearch}
        hideErrorSection
        data-pwid={`${dataPwId}-input-box`}
      />
      {card ? (
        <Card className={`flex flex-col ${loading ? '' : 'overflow-y-auto '}`}>{content}</Card>
      ) : (
        <div className={`flex flex-col ${loading ? '' : 'overflow-y-auto '}`}>{content}</div>
      )}
    </div>
  );
};

export * from './index.types';
