import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Radio } from '../../ComponentLibrary/src';
import { NewNotifications, NotificationMode } from '../../types';
import { NotificationType, UnsubscribeOption, UnsubscribeTokenPayload } from './types';

export interface UnsubscribeSelection extends Omit<UnsubscribeOption, 'type'> {
  type: 'org' | 'site' | 'system' | 'type' | 'all';
}

export default function Options({
  token,
  notificationSettings,
  onSelectionChange,
}: {
  token?: UnsubscribeTokenPayload;
  notificationSettings?: NewNotifications;
  onSelectionChange: (value: UnsubscribeSelection) => void;
}): JSX.Element {
  const { t } = useTranslation('unsubscribe');
  const [selectedOption, setSelectedOption] = useState<UnsubscribeOption>();

  const typeOptions = useMemo(
    () => ({
      [NotificationType.events]: 'FAULT, WARNING, & INFO',
      [NotificationType.connectivity]: 'CONNECTIVITY',
      [NotificationType.dailyReport]: 'DAILY REPORT',
      [NotificationType.weeklyReport]: 'WEEKLY REPORT',
      [NotificationType.releaseNotes]: 'RELEASE NOTES',
    }),
    [],
  );

  // Decide which asset the user will unsubscribe from
  const getAssetOption = useCallback(() => {
    // For cleaner type checking
    const eventToken = token as UnsubscribeTokenPayload;
    const asset: UnsubscribeOption = {
      type: 'system',
      label: eventToken?.sysId ?? '',
      value: eventToken?.sysId ?? '',
    };

    if (notificationSettings?.mode === NotificationMode.Whitelist) {
      if (eventToken?.org?.id && notificationSettings?.orgs.includes(eventToken.org.id)) {
        asset.type = 'org';
        asset.label = eventToken.org.name;
        asset.value = eventToken.org.id;
      } else if (eventToken?.site?.id && notificationSettings?.sites.includes(eventToken.site.id)) {
        asset.type = 'site';
        asset.label = eventToken.site.name;
        asset.value = eventToken.site.id;
      }
    } else if (eventToken?.site?.id) {
      asset.type = 'site';
      asset.label = eventToken.site.name;
      asset.value = eventToken.site.id;
    }

    return asset;
  }, [notificationSettings?.mode, notificationSettings?.orgs, notificationSettings?.sites, token]);

  const handleSelectionChange = (option: unknown) => {
    try {
      const selection = JSON.parse(option as string);
      setSelectedOption(selection as UnsubscribeOption);
      onSelectionChange({
        type: (selection as UnsubscribeOption).type,
        value: (selection as UnsubscribeOption).value,
      });
    } catch {}
  };

  if (!token) return <></>;

  return (
    <Radio.Group name="notification-systems" onChange={handleSelectionChange} value={JSON.stringify(selectedOption)}>
      {token.options.map((option) => {
        const unsubscribeOption: UnsubscribeOption = {
          type: 'all',
        };
        const i18nKey = option.type;
        const values: Record<string, unknown> = {};
        if (option.type === 'event') {
          const assetOption = getAssetOption();
          unsubscribeOption.label = assetOption.label;
          unsubscribeOption.type = assetOption.type;
          unsubscribeOption.value = assetOption.value;
          values.asset = assetOption.label;
        } else if (option.type === 'type') {
          unsubscribeOption.type = option.type as UnsubscribeOption['type'];
          unsubscribeOption.value = option.value === 'weeklyReport' ? 'expirations' : option.value;
          values.type = typeOptions[option.value as NotificationType];
        }
        return (
          <Radio
            key={unsubscribeOption.value ?? unsubscribeOption.type}
            value={JSON.stringify(unsubscribeOption)}
            label={
              <Trans t={t} i18nKey={i18nKey} values={values}>
                <strong></strong>
              </Trans>
            }
          />
        );
      })}
    </Radio.Group>
  );
}
