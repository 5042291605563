import '../../style/notifications.scss';

import { InformationCircleIcon } from '@heroicons/react/solid';
import { Divider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Api from '../../adapters/api';
import { fetchCurrentUser, putUserNotifications } from '../../adapters/api/user';
import {
  Button,
  Card,
  Form,
  FormSave,
  FormStatus,
  Radio,
  Select,
  SelectValue,
  Text,
  TextType,
  Variant,
} from '../../ComponentLibrary/src';
import { Type } from '../../ComponentLibrary/src/Button';
import { getUser, usePageContext } from '../../components/Page';
import { SystemsContext, useSystemsContextValue } from '../../context/Systems';
import { userContextDefaultValue } from '../../context/User';
import { useMobile, useSetDocumentTitle } from '../../hooks';
import {
  BatchType,
  NewNotifications,
  NotificationMode,
  NotificationPriority,
  Notifications,
  NotificationType,
} from '../../types';
import { usePromptUnsaved } from '../../util';
import ClickableListItem from './components/ClickableListItem';
import ModalSelect from './components/ModalSelect';
import SelectedList from './components/SelectedList';
import { GenericValueReactSelectOption, HierarchicalAssets, MenuItem } from './types';
import { buildSystemMenutItems, filterRecursively, menuItemBuilder, toggleAssetOption, updateList } from './util';

function NotificationsPage(): JSX.Element {
  useSetDocumentTitle('Notifications');
  const { t } = useTranslation(['notifications', 'translation', 'system']);
  const isMobile = useMobile();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const location = useLocation();
  const { notifications } = Api.getUser() || {};
  const [changes, setChanges] = useState<Notifications>({
    ...userContextDefaultValue.userNotifications,
    ...(notifications ?? {}),
  });
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const [selectedSiteId, setSelectedSiteId] = useState<string>();
  const [selectedAssets, setSelectedAssets] = useState<MenuItem[]>([]);

  useEffect(() => {
    setTitle(t('notification_settings'));
    setBreadcrumbs();
    setScrollable(false);
    if (location.key !== 'default') getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePromptUnsaved(formStatus === 'dirty');

  /**
   * Make api call with new notifications object, the full object not a diff
   *
   * @param selectedAssets All orgs, sites, and systems selected
   * @returns Promise
   */
  const handleSaveNotifications = (selectedAssets: MenuItem[]): Promise<void> => {
    setFormStatus('loading');
    const orgs: string[] = [];
    const sites: string[] = [];
    const systems: string[] = [];

    selectedAssets.forEach((item) => {
      if (item.selected) {
        if (item.isSystem) {
          systems.push(item.id);
        } else {
          item.subItems = [];
          orgs.push(item.id);
        }
      }

      if (item.isIndeterminate()) {
        // Sites and systems
        item.subItems.forEach((subItem) => {
          if (subItem.selected) {
            if (subItem.isSystem) {
              systems.push(subItem.id);
            } else {
              sites.push(subItem.id);
            }
          }
          // Systems only
          if (subItem.isIndeterminate()) {
            subItem.subItems.forEach(({ id }) => systems.push(id));
          }
        });
      }
    });

    const allChanges: NewNotifications = {
      orgs,
      sites,
      systems,
      mode: changes.mode,
      fault: changes.fault,
      warning: changes.warning,
      info: changes.info,
      connectivity: changes.connectivity,
      expirations: changes.expirations,
      dailyReport: changes.dailyReport,
      releaseNotes: changes.releaseNotes,
      batchWindows: changes.batchWindows,
    };

    return putUserNotifications({
      notifications: allChanges,
      onFail: (err) => {
        toast(t('settings_error'), {
          type: toast.TYPE.ERROR,
        });
        throw err;
      },
    })
      .then((res) => {
        if (!(res as unknown)) throw new Error();
        return fetchCurrentUser({ project: ['notifications'] });
      })
      .then((res) => {
        if (!(res as unknown)) throw new Error();
        toast(t('settings_updated'), {
          type: toast.TYPE.SUCCESS,
        });
        cleanupForm();
      })
      .catch(() => {
        setFormStatus('dirty');
      });
  };
  /**
   * Helper function to init the selected list and when user selects discard.
   */
  const setInitialSelectedItems = useCallback(() => {
    const orgs: MenuItem[] = changes.orgs.map(({ _id, name, allSites, sites, systems }) => {
      const orgSystems = systems.map((system) => {
        return { ...system, selected: true };
      });
      const sitesSubItems: HierarchicalAssets['orgName']['sites'] = {};

      sites.forEach(({ _id, name, systems: siteSystems, allSystems }) => {
        sitesSubItems[name ?? _id] = {
          id: _id,
          label: name ?? _id,
          selected: allSystems,
          systems: siteSystems.map((system) => {
            return { ...system, selected: true };
          }),
        };
      });
      return menuItemBuilder(_id, name ?? _id, 'org', orgSystems, sitesSubItems, allSites);
    });

    const unallocatedSystems = buildSystemMenutItems(
      changes.systems.map((system) => {
        return { ...system, selected: true };
      }),
    );

    setSelectedAssets([...orgs, ...unallocatedSystems]);
  }, [changes.orgs, changes.systems]);

  useEffect(() => {
    setInitialSelectedItems();
  }, [setInitialSelectedItems]);
  /**
   * Updates value for any notification type on the notification object
   */
  const onChangeCheckboxValue = useCallback(
    (property: NotificationType, checked: boolean) => {
      setChanges({ ...changes, [property]: checked });
      setFormStatus('dirty');
    },
    [changes, setChanges, setFormStatus],
  );
  /**
   * Updates priority for any property on the batchWindows object
   */
  const handleOnChangeSelect = useCallback(
    (property: BatchType, selected: SelectValue) => {
      const clone = structuredClone<Notifications['batchWindows']>(changes.batchWindows);
      if (selected === NotificationPriority.Fast || selected === NotificationPriority.Slow) {
        clone[property] = selected;

        setChanges({ ...changes, batchWindows: clone });
        setFormStatus('dirty');
      }
    },
    [setChanges, setFormStatus, changes],
  );

  const handleModeChange = useCallback(
    (selectedMode: unknown) => {
      setChanges({ ...changes, mode: selectedMode as NotificationMode });
      setFormStatus('dirty');
    },
    [setChanges, setFormStatus, changes],
  );

  const cleanupForm = useCallback(() => {
    setFormStatus(undefined);
    setSelectedOrgId(undefined);
    setSelectedSiteId(undefined);
  }, []);

  const handleDiscard = useCallback(() => {
    setChanges({ ...(notifications ?? userContextDefaultValue.userNotifications) });
    cleanupForm();
    setInitialSelectedItems();
  }, [notifications, cleanupForm, setInitialSelectedItems]);
  /**
   * Adds all selected items from the multi select menu and adds it to the selected list
   */
  const handleDone = useCallback((itemsSelected: MenuItem[]) => {
    setSelectedAssets(itemsSelected);
    setShowModal(false);
    setFormStatus('dirty');
  }, []);
  /**
   * Recursively removes from selected list if item or sub items are not selected
   */
  const handleRemoveItem = useCallback(
    (itemId: string) => {
      setSelectedAssets(filterRecursively(selectedAssets, itemId));
      setFormStatus('dirty');
    },
    [selectedAssets],
  );

  const handleClickAllCheckbox = useCallback(
    (item: MenuItem, parent?: MenuItem) => {
      const updatedItem = toggleAssetOption(item, parent);
      setSelectedAssets(updateList(selectedAssets, updatedItem));
      setFormStatus('dirty');
    },
    [selectedAssets, setSelectedAssets],
  );
  /**
   * Batch priority options
   */
  const selectOptions: GenericValueReactSelectOption<NotificationPriority>[] = useMemo(() => {
    return [
      {
        label: t('fast_batching'),
        value: NotificationPriority.Fast,
      },
      {
        label: t('slow_batching'),
        value: NotificationPriority.Slow,
      },
    ];
  }, [t]);
  const connectivitySelectOptions: GenericValueReactSelectOption<NotificationPriority>[] = useMemo(() => {
    return [
      {
        label: t('fast_batching_conn'),
        value: NotificationPriority.Fast,
      },
      {
        label: t('slow_batching_conn'),
        value: NotificationPriority.Slow,
      },
    ];
  }, [t]);

  return (
    <div className={`overflow-hidden ${isMobile ? 'px-2' : 'px-4'}`}>
      <Form
        className="h-full flex flex-col gap-2 pt-2"
        onSubmit={() => handleSaveNotifications(selectedAssets)}
        preventDefault
      >
        <FormSave className="self-end" mode="edit" onCancel={handleDiscard} status={formStatus} />
        <div className="flex flex-col gap-4 flex-1 overflow-y-auto overflow-x-hidden pb-2">
          <Card className="flex flex-col">
            <Text type={TextType.h4}>{t('general_notifications')}</Text>
            <div className="clickable-list">
              <ClickableListItem
                title={t('daily_report')}
                help={
                  <Trans t={t} i18nKey="daily_report_help">
                    <b key={1} className="text-primary-500"></b>
                  </Trans>
                }
                checked={changes.dailyReport}
                checkboxId="dailyReport"
                onChangeValue={(checked: boolean) => onChangeCheckboxValue('dailyReport', checked)}
              />
              <ClickableListItem
                title={t('subscription_warranty')}
                help={t('subscription_warranty_help')}
                checked={changes.expirations}
                checkboxId="subscriptionWarranty"
                onChangeValue={(checked: boolean) => onChangeCheckboxValue('expirations', checked)}
              />
              <ClickableListItem
                title={t('release_notes')}
                help={t('release_notes_help')}
                checked={changes.releaseNotes}
                checkboxId="releaseNotes"
                onChangeValue={(checked: boolean) => onChangeCheckboxValue('releaseNotes', checked)}
              />
            </div>
          </Card>
          <Card className="flex-10 flex flex-col bg-gray-50 gap-4">
            <Text type={TextType.h4}>{t('per_system_notifications')}</Text>
            <div className="list-item">
              <Text type={TextType.h5} className="min-w-[16rem]">
                {t('event_types')}
              </Text>
              <Text type={TextType.h5} className="min-w-[16rem] desktop">
                {t('batch_window')}
                &nbsp;
                <InformationCircleIcon
                  data-testid="tooltip"
                  className={`${isMobile ? 'w-5 h-5' : 'w-4 h-4'} text-blue-800 inline`}
                  data-tip={t('notification_batching_info')}
                />
              </Text>
              <Text className="mobile" overflow="" wrap>
                {t('notification_batching_info')}
              </Text>
            </div>
            <div className="clickable-list">
              <ClickableListItem
                title={t('fault_events')}
                help={t('fault_events_help')}
                checked={changes.fault}
                checkboxId="faultEvent"
                onChangeValue={(checked: boolean) => {
                  onChangeCheckboxValue('fault', checked);
                }}
              >
                {changes.fault && (
                  <Select
                    options={selectOptions}
                    className="batch-window-select"
                    placeholder={t('select_priority')}
                    value={changes.batchWindows?.fault ?? undefined}
                    onChangeValue={(selected: SelectValue) => handleOnChangeSelect('fault', selected)}
                    clearable={false}
                    data-pwid="fault-batching-select"
                  />
                )}
              </ClickableListItem>
              <ClickableListItem
                title={t('warning_events')}
                help={t('warning_events_help')}
                checked={changes.warning}
                checkboxId="warningEvent"
                onChangeValue={(checked: boolean) => onChangeCheckboxValue('warning', checked)}
              >
                {changes.warning && (
                  <Select
                    options={selectOptions}
                    className="batch-window-select"
                    placeholder={t('select_priority')}
                    value={changes.batchWindows?.warning ?? undefined}
                    onChangeValue={(selected: SelectValue) => handleOnChangeSelect('warning', selected)}
                    clearable={false}
                    data-pwid="warning-batching-select"
                  />
                )}
              </ClickableListItem>
              <ClickableListItem
                title={t('information_events')}
                help={t('information_events_help')}
                checked={changes.info}
                checkboxId="infoEvent"
                onChangeValue={(checked: boolean) => onChangeCheckboxValue('info', checked)}
              >
                {changes.info && (
                  <Select
                    options={selectOptions}
                    className="batch-window-select"
                    placeholder={t('select_priority')}
                    value={changes.batchWindows?.info ?? undefined}
                    onChangeValue={(selected: SelectValue) => handleOnChangeSelect('info', selected)}
                    clearable={false}
                    data-pwid="info-batching-select"
                  />
                )}
              </ClickableListItem>
            </div>

            <div className="list-item">
              <div></div>
              <Text type={TextType.h5} className="min-w-[16rem] desktop items-center">
                {t('debounce_window')} &nbsp;
                <InformationCircleIcon
                  data-testid="tooltip"
                  className={`${isMobile ? 'w-5 h-5' : 'w-4 h-4'} text-blue-800 inline`}
                  data-tip={t('debounce_info')}
                />
              </Text>
              <Text className="mobile" overflow="" wrap>
                {t('notification_batching_info')}
              </Text>
            </div>

            <div>
              <ClickableListItem
                title={t('connectivity_events')}
                help={t('connectivity_events_help')}
                checked={changes.connectivity}
                checkboxId="connectivityEvent"
                onChangeValue={(checked: boolean) => onChangeCheckboxValue('connectivity', checked)}
              >
                {changes.connectivity && (
                  <Select
                    options={connectivitySelectOptions}
                    className="batch-window-select"
                    placeholder={t('select_priority')}
                    value={changes.batchWindows?.connectivity ?? undefined}
                    onChangeValue={(selected: SelectValue) => handleOnChangeSelect('connectivity', selected)}
                    clearable={false}
                    data-pwid="connectivity-debounce-select"
                  />
                )}
              </ClickableListItem>
            </div>
            <Divider />
            <Text type={TextType.h5}>{t('systems')}</Text>
            <Radio.Group name="notification-systems" onChange={handleModeChange} value={changes.mode}>
              <Radio
                value={NotificationMode.Whitelist}
                label={
                  <Trans t={t} i18nKey="whitelist">
                    <strong
                      key={1}
                      className={`${
                        changes.mode === NotificationMode.Whitelist ? 'text-emerald-500' : 'text-primary-500'
                      }`}
                    ></strong>
                  </Trans>
                }
                data-pwid="whitelist-radio"
              />
              <Radio
                value={NotificationMode.Blacklist}
                label={
                  <Trans t={t} i18nKey="blacklist">
                    <strong
                      key={1}
                      className={`${changes.mode === NotificationMode.Blacklist ? 'text-red-500' : 'text-primary-500'}`}
                    ></strong>
                  </Trans>
                }
                data-pwid="blacklist-radio"
              />
            </Radio.Group>
            <div className="ml-6">
              <Button
                variant={Variant.secondaryFilled}
                type={Type.button}
                onClick={() => {
                  setSelectedOrgId(undefined);
                  setSelectedSiteId(undefined);
                  setShowModal(true);
                }}
                data-pwid="add-to-list-button"
              >
                {t('select_assets')}
              </Button>
            </div>
            <SelectedList
              listItems={selectedAssets}
              mode={changes.mode}
              handleRemoveItem={handleRemoveItem}
              handleShowModal={(selectedItem) => {
                if (selectedItem.isSite) {
                  setSelectedSiteId(selectedItem.id);
                  setSelectedOrgId(undefined);
                } else {
                  setSelectedOrgId(selectedItem.id);
                  setSelectedSiteId(undefined);
                }
                setShowModal(true);
              }}
              handleCheckbox={handleClickAllCheckbox}
            />
          </Card>
        </div>
        {showModal && (
          <ModalSelect
            onCancel={() => setShowModal(false)}
            onDone={handleDone}
            selectedAssets={selectedAssets}
            selectedOrgId={selectedOrgId}
            selectedSiteId={selectedSiteId}
          />
        )}
      </Form>
    </div>
  );
}

export default function NotificationsContainer(): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <NotificationsPage />
    </SystemsContext.Provider>
  );
}
