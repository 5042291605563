import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { Button, HeroIcons, Input, Link, Text, TextType, Variant } from '../../../ComponentLibrary/src';
import { screenSizeMediaQuery } from '../../../ComponentLibrary/src/util/constants';
import { AuthContext } from '../../../context/Auth';
import { DragItem, Organization, Site } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';

/**
 * Header for the organizations panel that displays breadcrumbs and actions
 * a user can take on each page.
 */
interface OrganizationsHeaderProps {
  selectedOrg: Organization | null;
  selectedSite: Site | null;
  bulkMove: boolean;
  orgSearchTerm?: string;
  handleBulkCancel: (bulkMove: boolean) => void;
  setOrgSearchTerm: (term: string) => void;
  onDrop: (item: DragItem, destination: string) => void;
}

export default function OrganizationsHeader({
  selectedOrg,
  selectedSite,
  bulkMove,
  orgSearchTerm,
  handleBulkCancel,
  setOrgSearchTerm,
  onDrop,
}: OrganizationsHeaderProps): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const navigate = useNavigate();
  const isSmall = useMediaQuery(screenSizeMediaQuery.small);
  const [_searchTerm, setSearchTerm] = useState<string>(orgSearchTerm ?? '');
  const { t } = useTranslation(['assets', 'translation']);
  const [{ isOverOrgName }, orgNameDrop] = useDrop(() => {
    return {
      accept: ['System'],
      collect: (monitor) => {
        return {
          isOverOrgName: monitor.isOver(),
        };
      },
      drop: (item: DragItem) => {
        if (selectedOrg) {
          item.org = selectedOrg;
        }
        onDrop(item, 'org');
      },
    };
  });

  const [{ isOverOrgs }, orgsDrop] = useDrop(() => {
    return {
      accept: ['System'],
      collect: (monitor) => {
        return {
          isOverOrgs: monitor.isOver(),
        };
      },
    };
  });

  const handleBack = useCallback(() => {
    navigate(`/assets${document.location.search}`);
  }, [navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchTermDebounced = useCallback(
    debounce((term: string) => {
      setOrgSearchTerm(term);
      handleBack();
    }, 500),
    [setOrgSearchTerm, handleBack],
  );

  const handleChangeOrgSearch = useCallback(
    (term: string | number) => {
      setSearchTermDebounced(term as string);
      setSearchTerm(term as string);
    },
    [setSearchTermDebounced, setSearchTerm],
  );

  const hasAssets = selectedOrg && (selectedOrg.systems?.length || selectedOrg.sites?.length);
  // Comment out for now until we can handle unmount not firing dragend event
  /* const handlBackToOrg = useCallback(() => {
    if (selectedOrg && selectedSite) {
      navigate(`/assets/${selectedOrg._id}${document.location.search}`);
    }
  }, [navigate, selectedOrg, selectedSite]);

  const debouncedOnDragOverOrg = useMemo(() => {
    return debouncedFunction<void>(handlBackToOrg);
  }, [handlBackToOrg]);

  const debouncedOnDragOverOrgs = useMemo(() => {
    return debouncedFunction<void>(handleBack);
  }, [handleBack]);

  useEffect(() => {
    if (isOverOrgName) {
      debouncedOnDragOverOrg();
    } else {
      debouncedOnDragOverOrg.cancel();
    }
  }, [isOverOrgName, debouncedOnDragOverOrg]);

  useEffect(() => {
    if (isOverOrgs) {
      debouncedOnDragOverOrgs();
    } else {
      debouncedOnDragOverOrgs.cancel();
    }
  }, [isOverOrgs, debouncedOnDragOverOrgs]);
 */
  return (
    <div className="flex flex-col gap-3 space-between">
      <div className="flex flex-row gap-2 items-center min-h-9">
        {selectedOrg ? (
          <React.Fragment>
            <Link
              href={`${selectedSite ? `/assets/${selectedOrg._id}` : '/assets'}${document.location.search}`}
              disabled={bulkMove}
            >
              <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
            </Link>
            <div ref={orgsDrop}>
              <Link
                href={`/assets${document.location.search}`}
                className={`ellipsis${isOverOrgs ? ' bg-gray-200' : ''}`}
                disabled={bulkMove}
              >
                <Text type={TextType.h5} overrideColor className="text-gray-500">
                  {t('Organizations')}
                </Text>
              </Link>
            </div>
            {selectedSite ? (
              <React.Fragment>
                <Text type={TextType.h5} overrideColor={true} textClassName="text-gray-200">
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                </Text>
                <div ref={orgNameDrop}>
                  <Link
                    href={`/assets/${selectedOrg._id}${document.location.search}`}
                    className={`ellipsis${isOverOrgName ? ' bg-gray-200' : ''}`}
                  >
                    <Text type={TextType.h5} overrideColor className="text-gray-500">
                      {selectedOrg.name}
                    </Text>
                  </Link>
                </div>
                <Text type={TextType.h5} overrideColor={true} textClassName="text-gray-200">
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                </Text>
                <Text type={TextType.h5} overrideColor={true} textClassName="text-blue-800">
                  {selectedSite.name}
                </Text>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Text type={TextType.h5} overrideColor={true} textClassName="text-gray-200">
                  <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                </Text>
                <Text type={TextType.h5} overrideColor={true} textClassName="text-blue-800">
                  {selectedOrg.name}
                </Text>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span className="h-5 w-5"></span>
            <Text type={TextType.h5} overrideColor={true} textClassName="text-blue-800">
              {t('Organizations')}
            </Text>
          </React.Fragment>
        )}
        <div className="flex flex-1 flex-row gap-3 justify-end">
          {!selectedOrg && !selectedSite && hasPermissions(PERMISSIONS.dashboard.orgs.add) && (
            <Link href="/assets/createOrg" data-pwid="create-org-button">
              <Button variant={Variant.secondaryFilled}>{t('Create Organization')}</Button>
            </Link>
          )}
          {selectedOrg && !selectedSite && (
            <div className="max-md:hidden flex flex-1 flex-row gap-3 justify-end">
              {hasPermissions(PERMISSIONS.dashboard.sites.add) && (
                <Link href={`/assets/createSite/${selectedOrg._id}`} data-pwid="create-site-button">
                  <Button variant={Variant.secondaryFilled}>{t('create_site')}</Button>
                </Link>
              )}
              {!isSmall &&
                (hasPermissions(PERMISSIONS.dashboard.sites.update) &&
                hasPermissions(PERMISSIONS.dashboard.systems.updateSite) &&
                bulkMove ? (
                  <Button
                    variant={Variant.primary}
                    icon={HeroIcons.XCircleIcon}
                    onClick={() => handleBulkCancel(false)}
                    data-pwid="cancel-move-button"
                  >
                    {t('Cancel')}
                  </Button>
                ) : (
                  <Button
                    variant={Variant.secondary}
                    icon={HeroIcons.PencilIcon}
                    onClick={() => handleBulkCancel(true)}
                    disabled={!hasAssets}
                    tooltip={hasAssets ? undefined : t('no_assets')}
                    data-pwid="bulk-move-button"
                  >
                    {t('move')}
                  </Button>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-between items-center">
        <Input
          className="w-full"
          onChangeValue={handleChangeOrgSearch}
          value={_searchTerm}
          leftIcon={HeroIcons.SearchIcon}
          disabled={bulkMove}
          hideErrorSection
          clearable={!bulkMove}
          data-pwid="org-search-input"
        />
        {selectedOrg && !selectedSite && (
          <div className="md:hidden">
            {hasPermissions(PERMISSIONS.dashboard.sites.add) && (
              <Link href={`/assets/createSite/${selectedOrg._id}`} data-pwid="create-site-button">
                <Button variant={Variant.secondaryFilled}>{t('create_site')}</Button>
              </Link>
            )}
            {!isSmall &&
              (hasPermissions(PERMISSIONS.dashboard.sites.update) &&
              hasPermissions(PERMISSIONS.dashboard.systems.updateSite) &&
              bulkMove ? (
                <Button
                  variant={Variant.primary}
                  icon={HeroIcons.XCircleIcon}
                  onClick={() => handleBulkCancel(false)}
                  data-pwid="cancel-move-button"
                >
                  {t('Cancel')}
                </Button>
              ) : (
                <Button
                  variant={Variant.secondary}
                  icon={HeroIcons.PencilIcon}
                  onClick={() => handleBulkCancel(true)}
                  disabled={!hasAssets}
                  tooltip={hasAssets ? undefined : t('no_assets')}
                  data-pwid="bulk-move-button"
                >
                  {t('move')}
                </Button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
