import './index.scss';
import '../Label/index';

import React, { useEffect, useState } from 'react';

import { Label } from '../Label';
import { FlexDirection } from '../types';
import { RadioGroupProps, RadioProps } from './index.types';

export const Radio: React.FC<RadioProps> & { Group: React.FC<RadioGroupProps> } = ({
  label,
  checked,
  tooltip,
  groupName,
  value,
  onChange,
  'data-pwid': dataPwId = 'radio',
}: RadioProps) => {
  const [selected, setSelected] = useState(checked);

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  return (
    <div data-testid="radio" className="flex flex-row gap-2 items-center">
      <div
        className="flex flex-row gap-2 items-center cursor-pointer"
        onClick={() => {
          if (onChange) onChange(value);
        }}
        data-pwid={dataPwId}
      >
        <input
          type="radio"
          checked={selected}
          name={groupName}
          onChange={(e) => e.stopPropagation()}
          data-pwid={`${dataPwId}-input`}
        />
        {label && <Label tooltip={tooltip}>{label}</Label>}
      </div>
    </div>
  );
};

export const Group: React.FC<RadioGroupProps> = ({
  label,
  name,
  direction,
  tooltip,
  value,
  onChange,
  children,
  disabled,
}: RadioGroupProps) => {
  const [_value, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleSelect = (newValue: unknown) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <fieldset
      className="radio-group"
      style={{
        flexDirection: direction ?? FlexDirection.column,
      }}
      disabled={disabled}
    >
      <legend>
        <Label tooltip={tooltip}>{label}</Label>
      </legend>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          groupName: name,
          checked: _value === child.props.value,
          onChange: handleSelect,
        }),
      )}
    </fieldset>
  );
};

Radio.Group = Group;

export * from './index.types';
