import { CubeIcon, LocationMarkerIcon, XIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { fetchOrgs } from '../../../adapters/api/organizations';
import { fetchSearchSites } from '../../../adapters/api/sites';
import {
  AsyncSelect,
  Form,
  FormSave,
  FormStatus,
  ReactSelectOption,
  Select,
  SelectValue,
  Text,
  TextType,
} from '../../../ComponentLibrary/src';
import { Distributor, Organization, Site, System } from '../../../types';
import { AssetError } from '../types';
import Distributors from './Distributors';
import StaticSite from './StaticSite';
import StaticSystem from './StaticSystem';

interface MoveProps {
  focusedOrg?: Organization;
  systems: System[];
  sites: Site[];
  bulkMove: boolean;
  all: boolean;
  setSystems: (systems: System[] | ((s: System[]) => System[])) => void;
  setSites: (sites: Site[] | ((s: Site[]) => Site[])) => void;
  handleUndo: (system?: System, site?: Site) => void;
  handleSaveSite: (
    site: Pick<Site, '_id' | 'name' | 'orgId'> & Partial<Site>,
    skipRefresh: boolean,
  ) => Promise<AssetError | undefined>;
  handleSaveSystem: (system: System, skipRefresh: boolean) => Promise<AssetError | undefined>;
  handleDiscard: () => void;
  refreshData: () => void;
  setBulkMove: (bulkMove: boolean) => void;
}

export default React.memo(function Move({
  systems,
  sites,
  setSystems,
  setSites,
  handleUndo,
  handleSaveSite,
  handleSaveSystem,
  handleDiscard,
  refreshData,
  bulkMove,
  focusedOrg,
  all,
  setBulkMove,
}: MoveProps): JSX.Element {
  const { t } = useTranslation('assets');
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>('dirty');
  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>(focusedOrg);
  const [allOrgs, setAllOrganizations] = useState<Organization[]>([]);
  const [allSites, setAllSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<Site | undefined>(undefined);
  const [selectedDistributor, setSelectedDistributor] = useState<Distributor | undefined>(undefined);
  const [errors, setErrors] = useState<{ [sysId: string]: string }>({});

  const handleDiscardForm = useCallback(() => {
    handleDiscard();
    setSelectedOrg(undefined);
    setSelectedSite(undefined);
    setSelectedDistributor(undefined);
  }, [handleDiscard]);

  // Clear form
  useEffect(() => {
    setSelectedOrg(focusedOrg);
    return () => {
      handleDiscardForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkMove]);

  useEffect(() => {
    ReactTooltip.rebuild();

    return () => {
      ReactTooltip.hide();
    };
  }, [errors]);

  const handleSave = useCallback(async () => {
    if (!selectedOrg) return;
    setFormStatus('loading');
    let names = '';

    if (systems.length > 0) {
      names = systems.reduce((acc, sys, i) => {
        if (i === 0) acc = `${acc} system(s)`;
        return `${acc} ${sys.sysId}`;
      }, names);

      if (sites.length > 0) `${names} and`;
    }

    names = sites.reduce((acc, site, i) => {
      if (i === 0) acc = `${acc} site(s)`;
      return `${acc} ${site.name}`;
    }, names);

    if (confirm(t('confirm_system_to_org', { system: names, org: selectedOrg.name }))) {
      const systemPromises: Promise<AssetError | undefined>[] = systems.map(({ sysId }: System) => {
        return handleSaveSystem(
          {
            sysId,
            orgId: selectedOrg._id,
            siteId: selectedSite?._id,
            distributor: selectedDistributor,
          },
          true,
        );
      });
      const sitePromises: Promise<AssetError | undefined>[] = sites.map(({ _id, name }: Site) => {
        return handleSaveSite({ _id, name, orgId: selectedOrg._id, distributorId: selectedDistributor?._id }, true);
      });
      const [systeRes, siteRes] = await Promise.all<Array<AssetError | undefined>>([
        Promise.all<AssetError | undefined>(systemPromises),
        Promise.all<AssetError | undefined>(sitePromises),
      ]);
      const errMessages = [...systeRes, ...siteRes].filter((e) => !!e);
      if (errMessages.every((err) => err === undefined)) {
        if (systems.length + sites.length > 2) {
          toast(t('success_bulk_move', { org: selectedOrg.name }), { type: 'success' });
        } else {
          if (systems.length) {
            toast(t('success_move', { system: systems[0].sysId }), { type: 'success' });
          }
          if (sites.length) {
            toast(t('success_move_site', { site: sites[0].name }), { type: 'success' });
          }
        }
        setFormStatus('success');
        setSystems([]);
        setSites([]);
        refreshData();
        setSelectedOrg(undefined);
        setSelectedSite(undefined);
        setBulkMove(false);
      } else {
        setFormStatus('dirty');
        const errs = errMessages
          .map<AssetError>((err: AssetError | undefined) => {
            if (err === undefined) {
              return { id: '', error: '' };
            }
            return err;
          })
          .filter(({ id }) => !!id)
          .reduce((acc, errMessage) => {
            acc[errMessage.id] = errMessage.error;
            return acc;
          }, {} as { [id: string]: string });

        setSystems(systems.filter(({ sysId }) => !!errs[sysId]));
        setSites(sites.filter(({ _id }) => !!errs[_id]));
        setErrors(errs);
      }
    } else {
      setFormStatus('dirty');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systems, sites, selectedOrg, selectedSite, selectedDistributor]);

  const handleSelectOrg = useCallback(
    (reactSelOrg: ReactSelectOption | null) => {
      const found = allOrgs.find((org) => {
        return org._id === reactSelOrg?.value;
      });
      setSelectedOrg(found);
      setSelectedSite(undefined);
      setFormStatus('dirty');
    },
    [allOrgs],
  );

  const handleSelectSite = useCallback(
    (selected: ReactSelectOption | null) => {
      const found = allSites.find((site) => site._id === selected?.value);
      setSelectedSite(found);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allSites],
  );

  const handleSelectDistributor = useCallback(
    (distributorId: SelectValue) => {
      if (selectedOrg) {
        setSelectedDistributor(selectedOrg.distributors?.find((dist) => dist._id === distributorId));
      }
    },
    [selectedOrg],
  );

  const handleFetchSites = useCallback(
    async (search: string): Promise<ReactSelectOption[]> => {
      const results = await fetchSearchSites({
        project: ['site.name'],
        pageNumber: 1,
        countPerPage: 25,
        sort: {
          name: 1,
        },
        orgId: selectedOrg?._id,
        count: true,
        searchTerm: search,
      });
      if (results) {
        setAllSites(results.sites);
        const selectOptions: ReactSelectOption[] = results.sites.map((site) => {
          return {
            value: site._id,
            label: site?.label ?? site.name,
          };
        });

        if (results.count > results.sites.length) {
          selectOptions.push({
            value: '',
            label: `And ${results.count - results.sites.length} more...`,
            isDisabled: true,
          });
        }

        return selectOptions;
      }
      return [];
    },
    [selectedOrg],
  );

  const handleFetchOrgs = useCallback(async (search: string): Promise<ReactSelectOption[]> => {
    return fetchOrgs({
      count: true,
      'page[number]': 1,
      'page[size]': 25,
      textSearch: search,
    }).then((res: unknown) => {
      const { orgs, count } = res as {
        orgs: Organization[];
        count: number;
      };
      setAllOrganizations(orgs);
      return [
        ...orgs.map((org) => ({
          value: org._id,
          label: org.label ?? org.name,
        })),
        ...(count > orgs.length
          ? [
              {
                value: '',
                label: `And ${count - orgs.length} more...`,
                isDisabled: true,
              },
            ]
          : []),
      ] as ReactSelectOption[];
    });
  }, []);

  const getDistributorOptions = useMemo(() => {
    if (selectedOrg && selectedOrg.distributors) {
      if (selectedSite && selectedSite.distributorId) {
        const found = selectedOrg.distributors.find(({ _id }) => _id === selectedSite.distributorId);
        if (found) {
          setSelectedDistributor(found);
        }
      }
      return selectedOrg.distributors.map((dist) => {
        return {
          label: dist.name,
          value: dist._id,
        };
      });
    }
    return [];
  }, [selectedOrg, selectedSite]);

  const showForm = useMemo(() => systems.length > 0 || sites.length > 0, [systems?.length, sites.length]);

  return (
    <div className={`card move${bulkMove ? ' bulk-edit' : ''}`}>
      <div className="flex flex-col gap-2">
        <div>
          <Text type={TextType.h4} align="center" wrap inline>
            {t('move_title')}
          </Text>
        </div>
        {all ? (
          <div className="bg-gray-50 shadow-md flex items-center flex-row py-3 sm:px-2" data-pwid="move-all-selected">
            <div className="flex flex-2 flex-col">
              {focusedOrg && (
                <div className="flex flex-2 flex-row gap-2">
                  <Text type={TextType.h5} textClassName="font-semibold" inline>
                    {focusedOrg.name}
                  </Text>
                  <Distributors
                    selectedDistributors={focusedOrg.distributors}
                    parentName={focusedOrg.name}
                    showAdd={false}
                    canEdit={false}
                  />
                </div>
              )}
              <Text textClassName="italic">({t('all_sites_systems')})</Text>
            </div>
            <div className="max-md:hidden px-2" data-tip={t('number_of_sites')}>
              <LocationMarkerIcon className="w-5 h-5 inline" />
              <Text inline>{sites.length}</Text>
            </div>
            <div className="max-md:hidden px-2" data-tip={t('number_of_systems')}>
              <CubeIcon className="w-5 h-5 inline" />
              <Text inline>
                {sites.reduce((acc: number, site: Site) => {
                  return acc + (site.systems?.length ?? 0);
                }, systems.length)}
              </Text>
            </div>
            <XIcon className="w-5 h-5 cursor-pointer" onClick={() => handleUndo()} data-tip={t('Undo')} />
          </div>
        ) : (
          <div className="shadow-md">
            {systems.map((system: System) => (
              <div key={JSON.stringify(system)} className="border-b border-gray-300 hover:bg-gray-100">
                <div className="relative flex flex-row gap-2 pl-6 mx-2 mb-0.5 items-center h-11">
                  <StaticSystem
                    system={system}
                    systemClasses=""
                    showRemove={false}
                    bulkMove={true}
                    handleUndoSystem={handleUndo}
                    refreshData={refreshData}
                    error={errors[system.sysId]}
                  />
                </div>
              </div>
            ))}
            {sites.map((site: Site) => (
              <div key={JSON.stringify(site)}>
                <StaticSite
                  site={site}
                  hideActions={true}
                  bulkMove={true}
                  siteClasses="px-2"
                  handleUndo={(site) => handleUndo(undefined, site)}
                  refreshData={refreshData}
                  error={errors[site._id]}
                />
              </div>
            ))}
          </div>
        )}
        {showForm && (
          <Form className="flex-1 flex flex-col gap-4 mt-4" preventDefault onSubmit={handleSave}>
            <AsyncSelect
              className="w-full"
              label={t('select_organization')}
              placeholder={t('organization_name')}
              hideErrorSection
              value={{ value: selectedOrg?._id, label: selectedOrg?.name || '' }}
              onChange={handleSelectOrg}
              onLoadOptions={handleFetchOrgs}
              searchable
            />
            {selectedOrg && (
              <React.Fragment>
                {sites.length === 0 && (
                  <AsyncSelect
                    className="w-full"
                    label={t('select_site')}
                    hideErrorSection
                    value={{ value: selectedSite?._id, label: selectedSite?.name || '' }}
                    onChange={handleSelectSite}
                    onLoadOptions={handleFetchSites}
                    searchable
                  />
                )}
                <Select
                  label={t('select_distributor_optional')}
                  className="w-full"
                  hideErrorSection
                  options={getDistributorOptions}
                  value={selectedDistributor?._id}
                  disabled={!!selectedSite?.distributorId}
                  onChangeValue={handleSelectDistributor}
                />
              </React.Fragment>
            )}
            <FormSave className="self-end" onCancel={handleDiscardForm} mode="edit" status={formStatus} />
          </Form>
        )}
      </div>
    </div>
  );
});
