import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import Api from '../../adapters/api';
import {
  Button,
  IndicatorTier,
  Link,
  PageHeader,
  Signal,
  SystemDisplayState,
  Variant,
} from '../../ComponentLibrary/src';
import { screenSizeMediaQuery } from '../../ComponentLibrary/src/util/constants';
import { usePageContext } from '../../components/Page';
import ActiveAlerts from '../../components/System/ActiveAlerts';
import Battery from '../../components/System/Battery';
import CapData from '../../components/System/CapData';
import Compressors from '../../components/System/Compressors';
import CustomMonitor from '../../components/System/CustomMonitor';
import Events from '../../components/System/Events';
import General from '../../components/System/General';
import Info from '../../components/System/Info';
import InstallDescription from '../../components/System/InstallDescription';
import IO from '../../components/System/IO';
import Map from '../../components/System/Map';
import Metrics from '../../components/System/Metrics';
import Modem from '../../components/System/Modem';
import OutputData from '../../components/System/OutputData';
import PerformanceData from '../../components/System/PerformanceData';
import TabNavigation from '../../components/System/TabNavigation';
import TransferSwitch from '../../components/System/TransferSwitch';
import StatusIndicator from '../../components/Systems/StatusIndicator';
import { AuthContext } from '../../context/Auth';
import { Interval, SystemsContext } from '../../context/Systems';
import { getSystemDisplayState } from '../../context/util';
import { useMobile, useSetDocumentTitle } from '../../hooks';
import { CapStats, GenSystem } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import { usePolling } from '../../WebUtils';
import { isCap, parseSignalStrength } from './util';

export default function System(): JSX.Element {
  const {
    system,
    getSystem,
    getCurrentSystemData,
    systemData,
    systemDeltaData,
    getSystemDeltaData,
    getSystemData,
    currentSystemData,
    getSystemTimeline,
    summitInfo,
    modemError,
  } = useContext(SystemsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dataStale, setDataStale] = useState(false);
  const { sysId } = useParams();
  const { hasPermissions } = useContext(AuthContext);
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const navigate = useNavigate();
  useSetDocumentTitle(sysId ?? 'System');
  const isMobile = useMobile();
  const isSmallScreen = useMediaQuery(screenSizeMediaQuery.small);
  const onFailHandler = (error: AxiosError) => {
    if (error.response?.status === 402) return;
    if (error.response?.status === 403) {
      navigate(`/systems/${sysId}`);
    }
  };

  useEffect(() => {
    setTitle('');
    setBreadcrumbs();
    setScrollable(false);

    return () => {
      toast.dismiss('oldData');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      system &&
      currentSystemData?.ts &&
      Api.getServerTime() &&
      Api.getServerTime().diff(DateTime.fromISO(currentSystemData.ts)).as('minutes') >
        parseInt(process.env.REACT_APP_DATA_STALE_MIN ?? '20')
    ) {
      setDataStale(getSystemDisplayState(system) !== SystemDisplayState.unknown);
    }
  }, [system, currentSystemData]);

  usePolling(() => {
    const rightNow = DateTime.now();
    if (sysId) {
      const promises = [
        getSystem({ sysId, fetchSummit: true, onFail: onFailHandler }),
        getCurrentSystemData(sysId as string, onFailHandler),
        getSystemTimeline({
          sysId: sysId as string,
          filter: [],
          countPerPage: 5,
          sort: {
            ts: -1,
          },
          onFail: onFailHandler,
        }),
        getSystemData(
          sysId as string,
          undefined,
          rightNow.minus({ weeks: 1 }).toJSDate(),
          rightNow.toJSDate(),
          Interval.hour,
          onFailHandler,
        ),
      ];

      if (isCap({ sysId })) {
        promises.push(
          getSystemDeltaData(
            sysId as string,
            ['accCh4'],
            rightNow.minus({ weeks: 1 }).toJSDate(),
            rightNow.toJSDate(),
            Interval.day,
            onFailHandler,
          ),
        );
      }
      Promise.all(promises).finally(() => setIsLoading(false));
    }
  }, [sysId]);

  const data = useMemo(() => {
    return {
      userLoadP: {
        x: systemData?.ts,
        y: systemData?.data?.userLoadP,
      },
      pCap: {
        x: systemData?.ts,
        y: systemData?.data?.pCap,
      },
      pressure: {
        x: systemData?.ts,
        y: systemData?.data?.pressure,
      },
      pBk: {
        x: systemData?.ts,
        y: systemData?.data?.pBk,
      },
      flwCap: {
        x: systemData?.ts,
        y: systemData?.data?.flwCap,
      },
      flow: {
        x: systemData?.ts,
        y: systemData?.data?.flow,
      },
      gasPress: {
        x: systemData?.ts,
        y: systemData?.data?.gasPress,
      },
      tGhtOut: {
        x: systemData?.ts,
        y: systemData?.data?.tGhtOut,
      },
      tGhtIn: {
        x: systemData?.ts,
        y: systemData?.data?.tGhtIn,
      },
      ghtPumpOn: {
        x: systemData?.ts,
        y: systemData?.data?.ghtPumpOn,
      },
      outP1: {
        x: systemData?.ts,
        y: systemData?.data?.outP1,
      },
      outP2: {
        x: systemData?.ts,
        y: systemData?.data?.outP2,
      },
      outP3: {
        x: systemData?.ts,
        y: systemData?.data?.outP3,
      },
      flwGasMeter: {
        x: systemData?.ts,
        y: systemData?.data?.flwGasMeter,
      },
      accCh4: {
        x: systemDeltaData?.ts,
        y: systemDeltaData?.data?.accCh4,
      },
      solP: {
        x: systemData?.ts,
        y: systemData?.data?.solP,
      },
      battV: {
        x: systemData?.ts,
        y: systemData?.data?.battV,
      },
    };
  }, [systemData, systemDeltaData]);

  useEffect(() => {
    if (dataStale) {
      toast(`Data is older than ${process.env.REACT_APP_DATA_STALE_MIN ?? '20'} minutes`, {
        type: 'warning',
        autoClose: false,
        toastId: 'oldData',
      });
    }
  }, [dataStale]);

  const isCapSystem = useMemo(() => isCap(system), [system]);
  const signalStrength = useMemo(() => parseSignalStrength(summitInfo), [summitInfo]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <PageHeader
        className={isMobile ? 'p-2' : 'p-4'}
        title={sysId}
        subtitle={isLoading ? '' : system?.site?.name}
        breadcrumbs={[{ text: 'Systems', href: '/systems' }, { text: sysId ?? '' }]}
        leftComponent={<StatusIndicator system={system} tier={IndicatorTier.two} isLoading={isLoading} />}
        rightComponent={
          <>
            <Signal signalStrength={signalStrength} isLoading={isLoading} className="w-6 h-6" />
            {!isMobile &&
              hasPermissions(
                [
                  PERMISSIONS.dashboard.systems.update,
                  PERMISSIONS.dashboard.systems.updateShipDate,
                  PERMISSIONS.dashboard.systems.advancedUpdate,
                  PERMISSIONS.dashboard.systems.updateDistributor,
                  PERMISSIONS.dashboard.systems.updateOrg,
                  PERMISSIONS.dashboard.systems.updateSite,
                  PERMISSIONS.dashboard.systems.updateProductAccess,
                ],
                'or',
              ) && (
                <Link href={`/systems/${sysId}/edit`} data-pwid="system-edit-button">
                  <Button variant={Variant.secondaryFilled}>Edit</Button>
                </Link>
              )}
          </>
        }
        bottomBorder
      >
        <TabNavigation
          sysId={sysId}
          pageSelected="details"
          subscriptionStatus={isLoading ? '' : system?.subscriptionStatus || ''}
        />
      </PageHeader>
      <div
        className={`${isMobile ? 'pt-2 px-2 mb-2' : 'p-4'} overflow-y-auto`}
        id="content"
        onScroll={() => window.dispatchEvent(new Event('scrollPage'))}
      >
        <div
          className="flex flex-row flex-wrap gap-4 xl:gap-6 lg:flex-nowrap"
          style={isMobile ? { marginBottom: '4rem' } : {}}
        >
          <div className="flex flex-col gap-4 xl:gap-6 w-full lg:w-2/3">
            <Info isMobile={isMobile} system={system} isLoading={isLoading} />

            {isSmallScreen && <ActiveAlerts system={system} isLoading={isLoading} />}
            {isSmallScreen && <Events sysId={sysId} isLoading={isLoading} />}
            {isSmallScreen && <Map system={system} isLoading={isLoading} />}

            <Metrics system={system} isLoading={isLoading} data={data} currentSystemData={currentSystemData} />

            <InstallDescription system={system} isLoading={isLoading} />

            <OutputData
              system={system as GenSystem}
              isLoading={isLoading}
              data={data}
              currentSystemData={currentSystemData}
            />

            <Compressors
              currentSystemData={currentSystemData}
              isLoading={isLoading}
              stats={system?.stats as CapStats}
            />

            <IO currentSystemData={currentSystemData} isLoading={isLoading} />

            {<CustomMonitor currentSystemData={currentSystemData} isLoading={isLoading} />}

            <General system={system} isLoading={isLoading} currentSystemData={currentSystemData} />

            <PerformanceData
              currentSystemData={currentSystemData}
              isLoading={isLoading}
              isCap={isCapSystem}
              systemStats={system?.stats as CapStats}
            />

            {!isCapSystem && (
              <CapData system={system as GenSystem} currentSystemData={currentSystemData} isLoading={isLoading} />
            )}
          </div>

          <div className="flex flex-col gap-4 xl:gap-6 w-full lg:w-1/3">
            {!isSmallScreen && <Map system={system} isLoading={isLoading} />}
            {!isSmallScreen && <ActiveAlerts system={system} isLoading={isLoading} />}
            {!isSmallScreen && <Events sysId={sysId} isLoading={isLoading} />}

            <Battery currentSystemData={currentSystemData} isLoading={isLoading} isCap={isCapSystem} />
            {system?.optConnectModemSN && (
              <Modem
                optConnectModemSN={system?.optConnectModemSN}
                summitInfo={summitInfo}
                isLoading={isLoading}
                modemError={modemError}
              />
            )}
            <TransferSwitch transferSw={currentSystemData?.xfrSw} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}
