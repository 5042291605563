/**
 * Variaty of util functions needed in different components that do the same thing.
 */
import NotificationMenuItem from './NotificationMenuItem';
import { HierarchicalAssets, MenuItem } from './types';

export const PAGE_SIZE = 25;
/**
 * Helper recursive function to traverse sub items and replace with updated item.
 */
const checkSubItems = (subItems: MenuItem[], updatedItem: MenuItem) => {
  return subItems.map((subMenuItem: MenuItem) => {
    if (subMenuItem.id === updatedItem.id) return updatedItem;

    subMenuItem.subItems = checkSubItems(subMenuItem.subItems, updatedItem);
    return subMenuItem;
  });
};
/**
 * Update the list with item that was selected or deselected and replaces root node item
 * with new item no matter where in the tree it was changed.
 *
 * organization <-- This node is replaced
 *     |
 *     |_ site
 *         |
 *         |_ system
 */
export const updateList = (list: MenuItem[], changedItem: MenuItem): MenuItem[] => {
  return list.map((option) => {
    if (option.id === changedItem.id) return changedItem;
    return option;
  });
};
/**
 * Build new menu item with updated selected property and replace the old one in the list
 */
export const toggleAssetOption = (item: MenuItem, parent?: MenuItem, add?: boolean): MenuItem => {
  const updatedItem = item.toggleSelected();
  // Top level was clicked
  if (!parent) return updatedItem;
  // Site or system was clicked
  if (add === undefined) {
    // Replace
    parent.subItems = checkSubItems(parent.subItems, updatedItem);
  } else {
    parent.subItems = parent.subItems.filter(({ id }) => id !== updatedItem.id);
    if (add) {
      parent.subItems = [...parent.subItems, updatedItem];
    }
  }
  return parent;
};

/**
 * Builds instances of menu item with given parameters
 */
export const menuItemBuilder = (
  id: string,
  itemName: string,
  type: string,
  systems: HierarchicalAssets['orgName']['systems'],
  sites: HierarchicalAssets['orgName']['sites'],
  selected?: boolean,
  searchLabel?: string,
): MenuItem => {
  const orgSystems = buildSystemMenutItems(systems);
  const orgSites = Object.entries(sites).map<NotificationMenuItem>(([siteName, siteObj]) => {
    return new NotificationMenuItem({
      id: siteObj.id,
      searchLabel: siteObj.label,
      label: siteName,
      type: 'site',
      selected: siteObj.selected ?? false,
      subItems: buildSystemMenutItems(siteObj.systems),
    });
  });
  const item: MenuItem = new NotificationMenuItem({
    id,
    searchLabel,
    label: itemName,
    type,
    selected: selected ?? false,
    subItems: [...orgSystems, ...orgSites],
  });

  return item;
};
/**
 * Builds instances of menu item for each system
 */
export const buildSystemMenutItems = (systems: HierarchicalAssets['orgName']['systems']): NotificationMenuItem[] => {
  return systems.map(({ sysId, model, label, selected }) => {
    return new NotificationMenuItem({
      id: sysId,
      label: sysId,
      type: 'system',
      selected: selected ?? false,
      model,
      searchLabel: label,
    });
  });
};
/**
 * Helper function to recursively filters out the item to be removed and any parent that no longer has sub items
 */
export const filterRecursively = (items: MenuItem[], removeItemId: string): MenuItem[] => {
  return items.filter((selectedAsset) => {
    if (selectedAsset.id === removeItemId) return false;
    if (selectedAsset.selected) return true;

    selectedAsset.subItems = filterRecursively(selectedAsset.subItems, removeItemId);
    return selectedAsset.isIndeterminate();
  });
};
