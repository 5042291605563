import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchOrg, postOrg } from '../../adapters/api/organizations';
import { putOrg } from '../../adapters/api/organizations';
import { Card, Checkbox, Form, FormSave, FormStatus, Input } from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import i18n from '../../i18n';
import { Organization } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import { validateOrgName } from './formValidation';
interface OrgProps {
  edit?: boolean;
}

export default function Org({ edit }: OrgProps): JSX.Element {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermissions } = useContext(AuthContext);
  const [org, setOrg] = useState<Organization | undefined>(undefined);
  const [name, setName] = useState('');
  const [allowImages, setAllowImages] = useState<boolean | undefined>(false);
  const [revealErrorMessage, setRevealErrorMessage] = useState(false);
  const [loading, setLoading] = useState(edit);
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();
  const { setTitle, setBreadcrumbs } = usePageContext();
  const { t } = useTranslation('translation');

  useEffect(() => {
    const title = edit ? t('Edit Organization') : t('Create Organization');
    setTitle('');
    setBreadcrumbs([{ text: 'Assets', href: '/assets' }, { text: title }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    // Must have either add or update org permissions to view this page
    if (!hasPermissions([PERMISSIONS.dashboard.orgs.add, PERMISSIONS.dashboard.orgs.update], 'or')) {
      toast(t('Access Forbidden'), {
        // toast(i18n.t('Access Forbidden'), {
        type: toast.TYPE.ERROR,
        toastId: 'access',
        autoClose: false,
      });
      navigate('/');
      return;
    }

    if (edit) {
      fetchOrg(orgId as string).then((org) => {
        if (org) setOrg(org);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, orgId]);

  useEffect(() => {
    resetInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const resetInputs = () => {
    setName(org?.name ?? '');
    setAllowImages(org?.allowImages);
  };

  const handleSaveOrg = () => {
    // validate form
    if (!validateOrgName(name)) {
      setRevealErrorMessage(true);
      return;
    }

    const org: Omit<Organization, '_id'> = {
      name,
      allowImages,
    };
    if (edit) {
      setFormStatus('loading');
      putOrg(orgId as string, org).then((response) => {
        if (!(response as unknown)) {
          setFormStatus('dirty');
          return;
        }
        toast(`Success: ${org.name} updated`, { type: 'success' });
        fetchOrg(orgId as string).then((org) => {
          if (org) {
            setOrg(org);
            setFormStatus('success');
          } else {
            setFormStatus('dirty');
          }
        });
        setRevealErrorMessage(false);
        location.key === 'default' ? navigate('/') : window.history.back();
      });
    } else {
      setFormStatus('loading');
      postOrg(org).then((response) => {
        if (!(response as unknown)) {
          setFormStatus('dirty');
          return;
        }
        toast(`Success: ${org.name} created`, { type: 'success' });
        setFormStatus('success');
        location.key === 'default' ? navigate('/') : window.history.back();
      });
    }
  };

  const handleCancelSaveOrg = () => {
    if (edit) {
      setRevealErrorMessage(false);
      setFormStatus(undefined);
      resetInputs();
    } else {
      navigate('/assets');
    }
  };

  const handleChangename = (name: string | number) => {
    if (validateOrgName(name as string)) {
      setFormStatus('dirty');
      setRevealErrorMessage(false);
    } else {
      setFormStatus(undefined);
      setRevealErrorMessage(true);
    }
    setName(name as string);
  };

  const handleClickAllowImages = (checked: boolean) => {
    setFormStatus('dirty');
    setAllowImages(checked);
  };

  return (
    <Card className={`flex flex-col overflow-y-scroll md:overflow-y-auto m-4`}>
      <Form className="flex-1 flex flex-col gap-2" preventDefault onSubmit={handleSaveOrg}>
        <Input
          className="w-full"
          tooltip="Name of the organization"
          onChangeValue={handleChangename}
          label="Name"
          value={name}
          errorMessage={
            !validateOrgName(name)
              ? i18n.t('assets:name_length_error', {
                  length: 3,
                })
              : undefined
          }
          revealErrorMessage={revealErrorMessage}
          loading={loading}
          data-pwid="org-name-input"
        />

        <Checkbox
          label="Allows Photo Uploads"
          checked={allowImages ?? false}
          onChangeValue={handleClickAllowImages}
          hideErrorSection
          tooltip="Allow image uploads in action logs across all systems in the organization"
        />

        <FormSave
          className="self-end"
          onCancel={handleCancelSaveOrg}
          mode={edit ? 'edit' : 'create'}
          status={formStatus}
        />
      </Form>
    </Card>
  );
}
