import '../../../style/notifications.scss';

import React, { ReactElement, ReactNode, useCallback, useRef } from 'react';

import { Checkbox, Label } from '../../../ComponentLibrary/src';

interface ClickableListItemProps {
  onChangeValue: (checked: boolean) => void;
  children?: ReactNode | ReactNode[];
  title: string;
  help?: string | ReactElement;
  checked: boolean;
  checkboxId: string;
  'data-pwid'?: string;
}

const ClickableListItem: React.FunctionComponent<ClickableListItemProps> = ({
  onChangeValue,
  children,
  title,
  help,
  checked,
  checkboxId,
  'data-pwid': dataPwid,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const handleListItemClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.target === divRef.current) {
        e.preventDefault();
        onChangeValue(!checked);
      }
    },
    [checked, onChangeValue],
  );

  return (
    <div ref={divRef} className="list-item" data-pwid={dataPwid}>
      <Checkbox id={checkboxId} className="flex-1" checked={checked} onChangeValue={onChangeValue} data-pwid={title}>
        <label htmlFor={checkboxId} className="ml-4 clickable" onClick={handleListItemClick}>
          <Label textColor="text-blue-800" wrap>
            {title}
          </Label>
          {help && (
            <Label textColor="text-blue-500" wrap>
              {help}
            </Label>
          )}
        </label>
      </Checkbox>
      {children}
    </div>
  );
};

export default ClickableListItem;
