import { DateTime } from 'luxon';

import { getSystemRowData } from '../../../components/System/constants';
import { TimelineItemType } from '../../../components/System/util';
import { ITimelineItem } from '../../../context/Systems';
import { Fault, FaultDump } from '../../../types';
import { TimelineItemsPretty } from '../../../util/constants';

export function getTitleText(item: ITimelineItem): string {
  switch (item.evtType) {
    case TimelineItemType.faultCleared:
      return !(item.data?.fault as Fault)?.faultCode && !(item.data?.fault as Fault)?.description
        ? 'All Event Codes Cleared'
        : TimelineItemsPretty[item.evtType];
    case TimelineItemType.fault:
      return (item.data.fault as Fault)?.severity ?? 'Fault';
    default:
      return TimelineItemsPretty[item.evtType];
  }
}

export function getContentText(item: ITimelineItem): string {
  switch (item.evtType) {
    case TimelineItemType.fault:
    case TimelineItemType.faultCleared:
      return `${(item.data?.fault as Fault)?.faultCode ? (item.data?.fault as Fault)?.faultCode + ' ' : ''}${
        (item.data?.fault as Fault)?.description ?? ''
      }`;
    case TimelineItemType.stateChange:
      return item.data?.state as string;
    case TimelineItemType.commissionDate:
    case TimelineItemType.shipDate:
    default:
      return '';
  }
}

export function formatFaultDumpCsv(item: ITimelineItem): string {
  let csvContent = 'Time (UTC),';

  const usedFields = [];
  let fields = [];

  const rows = (item.data?.faultDump as FaultDump)?.rows;
  fields = Object.keys(rows[0]);

  for (const field of fields) {
    usedFields.push(field);

    csvContent += (getSystemRowData(field)?.label || field) + ',';
  }

  csvContent += '\n';

  const rowCount = rows.length;

  for (let i = 0; i < rowCount; i++) {
    const offset = -1000 * (item.data.faultDump as FaultDump).offset[rowCount - 1 - i];

    csvContent += DateTime.fromISO(item.ts as string)
      .plus({ milliseconds: offset })
      .toUTC()
      .toFormat('yyyy-MM-dd HH:mm:ss');

    for (let j = 0; j < usedFields.length; j++) {
      csvContent += ',';
      if (typeof rows[rowCount - 1 - i][usedFields[j]] !== 'undefined') {
        const newContent = rows[rowCount - 1 - i][usedFields[j]];
        csvContent += newContent;
      }
    }
    csvContent += '\n';
  }

  return csvContent;
}
