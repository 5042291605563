import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { postDistributor } from '../../adapters/api/distributors';
import { Card, Form, FormSave, Input } from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';

export default function AddDistributor(): JSX.Element {
  const navigate = useNavigate();
  const [newDistName, setNewDistName] = useState('');
  const [addDistFormStatus, setAddDistFormStatus] = useState<'loading' | 'success' | 'dirty' | undefined>();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();

  useEffect(() => {
    const newBreadcrumbs = [{ text: 'Distributors', href: '/distributors' }, { text: 'Create' }];
    setTitle('');
    setBreadcrumbs(newBreadcrumbs);
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateDistributor = () => {
    setAddDistFormStatus('loading');
    setNewDistName('');
    postDistributor(newDistName).then(() => navigate('/distributors'));
  };

  return (
    <Card className="flex flex-col m-4">
      <Form className="flex-1 flex flex-col p-4 gap-2 w-full" preventDefault onSubmit={handleCreateDistributor}>
        <div className="flex flex-row gap-2 items-center flex-wrap">
          <Input
            className="w-full"
            tooltip="Name of the new distributor"
            onChangeValue={(value) => {
              setAddDistFormStatus('dirty');
              setNewDistName(value as string);
            }}
            label="Name"
            value={newDistName}
            hideErrorSection
            data-pwid="input-distributor-name"
          />
        </div>
        <FormSave
          className="self-end"
          onCancel={() => {
            setAddDistFormStatus(undefined);
            setNewDistName('');
            navigate(-1);
          }}
          mode="create"
          status={addDistFormStatus}
        />
      </Form>
    </Card>
  );
}
