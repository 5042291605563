import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import {
  Button,
  DateRange,
  DateRangePicker,
  Dropdown,
  HeroIcons,
  Select,
  SelectValue,
  Side,
  Text,
  Variant,
} from '../../ComponentLibrary/src';
import { useMobile } from '../../ComponentLibrary/src/util/hooks';
import { SystemsContext, useSystemsContextValue } from '../../context/Systems';
import { QuickRange } from '../../pages/System/Data/types';
import { luxonUnits, QUICK_RANGE_OPTIONS } from '../../pages/System/Data/util';
import { copyTextToClipboard } from '../../util';

const intervals = ['minute', 'hour', 'day', 'month'];

interface Props {
  dateRange: DateRange;
  loading?: boolean;
  dataInterval?: SelectValue;
  minInterval?: SelectValue;
  quickRange?: QuickRange;
  setDateRange: (range: DateRange) => void;
  onChangeExport?: (selectedItem: string) => void;
  setDataInterval?: (selectedInterval: SelectValue) => void;
  setQuickRange?: (quickRange: QuickRange) => void;
  onRefresh?: () => void;
  onDownloadImage?: () => void;
  'data-pwid'?: string;
  hasSelectedDataSources?: boolean;
}

function DataFilter({
  dateRange,
  loading,
  dataInterval,
  minInterval,
  quickRange,
  setDateRange,
  onChangeExport,
  setDataInterval,
  setQuickRange,
  onRefresh,
  onDownloadImage,
  'data-pwid': dataPwId = 'data-filter',
  hasSelectedDataSources,
}: Props): JSX.Element {
  const exportButtonRef = useRef<HTMLButtonElement>(null);
  const [showExportDropdown, setShowExportDropdown] = useState<boolean>();
  const isMobile = useMobile();

  const copyUrl = () => {
    copyTextToClipboard(window.location.href);
  };

  return (
    <div className={`flex flex-row gap-2 justify-between flex-wrap`}>
      <div className={`flex flex-row gap-2 flex-wrap items-center`}>
        <Button
          icon={HeroIcons.RefreshIcon}
          onClick={onRefresh}
          disabled={loading}
          tooltip="Refresh chart with the latest data"
          data-pwid="refresh-button"
        ></Button>
        <div data-tip="Select an interval. Note: some options are disabled for larger date ranges.">
          <Select
            className="min-w-[12rem]"
            options={[
              {
                value: 'minute',
                label: 'Minutes',
                isDisabled:
                  typeof minInterval === 'string' && intervals.indexOf('minute') < intervals.indexOf(minInterval),
              },
              {
                value: 'hour',
                label: 'Hours',
                isDisabled:
                  typeof minInterval === 'string' && intervals.indexOf('hour') < intervals.indexOf(minInterval),
              },
              {
                value: 'day',
                label: 'Days',
                isDisabled:
                  typeof minInterval === 'string' && intervals.indexOf('day') < intervals.indexOf(minInterval),
              },
              {
                value: 'month',
                label: 'Months',
              },
            ]}
            value={dataInterval}
            onChangeValue={(value) => {
              ReactTooltip.hide();
              if (setDataInterval) setDataInterval(value);
            }}
            placeholder={`${
              minInterval
                ? (minInterval as string).charAt(0).toUpperCase() + (minInterval as string).slice(1) + 's'
                : ''
            }`}
            hideErrorSection
            data-pwid={`${dataPwId}-data-interval-select`}
            clearable={false}
          ></Select>
        </div>

        <div data-tip={!isMobile ? 'Select a date range' : undefined} data-pwid={`${dataPwId}-date-range-picker`}>
          <DateRangePicker
            className="flex flex-row w-full sm:w-auto justify-between"
            dateRange={dateRange}
            onChange={(range: DateRange) => {
              setDateRange(range);
            }}
            side={Side.left}
          />
        </div>
        {!isMobile && setQuickRange && (
          <>
            {QUICK_RANGE_OPTIONS.map((opt) => (
              <Button
                key={opt}
                className="w-max"
                variant={
                  opt === `${quickRange?.value}${quickRange?.units.charAt(0)}`
                    ? Variant.secondaryFilled
                    : Variant.secondary
                }
                onClick={() =>
                  setQuickRange({
                    units: luxonUnits[opt.match(/[a-z]/)?.[0] as keyof typeof luxonUnits],
                    value: parseInt(opt.match(/[0-9]*/)?.[0] ?? '24'),
                  })
                }
                size="small"
              >
                {opt}
              </Button>
            ))}
          </>
        )}
      </div>
      {!isMobile && (
        <div className={`flex flex-row gap-2 flex-wrap items-center`}>
          <Button
            tooltip="Copy url"
            icon={HeroIcons.LinkIcon}
            className="w-max"
            variant={Variant.secondaryFilled}
            onClick={copyUrl}
          ></Button>
          {onDownloadImage && (
            <Button
              tooltip="Download chart as image"
              icon={HeroIcons.PhotographIcon}
              className="w-max"
              variant={Variant.secondaryFilled}
              onClick={onDownloadImage}
            ></Button>
          )}
          <Dropdown
            content={
              <div className="flex flex-col gap-2 p-2">
                <div
                  className="highlight-color cursor-pointer"
                  onClick={() => {
                    setShowExportDropdown(false);
                    if (onChangeExport) onChangeExport('all');
                  }}
                  data-pwid={`${dataPwId}-export-all-button`}
                >
                  <Text>All System Data</Text>
                </div>
                <div
                  className={`highlight-color ${
                    hasSelectedDataSources ? 'cursor-pointer' : 'cursor-not-allowed opacity-30'
                  }`}
                  data-tip={hasSelectedDataSources ? '' : 'Select data sources to export'}
                  onClick={() => {
                    if (!hasSelectedDataSources) return;
                    setShowExportDropdown(false);
                    if (onChangeExport) onChangeExport('current');
                  }}
                  data-pwid={`${dataPwId}-export-current-button`}
                >
                  <Text>Selected System Data</Text>
                </div>
              </div>
            }
            className="relative"
            childRef={exportButtonRef}
            show={showExportDropdown}
            onClose={() => setShowExportDropdown(false)}
            onOpen={() => setShowExportDropdown(true)}
          >
            {onChangeExport && (
              <Button
                ref={exportButtonRef}
                icon={HeroIcons.DownloadIcon}
                className="w-max"
                variant={Variant.secondaryFilled}
                tooltip="Export data to a CSV file"
                data-pwid={`${dataPwId}-export-button`}
              >
                Export
              </Button>
            )}
          </Dropdown>
        </div>
      )}
    </div>
  );
}

export default function DataFilterContainer(props: Props): JSX.Element {
  const systemsContextValue = useSystemsContextValue();

  return (
    <SystemsContext.Provider value={systemsContextValue}>
      <DataFilter {...props} />
    </SystemsContext.Provider>
  );
}
