import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchSearchDistributors } from '../../../adapters/api/distributors';
import { fetchSearchOrgs, SearchOrgsResponse } from '../../../adapters/api/organizations';
import { fetchSearchSites } from '../../../adapters/api/sites';
import { patchSystem } from '../../../adapters/api/systems';
import {
  AsyncSelect,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  FormSave,
  FormStatus,
  Input,
  KeyValue,
  ReactSelectOption,
  Select,
  SelectValue,
  Text,
  TextArea,
  TextType,
} from '../../../ComponentLibrary/src';
import { usePageContext } from '../../../components/Page';
import { AuthContext } from '../../../context/Auth';
import { DistributorsContext, useDistributorsContextValue } from '../../../context/Distributors';
import { OrganizationsContext, useOrganizationsContextValue } from '../../../context/Organizations';
import { SitesContext, useSitesContextValue } from '../../../context/Sites';
import { SystemsContext } from '../../../context/Systems';
import { useMobile, useMounted, useSetDocumentTitle } from '../../../hooks';
import { EditSystem as IEditSystem, GenSystem } from '../../../types';
import { toLocalTimezone, toUtc, warrantySelectionToDate, warrantyToDropdownString } from '../../../util';
import { PERMISSIONS } from '../../../util/constants';
import { getThumbnail } from '../util';
import { SystemDates } from './Dates';

const clearedValue = {
  value: undefined,
  label: '',
};

function EditSystem(): JSX.Element {
  const navigate = useNavigate();
  const { system, getSystem } = useContext(SystemsContext);
  const { hasPermissions } = useContext(AuthContext);
  const { sysId } = useParams();
  const mounted = useMounted();
  useSetDocumentTitle(`Edit System${sysId ? ' ' + sysId : ''}`);
  const [description, setDescription] = useState(system?.systemDescription);
  const [installDescription, setInstallDescription] = useState(system?.installDescription);
  const [distributor, setDistributor] = useState<ReactSelectOption | null>({
    value: system?.distributor?._id,
    label: system?.distributor?.name ?? '',
  });
  const [organization, setOrganization] = useState<ReactSelectOption | null>({
    value: system?.org?._id,
    label: system?.org?.name ?? '',
  });
  const [site, setSite] = useState<ReactSelectOption | null>({
    value: system?.site?._id,
    label: system?.site?.name ?? '',
  });
  const [shipDate, setShipDate] = useState(toLocalTimezone(system?.shipDate));
  const [commissionDate, setCommissionDate] = useState(toLocalTimezone(system?.commissionDate));
  const [lastAnnualInspectionDate, setLastAnnualInspectionDate] = useState(
    toLocalTimezone(system?.lastAnnualInspectionDate),
  );
  const [warrantyExpirationDate, setWarrantyExpirationDate] = useState<Date | string | undefined>(
    toLocalTimezone(system?.warrantyExpirationDate),
  );
  const [subscriptionExpirationDate, setSubscriptionExpirationDate] = useState<Date | string | undefined>(
    toLocalTimezone(system?.subscriptionExpirationDate),
  );
  const [overrideSubscription, setOverrideSubscription] = useState(!!system?.subscription);
  const [warranty, setWarranty] = useState<Date | string | number | null | undefined>(system?.warranty);
  const [locked, setLocked] = useState((system as GenSystem)?.locked ?? false);
  const [optConnectModemSN, setOptConnectModemSN] = useState<string | null | undefined>(
    (system as GenSystem)?.optConnectModemSN,
  );
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();
  const [descriptionError, setDescriptionError] = useState('');
  const isMobile = useMobile();

  const enforcePermissions = useCallback(() => {
    const kickUserOut = () => {
      toast('You do not have access to this page.', {
        type: toast.TYPE.ERROR,
      });
      navigate(`/systems/${sysId}`);
    };
    if (
      !hasPermissions(
        [
          PERMISSIONS.dashboard.systems.update,
          PERMISSIONS.dashboard.systems.updateShipDate,
          PERMISSIONS.dashboard.systems.advancedUpdate,
          PERMISSIONS.dashboard.systems.updateDistributor,
          PERMISSIONS.dashboard.systems.updateOrg,
          PERMISSIONS.dashboard.systems.updateSite,
          PERMISSIONS.dashboard.systems.updateProductAccess,
        ],
        'or',
      )
    ) {
      kickUserOut();
    }
  }, [hasPermissions, navigate, sysId]);

  useEffect(() => {
    if (description?.length && description?.length > 100) {
      setDescriptionError('System description is limited to 100 characters.');
    } else {
      setDescriptionError('');
    }
  }, [description]);

  useEffect(() => {
    if (sysId)
      getSystem({ sysId: sysId + '?edit=true' }).then(() => {
        enforcePermissions();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId]);

  // Set initial values after fetch
  useEffect(() => {
    if (mounted && system) {
      resetInputs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system]);

  const resetInputs = useCallback(() => {
    if (system) {
      setDescription(system?.systemDescription);
      setInstallDescription(system?.installDescription);
      setOrganization({ value: system?.org?._id, label: system?.org?.name ?? '' });
      setDistributor({ value: system?.distributor?._id, label: system?.distributor?.name ?? '' });
      setSite({ value: system?.site?._id, label: system?.site?.name ?? '' });
      setShipDate(toLocalTimezone(system?.shipDate));
      setCommissionDate(toLocalTimezone(system?.commissionDate));
      setLastAnnualInspectionDate(toLocalTimezone(system?.lastAnnualInspectionDate));
      setWarrantyExpirationDate(toLocalTimezone(system?.warrantyExpirationDate));
      setSubscriptionExpirationDate(toLocalTimezone(system?.subscriptionExpirationDate));
      setOverrideSubscription(!!system?.subscription);
      setLocked((system as GenSystem)?.locked ?? false);
      setOptConnectModemSN((system as GenSystem)?.optConnectModemSN);
      setWarranty(system?.warranty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system]);

  const handleSaveSystem = () => {
    if (formStatus === 'dirty') setFormStatus('loading');
    // REFACTORME: using single object for changes only
    const update: IEditSystem = {
      sysId,
    };

    if (description !== system?.systemDescription) update.systemDescription = description;
    if (installDescription !== system?.installDescription) update.installDescription = installDescription;
    if (organization?.value !== system?.org?._id) update.orgId = (organization?.value as string) ?? null;
    if (distributor?.value !== system?.distributor?._id) update.distributorId = (distributor?.value as string) ?? null;
    if (site?.value !== system?.site?._id) update.siteId = (site?.value as string) ?? null;
    if (toUtc(shipDate) !== toUtc(system?.shipDate)) {
      update.shipDate = toUtc(shipDate);
      if (shipDate) {
        if (
          !confirm(
            `Are you sure you want to set the ship date to ${DateTime.fromISO(update.shipDate as string).toLocaleString(
              DateTime.DATE_FULL,
            )}?`,
          )
        ) {
          setFormStatus('dirty');
          return;
        }
      }
    }
    if (toUtc(commissionDate) !== toUtc(system?.commissionDate)) {
      update.commissionDate = toUtc(commissionDate);
      if (commissionDate) {
        if (
          !confirm(
            `Are you sure you want to set the commission date to ${DateTime.fromISO(
              update.commissionDate as string,
            ).toLocaleString(DateTime.DATE_FULL)}?`,
          )
        ) {
          setFormStatus('dirty');
          return;
        }
      }
    }

    if (toUtc(lastAnnualInspectionDate) !== toUtc(system?.lastAnnualInspectionDate))
      update.lastAnnualInspectionDate = toUtc(lastAnnualInspectionDate);

    if (
      (!warranty || typeof warranty === 'string' || typeof warranty === 'number' ? warranty : toUtc(warranty)) !==
      (!warranty || typeof system?.warranty === 'number' ? system?.warranty : toUtc(system?.warranty as string))
    ) {
      update.warranty =
        typeof warranty === 'string' || typeof warranty === 'number' ? warranty : toUtc(warranty) ?? null;
    }

    if (overrideSubscription) {
      if (toUtc(subscriptionExpirationDate) !== toUtc(system?.subscription))
        update.subscription = toUtc(subscriptionExpirationDate);
    } else {
      if (system?.subscription) update.subscription = null;
    }

    if (!locked && (system as GenSystem)?.locked) update.lockKey = null;

    if (optConnectModemSN !== (system as GenSystem)?.optConnectModemSN) update.optConnectModemSN = optConnectModemSN;

    patchSystem(update, (err: AxiosError) => {
      toast(err?.response?.data?.error ?? err.message, { type: 'error' });
      setFormStatus('dirty');
    }).then((res) => {
      if (res === undefined) return;

      if (formStatus !== 'success') setFormStatus('success');
      if (sysId) return getSystem({ sysId: sysId + '?edit=true' });
    });
  };

  const handleCancelSaveSystem = () => {
    setFormStatus(undefined);
    setFormStatus(undefined);
    setFormStatus(undefined);
    setFormStatus(undefined);
    resetInputs();
  };

  const handleChangeDescription = (newDescription: string | number) => {
    setFormStatus('dirty');
    setDescription(newDescription as string);
  };

  const handleChangeInstallDescription = (newDescription: string | number) => {
    setFormStatus('dirty');
    setInstallDescription(newDescription as string);
  };

  const handleSelectOrganization = (newOrganization: ReactSelectOption | null) => {
    setFormStatus('dirty');
    setOrganization(newOrganization);
    setSite(clearedValue);
  };

  const handleSelectDistributor = (newDistributor: ReactSelectOption | null) => {
    setFormStatus('dirty');
    setDistributor(newDistributor ?? clearedValue);
    setSite(clearedValue);
    setOrganization(clearedValue);
  };

  const handleSelectSite = (newSite: ReactSelectOption | null) => {
    setFormStatus('dirty');
    setSite(newSite ?? clearedValue);
  };

  const handleChangeShipDate = (date?: Date) => {
    setFormStatus('dirty');
    setShipDate(date);
  };

  const handleChangeCommissionDate = (date?: Date) => {
    setFormStatus('dirty');
    setCommissionDate(date);
  };

  const handleChangeLastAnnualInspectionDate = (date?: Date) => {
    setFormStatus('dirty');
    setLastAnnualInspectionDate(date);
  };

  const handleSelectWarranty = (warrantySelection: SelectValue) => {
    setFormStatus('dirty');

    const newWarrantyDate = warrantySelectionToDate(
      warrantySelection as string | number | null,
      toLocalTimezone(system?.warrantyExpirationDate),
      toLocalTimezone(system?.warrantyStandardDate),
      toLocalTimezone(system?.commissionDate),
    );

    setWarranty(warrantySelection === 'manual' ? newWarrantyDate : (warrantySelection as number));

    setWarrantyExpirationDate(newWarrantyDate);
    if (newWarrantyDate && !overrideSubscription) {
      setSubscriptionExpirationDate(newWarrantyDate);
    }
  };

  const handleSelectSubscriptionOverride = (override: boolean) => {
    setFormStatus('dirty');
    setSubscriptionExpirationDate(warrantyExpirationDate);
    setOverrideSubscription(override);
  };

  const handleSelectUnlimitedSubscription = (unlimited: boolean) => {
    setFormStatus('dirty');
    const date = unlimited ? 'unlimited' : warrantyExpirationDate;

    setSubscriptionExpirationDate(date);
  };

  const handleChangeWarrantyExpirationDate = (date?: string | Date) => {
    setFormStatus('dirty');
    setWarrantyExpirationDate(date ? new Date(date) : undefined);
    setWarranty(date ? new Date(date) : undefined);
    if (date && !overrideSubscription) {
      setSubscriptionExpirationDate(new Date(date));
    }
  };

  const handleChangeSubscriptionExpirationDate = (date?: string | Date) => {
    setFormStatus('dirty');
    setSubscriptionExpirationDate(date ? new Date(date) : undefined);
  };

  const updateSearchOrgs = async (searchTerm: string) => {
    return fetchSearchOrgs({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      distributorId: distributor?.value as string,
      searchTerm,
      count: true,
    })
      .then((response) => {
        if (response) {
          const { orgs, count } = response as SearchOrgsResponse;
          const options: ReactSelectOption[] = orgs?.map((org) => ({
            value: org._id,
            label: org.label ?? org.name,
          }));
          if (count > orgs.length) {
            options.push({
              value: 'more',
              label: `and ${count - orgs.length} more...`,
              isDisabled: true,
            });
          }
          return options;
        }
        return [];
      })
      .catch(() => []);
  };

  const updateSearchSites = async (searchTerm: string) => {
    return fetchSearchSites({
      project: ['site.name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      distributorId: distributor?.value as string,
      orgId: organization?.value as string,
      searchTerm,
      count: true,
    })
      .then((response) => {
        if (response) {
          const { sites, count } = response;
          const options: ReactSelectOption[] = response.sites?.map((site) => ({
            value: site._id,
            label: site.label ?? site.name,
          }));
          if (count > sites.length) {
            options.push({
              value: 'more',
              label: `and ${count - sites.length} more...`,
              isDisabled: true,
            });
          }
          return options;
        }
        return [];
      })
      .catch(() => []);
  };

  const updateSearchDistributors = async (searchTerm?: string) => {
    if (!hasPermissions(PERMISSIONS.dashboard.distributors.read)) return [];
    return fetchSearchDistributors({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      orgId: organization?.value as string,
      searchTerm,
      count: true,
    });
  };

  const handleDeleteLockKey = async () => {
    setLocked(false);
    setFormStatus('dirty');
  };

  const manualWarranty = warrantyToDropdownString(warranty) === 'manual';

  const loading = !system;

  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();

  useEffect(() => {
    setTitle('');
    setBreadcrumbs([
      { text: 'Systems', href: '/systems' },
      { text: `${sysId || 'System'}`, href: `/systems/${sysId}` },
      { text: 'Edit' },
    ]);
    setScrollable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // complex permissions
  const canUpdateProductAccess = useMemo(() => {
    if (!!commissionDate && !!shipDate) {
      return hasPermissions(PERMISSIONS.dashboard.systems.updateProductAccess);
    }
    return false;
  }, [commissionDate, shipDate, hasPermissions]);

  const thumbnail = getThumbnail(system);
  const isValidShipAndCommissionDates = useCallback(() => {
    if (shipDate instanceof Date && commissionDate instanceof Date) {
      return shipDate.getTime() <= commissionDate.getTime();
    }
    return true;
  }, [shipDate, commissionDate]);
  // Display ship date error if one of the following is true
  // - No ship date but commission date exists
  // - Ship date is after commission date
  const shipDateError = useMemo(() => {
    return (!shipDate && !!commissionDate) || !isValidShipAndCommissionDates();
  }, [shipDate, commissionDate, isValidShipAndCommissionDates]);

  return (
    <Form preventDefault onSubmit={handleSaveSystem} className="flex flex-col gap-4 w-full px-4 pb-4">
      <FormSave
        className="self-end"
        onCancel={handleCancelSaveSystem}
        mode="edit"
        status={formStatus}
        disablePrimary={!!descriptionError || shipDateError}
        data-pwid="system-form-save-1"
      />
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Card className="lg:col-span-2 flex flex-col gap-2 flex-wrap md:flex-nowrap items-center md:items-start">
          <Text type={TextType.h3}>Info</Text>
          <div className="w-full flex flex-row">
            {thumbnail && <img src={thumbnail} className="h-36 md:w-48 md:h-48 self-center mx-8" />}

            <div className="flex flex-col w-full gap-2">
              <Input
                className={`${isMobile ? 'w-full' : 'flex-1'}`}
                tooltip="A short description of the system. For more information, see action logs."
                onChangeValue={handleChangeDescription}
                label="Description"
                value={description ?? ''}
                hideErrorSection={!descriptionError}
                errorMessage={descriptionError}
                revealErrorMessage={!!descriptionError}
                loading={loading}
                disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                data-pwid="system-description-input"
              />
              <Input
                className={`${isMobile ? 'w-full' : 'flex-1'}`}
                tooltip="System type (set in firmware)"
                disabled
                label="Type"
                value={system?.model ?? ''}
                hideErrorSection
                loading={loading}
                data-pwid="system-type-input"
              />
              <TextArea
                className={`${isMobile ? 'w-full' : 'flex-1'}`}
                tooltip="A short description of the installation. For more information, see action logs."
                label="Install Description"
                onChangeValue={handleChangeInstallDescription}
                loading={loading}
                value={installDescription}
                disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                data-pwid="system-install-description-input"
              />
            </div>
          </div>
        </Card>
        <SystemDates
          isMobile={isMobile}
          isLoading={loading}
          hasWarrantyExpirationDate={!!warrantyExpirationDate}
          hasSubscriptionExpirationDate={!!subscriptionExpirationDate}
          shipDate={shipDate}
          commissionDate={commissionDate}
          lastAnnualInspectionDate={lastAnnualInspectionDate}
          isValidShipAndCommissionDates={isValidShipAndCommissionDates}
          handleChangeShipDate={handleChangeShipDate}
          handleChangeCommissionDate={handleChangeCommissionDate}
          handleChangeLastAnnualInspectionDate={handleChangeLastAnnualInspectionDate}
        />
        <Card className="flex-1 flex flex-col gap-2 flex-wrap mb-4 md:mb-0">
          <Text type={TextType.h3} wrap>
            Warranty and Subscription
          </Text>
          {!shipDate || !commissionDate ? (
            <Text type={TextType.body} overrideColor wrap className="text-gray-400 italic">
              <ExclamationCircleIcon className="mr-1 pb-[0.1rem] w-5 h-5 inline" />
              Ship and commission dates must be set first
            </Text>
          ) : (
            <>
              <Select
                className="flex-1"
                label="Warranty"
                tooltip="The warranty expiration on the unit"
                options={[
                  {
                    value: null,
                    label: 'Standard',
                  },
                  {
                    value: 1,
                    label: '1 Year Extended',
                  },
                  {
                    value: 2,
                    label: '2 Year Extended',
                  },
                  {
                    value: 3,
                    label: '3 Year Extended',
                  },
                  {
                    value: 'manual',
                    label: 'Manual Override',
                  },
                ]}
                value={warrantyToDropdownString(warranty)}
                onChangeValue={handleSelectWarranty}
                hideErrorSection
                clearable={false}
                loading={loading}
                disabled={!canUpdateProductAccess}
                data-pwid="warranty-type-select"
              />

              {warrantyExpirationDate && warrantyExpirationDate !== 'unlimited' && (
                <DatePicker
                  className="flex-1"
                  label="Warranty Expiration Date"
                  tooltip="Effective warranty expiration on the unit"
                  date={warrantyExpirationDate}
                  onChange={handleChangeWarrantyExpirationDate}
                  disabled={!manualWarranty || !canUpdateProductAccess}
                  data-pwid="warranty-date-picker"
                />
              )}

              <Checkbox
                onChangeValue={handleSelectSubscriptionOverride}
                checked={overrideSubscription}
                label="Override Subscription"
                hideErrorSection
                disabled={!canUpdateProductAccess}
              />

              <Checkbox
                className={`${!overrideSubscription ? 'invisible' : ''}`}
                onChangeValue={handleSelectUnlimitedSubscription}
                checked={subscriptionExpirationDate === 'unlimited'}
                label="Unlimited Subscription"
                hideErrorSection
                disabled={!canUpdateProductAccess}
              />

              {(subscriptionExpirationDate || overrideSubscription) && subscriptionExpirationDate !== 'unlimited' && (
                <DatePicker
                  label="Subscription Expiration Date"
                  tooltip={`Effective ${process.env.REACT_APP_NAME} subscription expiration on the unit`}
                  date={subscriptionExpirationDate}
                  onChange={handleChangeSubscriptionExpirationDate}
                  disabled={!overrideSubscription || !canUpdateProductAccess}
                  data-pwid="subscription-date-picker"
                />
              )}
            </>
          )}
        </Card>

        <Card className="xl:col-span-2 2xl:col-span-1 flex flex-col md:flex-row gap-2 flex-wrap md:flex-nowrap items-center md:items-start">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row gap-2 items-center">
              <Text type={TextType.h3}>Allocation</Text>
            </div>
            {/* // TODO: Memoize to avoid unecessary fetches */}
            <AsyncSelect
              // workaround to trigger loading options when dist, org or site changes, see https://github.com/JedWatson/react-select/issues/1879
              key={`dist-${distributor?.value?.toString() ?? ''}-${organization?.value?.toString() ?? ''}-${
                site?.value?.toString() ?? ''
              }`}
              className={`${isMobile ? 'w-full' : 'flex-1'}`}
              label="Distributor"
              tooltip="Distributor that sold the unit"
              onLoadOptions={updateSearchDistributors}
              placeholder={system?.distributor?.name}
              onChange={handleSelectDistributor}
              hideErrorSection
              loading={loading}
              clearable
              value={distributor ?? undefined}
              disabled={!hasPermissions(PERMISSIONS.dashboard.systems.updateDistributor)}
              data-pwid="system-distributor-select"
            />
            {/* // TODO: Memoize to avoid unecessary fetches */}
            <AsyncSelect
              // workaround to trigger loading options when org, distributor or site changes, see https://github.com/JedWatson/react-select/issues/1879
              key={`org-${distributor?.value?.toString() ?? ''}-${organization?.value?.toString() ?? ''}-${
                site?.value?.toString() ?? ''
              }`}
              className={`${isMobile ? 'w-full' : 'flex-1'}`}
              label="Organization"
              tooltip="The organization or customer entity name"
              onLoadOptions={updateSearchOrgs}
              placeholder={system?.org?.name}
              onChange={handleSelectOrganization}
              hideErrorSection
              loading={loading}
              clearable
              value={organization ?? undefined}
              disabled={!hasPermissions(PERMISSIONS.dashboard.systems.updateOrg)}
              data-pwid="system-org-select"
            />
            {/* // TODO: Memoize to avoid unecessary fetches */}
            <AsyncSelect
              // workaround to trigger loading options when dist, site, or org changes, see https://github.com/JedWatson/react-select/issues/1879
              key={`site-${distributor?.value?.toString() ?? ''}-${organization?.value?.toString() ?? ''}-${
                site?.value?.toString() ?? ''
              }`}
              className="flex-1"
              label="Site"
              tooltip="The name of the site"
              onLoadOptions={updateSearchSites}
              placeholder={system?.site?.name}
              onChange={handleSelectSite}
              hideErrorSection
              loading={loading}
              disabled={!organization?.value || !hasPermissions(PERMISSIONS.dashboard.systems.updateSite)}
              value={site ?? undefined}
              clearable
              data-pwid="system-site-select"
            />
            {/* TODO: Use https://react-select.com/creatable instead */}
            {/* {organization?.value && (
              <Button onClick={() => navigate(`/assets/createSite/${organization.value}`)}>Allocate</Button>
            )} */}
          </div>
        </Card>

        <Card className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <Text type={TextType.h3}>Other</Text>
          </div>
          <div className="flex flex-row w-full gap-2 flex-wrap md:flex-nowrap">
            {hasPermissions([PERMISSIONS.dashboard.systems.advancedUpdate]) && (
              <div className="flex-1 flex flex-col gap-2 justify-center">
                <div className="flex flex-row justify-between">
                  <KeyValue
                    label="Dashboard Link"
                    value={locked ? 'Linked' : 'Unlinked'}
                    valueColor={locked ? 'text-emerald-500' : 'text-red-500'}
                  />
                  <Button onClick={handleDeleteLockKey} disabled={!locked}>
                    Unlink
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-row w-full gap-2 items-end flex-wrap md:flex-nowrap">
            {optConnectModemSN === undefined || optConnectModemSN === null ? (
              <Button onClick={() => setOptConnectModemSN('')} data-pwid="set-modem-button">
                Set Modem
              </Button>
            ) : (
              <>
                <Input
                  className={`${isMobile ? 'w-full' : 'flex-1'}`}
                  tooltip="Opt Connect Modem Serial Number"
                  label="Modem Serial"
                  value={optConnectModemSN ?? undefined}
                  hideErrorSection
                  loading={loading}
                  onChangeValue={(value) => {
                    setOptConnectModemSN(value as string);
                    setFormStatus('dirty');
                  }}
                  disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                  data-pwid="system-modem-serial-input"
                />
                {(system as GenSystem)?.optConnectModemSN && (
                  <Button
                    onClick={() => {
                      setOptConnectModemSN(null);
                      setFormStatus('dirty');
                    }}
                    disabled={!hasPermissions(PERMISSIONS.dashboard.systems.update)}
                  >
                    Unset
                  </Button>
                )}
              </>
            )}
          </div>
        </Card>
      </div>
      <FormSave
        className="self-end"
        onCancel={handleCancelSaveSystem}
        mode="edit"
        status={formStatus}
        disablePrimary={!!descriptionError || shipDateError}
        data-pwid="system-form-save-2"
      />
    </Form>
  );
}

export default function EditSystemContainer(): JSX.Element {
  const organizationsContextValue = useOrganizationsContextValue();
  const distributorsContextValue = useDistributorsContextValue();
  const sitesContextValue = useSitesContextValue();

  return (
    <OrganizationsContext.Provider value={organizationsContextValue}>
      <DistributorsContext.Provider value={distributorsContextValue}>
        <SitesContext.Provider value={sitesContextValue}>
          <EditSystem />
        </SitesContext.Provider>
      </DistributorsContext.Provider>
    </OrganizationsContext.Provider>
  );
}
