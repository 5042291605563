import { debounce, DebouncedFunc } from 'lodash';

import colors from '../../ComponentLibrary/src/style/colors';
import { getUniquePalletteColor } from '../../ComponentLibrary/src/util';

export type DebouncedFunction<S> = (
  func: (...args: S[]) => void,
  delay?: number,
  trailing?: boolean,
) => DebouncedFunc<(...args: S[]) => void>;

export const generateDistributorColor = (name: string, syncedWithParent?: boolean): string => {
  if (syncedWithParent === false) return colors.gray['300'];

  const color = getUniquePalletteColor(name);

  return color;
};

export const debouncedFunction = <T>(
  func: (item: T) => void,
  delay = 1000,
  trailing = true,
): DebouncedFunc<(arg0: T) => void> => {
  return debounce(func, delay, { trailing });
};
