import { CheckCircleIcon, LocationMarkerIcon, XCircleIcon } from '@heroicons/react/outline';
import { StatusOnlineIcon } from '@heroicons/react/solid';
import L, { Marker as TMarker } from 'leaflet';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, useMapEvents, ZoomControl } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchSearchDistributors } from '../../adapters/api/distributors';
import { fetchOrg, fetchOrgs } from '../../adapters/api/organizations';
import { patchSite, postSite } from '../../adapters/api/sites';
import { autoCompleteLocation, getPlaceDetails, gmApiKey } from '../../adapters/googleMaps';
import {
  AsyncSelect,
  Button,
  Card,
  Form,
  FormSave,
  FormStatus,
  HeroIcons,
  Input,
  InputType,
  ReactSelectOption,
  Text,
  TextType,
  Type,
} from '../../ComponentLibrary/src';
import colors from '../../ComponentLibrary/src/style/colors';
import { useMounted } from '../../ComponentLibrary/src/util/hooks';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { DistributorsContext, useDistributorsContextValue } from '../../context/Distributors';
import { OrganizationsContext, useOrganizationsContextValue } from '../../context/Organizations';
import { SitesContext, useSitesContextValue } from '../../context/Sites';
import { useMobile } from '../../hooks';
import { Organization, Site as ISite } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import { validateSiteName } from './formValidation';
import { GasCompositionProps } from './types';

const earthBounds = new L.LatLngBounds([
  [-90, -180],
  [90, 180],
]);

interface SiteProps {
  edit?: boolean;
}

function Site({ edit }: SiteProps): JSX.Element {
  const markerRef = useRef<TMarker>(null);
  const { t } = useTranslation(['assets', 'sites', 'translation']);
  const { siteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermissions } = useContext(AuthContext);
  const { getSite, site } = useContext(SitesContext);
  const { orgId } = useParams() as { orgId: string };
  const isMobile = useMobile();
  const [siteName, setSiteName] = useState<string>(site.name);
  const [distributor, setDistributor] = useState<ReactSelectOption>();
  const [latitude, setLatitude] = useState<number | undefined>(site.coords?.latitude);
  const [longitude, setLongitude] = useState<number | undefined>(site.coords?.longitude);
  const [gasComposition, setGasComposition] = useState<ISite['gasComposition']>({
    ...site.gasComposition,
  });

  const [totalIsValid, setTotalIsValid] = useState<boolean>(false);
  const [totalErrorMessage, setTotalErrorMessage] = useState<string>('');
  const [clickToSet, setClickToSet] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [revealErrorMessage, setRevealErrorMessage] = useState(false);
  const [loading, setLoading] = useState(edit);
  const [map, setMap] = useState<L.Map | null>(null);
  const [siteSearched, setSiteSearched] = useState<ReactSelectOption | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();
  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [showSatellite, setShowSatellite] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>({
    _id: orgId,
    name: '',
  });
  const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
  const mounted = useMounted();

  const MapEvents = () => {
    const map = useMapEvents({
      click(event) {
        if (clickToSet) {
          setLatitude(event.latlng.lat);
          setLongitude(event.latlng.lng);
          setClickToSet(false);
        }
      },
    });
    if (clickToSet) {
      map.getContainer().style.cursor = 'crosshair';
    } else {
      map.getContainer().style.cursor = 'grab';
    }
    return null;
  };

  // fetch org name if orgId was passed in the url
  useEffect(() => {
    if (orgId && selectedOrg && !selectedOrg.name) {
      fetchOrg(orgId)
        .then((org) => {
          if (org?.name) {
            setSelectedOrg({
              ...selectedOrg,
              name: org.name ?? '',
            });
          }
        })
        .catch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    const title = t(edit ? 'sites:edit_site' : 'sites:create_site');
    setTitle('');
    setBreadcrumbs([{ text: 'Assets', href: '/assets' }, { text: title }]);
    setScrollable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    if (edit && siteId) {
      getSite(siteId).then(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, siteId]);

  useEffect(() => {
    resetInputs();
    if (site) setSelectedOrg(site.org ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    resetInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    const { ch4, co2, other } = gasComposition;
    const isValid = ch4 + co2 + (other ?? 0) === 100;
    setTotalIsValid(isValid);

    if (isValid) setTotalErrorMessage('');
  }, [gasComposition]);

  useEffect(() => {
    if (latitude && longitude) markerRef.current?.setLatLng(new L.LatLng(latitude, longitude));
  }, [latitude, longitude]);

  const resetInputs = () => {
    setSiteName(site?.name ?? '');
    setDistributor({
      value: site?.distributor?._id,
      label: site?.distributor?.name ?? '',
    });
    setLatitude(site?.coords?.latitude ?? 0);
    setLongitude(site?.coords?.longitude ?? 0);
    setSelectedOrg(site?.org ?? null);
    setGasComposition(site.gasComposition);
  };

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const { lat, lng } = marker.getLatLng();
          setLatitude(lat);
          setLongitude(lng);
        }
        setDragging(false);
      },
      dragstart() {
        setDragging(true);
      },
      // update the map view when the marker location changes
      move() {
        if (map && markerRef.current && !dragging && !clickToSet) {
          map.setView(markerRef.current.getLatLng(), map.getZoom());
        }
      },
    }),
    [map, dragging, clickToSet],
  );

  const handleGetGps = () => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;
        setLatitude(latitude);
        setLongitude(longitude);
      },
      (err) => alert(err.message),
      {
        enableHighAccuracy: true,
      },
    );
  };

  const didUpdateGasComp = () => {
    const { ch4, co2, ger } = site.gasComposition;
    return ch4 !== gasComposition.ch4 || co2 !== gasComposition.co2 || ger !== gasComposition.ger;
  };

  const handleSaveSite = () => {
    // validate form
    if (!validateSiteName(siteName) || (!edit && !selectedOrg)) {
      setRevealErrorMessage(true);
      return;
    }

    if (!totalIsValid) {
      setTotalErrorMessage(t('sites:total_error'));
      return;
    }
    setFormStatus('loading');
    const site: Omit<ISite, '_id'> = {
      name: siteName,
      orgId: selectedOrg?._id ?? orgId,
      gasComposition,
    };

    if (distributor) {
      site.distributorId = distributor.value as string;
    }

    if (latitude && longitude) {
      site.coords = {
        latitude,
        longitude,
      };
    }

    if (edit) {
      patchSite(siteId as string, { ...site, gasComposition: didUpdateGasComp() ? gasComposition : undefined }).then(
        (response) => {
          if (!response) {
            setFormStatus('dirty');
            return;
          }
          toast(t('sites:success_message', { method: 'updated' }), { type: 'success' });
          getSite(siteId as string).then(() => setFormStatus('success'));
          setRevealErrorMessage(false);
          location.key === 'default' ? navigate('/') : window.history.back();
        },
      );
    } else {
      postSite(site).then((response) => {
        if (!(response as unknown)) {
          setFormStatus('dirty');
          return;
        }
        toast(t('sites:success_message', { method: 'created' }), { type: 'success' });
        setFormStatus('success');
        location.key === 'default' ? navigate('/') : window.history.back();
      });
    }
  };

  const handleCancelSaveSite = () => {
    if (edit) {
      setRevealErrorMessage(false);
      setFormStatus(undefined);
      resetInputs();
    } else {
      navigate('/assets');
    }
  };

  const handleChangeSite = (name: string | number) => {
    // gray out save button if site name is invalid
    setFormStatus('dirty');
    setSiteName(name as string);
  };

  const updateSearchDistributors = async (searchTerm: string) => {
    if (!hasPermissions(PERMISSIONS.dashboard.distributors.read)) return [];
    if (selectedOrg && selectedOrg.distributors) {
      return selectedOrg.distributors.map((distributor) => {
        return {
          value: distributor._id,
          label: distributor.name,
        };
      });
    }
    return fetchSearchDistributors({
      project: ['name'],
      pageNumber: 1,
      countPerPage: 10,
      sort: {
        name: 1,
      },
      orgId,
      searchTerm,
    });
  };

  const handleSelectDistributor = (newDistributor: ReactSelectOption | null) => {
    setFormStatus('dirty');
    setDistributor(
      newDistributor ?? {
        value: undefined,
        label: '',
      },
    );
  };

  const handleSelectSite = (newSite: ReactSelectOption | null) => {
    setFormStatus('dirty');
    setSiteSearched(newSite);
    getPlaceDetails(newSite?.value as string).then((details) => {
      setLatitude(details?.geometry?.location?.lat());
      setLongitude(details?.geometry?.location?.lng());
    });
  };

  const handleToggleControls = useCallback(() => {
    if (showSatellite) {
      addLeafletFilterStyle('');
    }
    setShowSatellite(!showSatellite);
  }, [showSatellite]);

  const handleChangeOrganization = useCallback(
    (selectedOrg: ReactSelectOption | null) => {
      if (!selectedOrg) return;
      const foundOrg = allOrganizations.find((org) => org._id === selectedOrg.value);
      setSelectedOrg(foundOrg ?? null);
      setFormStatus('dirty');
    },
    [allOrganizations],
  );

  const handleGasCompChange = useCallback(
    (prop: GasCompositionProps, composition: number) => {
      setFormStatus('dirty');
      if (Number.isNaN(composition) || composition < 0) {
        setGasComposition({ ...gasComposition, [prop]: 0 });
      } else if (composition > 100) {
        setGasComposition({ ...gasComposition, [prop]: 100 });
      } else {
        setGasComposition({ ...gasComposition, [prop]: composition });
      }
    },
    [gasComposition],
  );

  const handleFetchOrgs = useCallback((searchTerm: string) => {
    return fetchOrgs({
      count: true,
      'page[number]': 1,
      'page[size]': 10,
      textSearch: searchTerm,
      sort: ['name'],
    }).then((res: unknown) => {
      const { orgs, count } = res as {
        orgs: Organization[];
        count: number;
      };
      setAllOrganizations(orgs);
      return [
        ...orgs.map((org) => ({
          value: org._id,
          label: org.label ?? org.name,
        })),
        ...(count > orgs.length
          ? [
              {
                value: '',
                label: `And ${count - orgs.length} more...`,
                isDisabled: true,
              },
            ]
          : []),
      ] as ReactSelectOption[];
    });
  }, []);

  const distributorsSynced = useMemo(() => {
    if (!site) return true;
    if (!selectedOrg?.distributors) return true;
    if (!distributor) return true;
    if (selectedOrg && selectedOrg.distributors.length === 0) return false;

    return selectedOrg.distributors.some((parentDist) => parentDist._id === distributor.value);
  }, [selectedOrg, distributor, site]);

  const layerControl = useMemo(
    () => (
      <div
        className={`absolute z-900 bottom-4 left-4 bg-white bg-opacity-50 p-1 backdrop-blur-sm rounded-sm text-lg shadow ${
          isMobile ? 'self-end' : ''
        }`}
        onClick={handleToggleControls}
      >
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="32px"
          height="32px"
          viewBox="0 0 48.698 48.698"
          // style="enable-background:new 0 0 48.698 48.698;"
          xmlSpace="preserve"
        >
          <g color="#002255">
            <polygon points="47.784,13.309 24.349,0 0.914,13.309 24.349,26.698" fill="currentColor" />
            <polygon
              points="24.349,29.002 8.548,19.974 0.914,24.309 24.349,37.698 47.784,24.309 40.151,19.974"
              fill="currentColor"
            />
            <polygon
              points="24.349,40.002 8.548,30.974 0.914,35.309 24.349,48.698 47.784,35.309 40.151,30.974"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
    ),
    [isMobile, handleToggleControls],
  );

  const addLeafletFilterStyle = (style: string) => {
    // https://developer.mozilla.org/en-US/docs/Web/CSS/filter
    const leafletTilePanes = document.getElementsByClassName('leaflet-tile-pane');

    for (let i = 0; i < leafletTilePanes.length; i++) {
      const el = leafletTilePanes[i] as HTMLElement;
      el.style.filter = style;
    }
  };

  const orgValidationErr = !distributorsSynced
    ? t('unsynced_distributor_site')
    : !selectedOrg
    ? t('org_not_selected')
    : undefined;

  return (
    <Form
      className={`h-full flex flex-col gap-2 ${isMobile ? 'p-2' : 'px-4'}`}
      preventDefault
      onSubmit={handleSaveSite}
    >
      <FormSave
        className="self-end"
        onCancel={handleCancelSaveSite}
        mode={t(edit ? 'edit' : 'create')}
        status={formStatus}
      />
      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <div className="flex max-md:flex-col gap-2">
          <Card className="flex flex-col flex-1">
            <Text className="mb-2" type={TextType.h4}>
              {t('General')}
            </Text>
            <Input
              className="w-full"
              tooltip={t('sites:site_name_tooltip')}
              onChangeValue={handleChangeSite}
              label={t('sites:site_name')}
              value={siteName}
              errorMessage={
                !validateSiteName(siteName)
                  ? t('assets:name_length_error', {
                      length: 3,
                    })
                  : undefined
              }
              revealErrorMessage={revealErrorMessage}
              loading={loading}
              data-pwid="site-name-input"
            />
            <AsyncSelect
              className="w-full"
              label={t('Distributor')}
              tooltip={t('sites:distributor_tooltip')}
              onLoadOptions={updateSearchDistributors}
              placeholder={distributor?.label as string}
              onChange={handleSelectDistributor}
              menuPlacement="bottom"
              loading={loading}
              clearable
              value={distributor}
              disabled={!hasPermissions(PERMISSIONS.dashboard.distributors.read)}
              data-pwid="distributor-select"
            />
            <AsyncSelect
              className="w-full"
              tooltip={t('sites:organization_tooltip')}
              onLoadOptions={handleFetchOrgs}
              onChange={handleChangeOrganization}
              label={t('Organization')}
              menuPlacement="bottom"
              value={selectedOrg ? { label: selectedOrg.name, value: selectedOrg._id } : undefined}
              errorMessage={orgValidationErr}
              revealErrorMessage={revealErrorMessage}
              data-pwid="org-select"
            />
          </Card>
          <Card className="flex flex-col flex-1">
            <Text className="mb-2" type={TextType.h4}>
              {t('sites:gas_composition')}
            </Text>
            <Input
              type={InputType.number}
              tooltip={t('sites:methane_tooltip')}
              onChangeValue={(methane) => handleGasCompChange(GasCompositionProps.ch4, methane as number)}
              label="CH4 (%)"
              value={gasComposition.ch4}
              min={0}
              max={100}
              step={1}
              precision={1}
              data-pwid="methane-input"
            />
            <Input
              type={InputType.number}
              tooltip={t('sites:co2_tooltip')}
              onChangeValue={(co2) => handleGasCompChange(GasCompositionProps.co2, co2 as number)}
              label="CO2 (%)"
              value={gasComposition.co2}
              min={0}
              max={100}
              step={1}
              precision={1}
              data-pwid="co2-input"
            />
            <Input
              type={InputType.number}
              tooltip={t('sites:other_tooltip')}
              onChangeValue={(other) => handleGasCompChange(GasCompositionProps.other, other as number)}
              label={t('others')}
              value={gasComposition.other}
              min={0}
              max={100}
              step={1}
              precision={1}
              data-pwid="other-input"
            />
            <div className="flex justify-between">
              <Text
                type={TextType.custom}
                align="right"
                textClassName={`text-small font-normal inline-flex ${
                  totalIsValid ? 'text-emerald-500' : 'text-red-500'
                }`}
                overrideColor
              >
                {totalIsValid ? <CheckCircleIcon className="w-5" /> : <XCircleIcon className="w-5" />}
                &nbsp;
                {t('sites:total', { amount: gasComposition.ch4 + gasComposition.co2 + (gasComposition.other ?? 0) })}
              </Text>
              {!totalIsValid && totalErrorMessage && (
                <Text textClassName="text-red-500" overrideColor>
                  {totalErrorMessage}
                </Text>
              )}
            </div>
            <div className="border-b my-2"></div>
            <Text className="my-2" type={TextType.h4}>
              {t('sites:advanced_parameters')}
            </Text>
            <Input
              type={InputType.number}
              onChangeValue={(ger) => handleGasCompChange(GasCompositionProps.ger, ger as number)}
              label={t('sites:ger')}
              value={gasComposition.ger}
              min={0.5}
              max={1.5}
              step={0.0001}
              data-pwid="ger-input"
            />
          </Card>
        </div>
        <Card className="flex flex-col">
          <Text className="mb-2" type={TextType.h4}>
            {t('sites:location')}
          </Text>
          <div className="flex flex-row gap-2 flex-wrap w-full">
            <Input
              className="w-full flex-1"
              type={InputType.number}
              tooltip={t('sites:lat_tooltip')}
              onChangeValue={(lat: number | string) => {
                setFormStatus('dirty');
                setLatitude(lat as number);
              }}
              label={t('sites:latitude')}
              value={latitude ?? ''}
              rightIcon={HeroIcons.MapIcon}
              hideErrorSection
              min={-90}
              max={90}
              step="any"
              data-pwid="latitude-input"
            />
            <Input
              className="w-full"
              type={InputType.number}
              tooltip={t('sites:long_tooltip')}
              onChangeValue={(long: number | string) => {
                setFormStatus('dirty');
                setLongitude(long as number);
              }}
              label={t('sites:longitude')}
              value={longitude ?? ''}
              rightIcon={HeroIcons.MapIcon}
              hideErrorSection
              min={-180}
              max={180}
              step="any"
              data-pwid="longitude-input"
            />
          </div>
          <div className="flex flex-row gap-2 flex-wrap w-full">
            <div className="flex flex-1 gap-2 items-end">
              <AsyncSelect
                className="w-full"
                label={t('sites:site_location')}
                tooltip={t('sites:site_location_tooltip')}
                menuPlacement="bottom"
                onLoadOptions={autoCompleteLocation}
                onChange={handleSelectSite}
                value={siteSearched ?? undefined}
                hideErrorSection
                loading={loading}
                clearable
                data-pwid="site-location-input"
              />
              <Button
                type={Type.button}
                onClick={() => {
                  setLatitude(undefined);
                  setLongitude(undefined);
                  setFormStatus('dirty');
                }}
                data-pwid="unset-location-button"
              >
                {t('Unset')}
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center flex-wrap w-full justify-end">
            <StatusOnlineIcon className="text-blue-800 h-5 w-5 cursor-pointer" onClick={handleGetGps} />
            <LocationMarkerIcon
              className={`${clickToSet ? 'text-emerald-500' : 'text-blue-800'} h-5 w-5 cursor-pointer`}
              onClick={() => setClickToSet(!clickToSet)}
            />
          </div>
          <div className="z-0 relative min-h-[32rem]">
            {!isMobile && (
              <div className="absolute bottom-0 left-0 z-600 p-4 flex flex-col gap-4 items-center">{layerControl}</div>
            )}
            <MapContainer
              ref={setMap}
              className="w-full h-full"
              bounds={latitude && longitude ? new L.LatLngBounds([[latitude, longitude]]) : earthBounds}
              zoom={10}
              zoomControl={false}
              scrollWheelZoom={!isMobile}
              maxBounds={earthBounds}
              boundsOptions={{
                maxZoom: 8,
                padding: [50, 50],
              }}
              minZoom={2}
              maxZoom={20}
              maxBoundsViscosity={1}
              attributionControl={false}
            >
              {mounted && showSatellite && (
                <ReactLeafletGoogleLayer
                  apiKey={gmApiKey}
                  type="hybrid"
                  eventHandlers={{
                    add: () => {
                      addLeafletFilterStyle('saturate(60%) brightness(130%) contrast(60%)');
                    },
                  }}
                  googleMapsLoaderConf={{
                    apiKey: gmApiKey,
                    libraries: ['places'],
                  }}
                />
              )}
              {!showSatellite && (
                <ReactLeafletGoogleLayer
                  apiKey={gmApiKey}
                  type="roadmap"
                  styles={[
                    // https://developers.google.com/maps/documentation/javascript/style-reference
                    { featureType: 'all', stylers: [{ saturation: -100 }] },
                    {
                      featureType: 'water',
                      stylers: [{ color: colors.blue['800'] }, { saturation: -60 }, { lightness: 70 }],
                    },
                    { elementType: 'labels', stylers: [{ visibility: 'off' }] },
                    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
                    { featureType: 'administrative', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
                    {
                      featureType: 'administrative.country',
                      elementType: 'geometry',
                      stylers: [{ visibility: 'on' }],
                    },
                    {
                      featureType: 'administrative.province',
                      elementType: 'geometry',
                      stylers: [{ visibility: 'on' }],
                    },
                    {
                      featureType: 'administrative.locality',
                      elementType: 'geometry',
                      stylers: [{ visibility: 'on' }],
                    },
                    {
                      featureType: 'administrative.neighborhood',
                      elementType: 'geometry',
                      stylers: [{ visibility: 'on' }],
                    },
                    {
                      featureType: 'administrative.land_parcel',
                      elementType: 'geometry',
                      stylers: [{ visibility: 'on' }],
                    },
                    { featureType: 'administrative.locality', elementType: 'labels', stylers: [{ visibility: 'on' }] },
                    { featureType: 'administrative.country', elementType: 'labels', stylers: [{ visibility: 'on' }] },
                  ]}
                  googleMapsLoaderConf={{
                    apiKey: gmApiKey,
                    libraries: ['places'],
                  }}
                />
              )}
              {typeof latitude !== 'undefined' && typeof longitude !== 'undefined' && (
                <Marker
                  ref={markerRef}
                  position={[latitude || 0, longitude || 0]}
                  draggable
                  eventHandlers={eventHandlers}
                />
              )}
              <MapEvents />
              <ZoomControl position="bottomright" />
            </MapContainer>
          </div>
        </Card>
      </div>
    </Form>
  );
}

export default function SiteContainer(props: SiteProps): JSX.Element {
  const sitesContextValue = useSitesContextValue();
  const distributorsContextValue = useDistributorsContextValue();
  const organizationsContextValue = useOrganizationsContextValue();

  return (
    <SitesContext.Provider value={sitesContextValue}>
      <DistributorsContext.Provider value={distributorsContextValue}>
        <OrganizationsContext.Provider value={organizationsContextValue}>
          <Site {...props} />
        </OrganizationsContext.Provider>
      </DistributorsContext.Provider>
    </SitesContext.Provider>
  );
}
