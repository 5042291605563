import { TrashIcon } from '@heroicons/react/solid';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { deleteFault } from '../../adapters/api/faults';
import { Button, Column, HeroIcons, Link, Text, Variant } from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { FaultsContext, useFaultsContextValue } from '../../context/Faults';
import { useMobile, usePaginationQuery, useSetDocumentTitle } from '../../hooks';
import { ProductFamily } from '../../types';
import { PERMISSIONS } from '../../util/constants';
import Desktop from './Desktop';
import Mobile from './Mobile';

const defaultCountPerPage = 20;
const initialColumns = [
  {
    id: 'faultCode',
    label: 'Code',
    width: '6rem',
  },
  {
    id: 'description',
    label: 'Description',
    width: 2,
  },
  {
    id: 'severity',
    label: 'Severity',
    width: 1,
  },
  {
    id: 'simple.possibleCauses',
    label: 'Simple Causes',
    width: 1,
  },
  {
    id: 'simple.whatToDo',
    label: 'Simple Solutions',
    width: 1,
  },
  {
    id: 'advanced.possibleCauses',
    label: 'Advanced Causes',
    width: 1,
  },
  {
    id: 'advanced.whatToDo',
    label: 'Advanced Solutions',
    width: 1,
  },
  {
    id: 'actions',
    label: '',
    width: '4rem',
  },
];

interface Props {
  productFamily: ProductFamily;
}

function Faults({ productFamily }: Props): JSX.Element {
  useSetDocumentTitle('Faults');
  const navigate = useNavigate();
  const { hasPermissions } = useContext(AuthContext);
  const { getFaults, allFaults, total } = useContext(FaultsContext);
  const isMobile = useMobile();
  const { sort, setSort, order, setOrder, page, setPage, pageSize, setPageSize, textSearch, setTextSearch } =
    usePaginationQuery({
      sort: 'faultCode',
      order: 1,
      page: 1,
      pageSize: defaultCountPerPage,
    });
  const [columns, setColumns] = useState(initialColumns as Column[]);
  const { setTitle, setBreadcrumbs, setScrollable, scrollToTopOfPage } = usePageContext();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['translation', 'faults']);

  const enforcePermissions = useCallback(() => {
    const kickUserOut = () => {
      toast(t('No Access'), {
        type: toast.TYPE.ERROR,
      });
      navigate('/');
    };
    if (!hasPermissions(PERMISSIONS.dashboard.faults.update)) {
      kickUserOut();
    }
  }, [hasPermissions, navigate, t]);

  useEffect(() => {
    setTitle('Faults');
    setBreadcrumbs();
    setScrollable(isMobile);
    ReactTooltip.rebuild();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFaults = async () => {
    return getFaults({
      countPerPage: pageSize,
      pageNumber: page,
      sort:
        sort && order
          ? {
              [sort]: order,
            }
          : {
              faultCode: 1,
            },
      productFamily,
      textSearch,
    }).finally(() => {
      setLoading(false);
      enforcePermissions();
    });
  };

  useEffect(() => {
    updateFaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, page, textSearch, sort, order, pageSize, productFamily]);

  // show loading and scroll to top of table when filter/pagination changes
  useEffect(() => {
    setLoading(true);
    if (isMobile) {
      scrollToTopOfPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, page, pageSize, order, textSearch]);

  const handleChangePageNumber = (newPageNumber: number) => {
    setPage(newPageNumber);
  };

  const handleChangeColumns = (newColumns: Column[]) => {
    const sortCol = newColumns.find((col) => !!col.sortDirection);
    if (sortCol) {
      setSort(sortCol.id.toString());
      if (sortCol.sortDirection === 'down') setOrder(-1);
      if (sortCol.sortDirection === 'up') setOrder(1);
    }
    setColumns(newColumns);
  };

  const handleDeleteFault = (faultCode: number) => {
    if (confirm(t('faults:delete_confirmation'))) {
      deleteFault(faultCode)
        .then(() => updateFaults())
        .catch((err) => {
          toast(err?.response?.data?.error ?? err.message, { type: 'error' });
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchTermDebounced = useCallback(
    debounce((term: string) => {
      setTextSearch(term);
    }, 500),
    [],
  );

  const actions = (
    <div className="flex flex-row gap-2 items-center">
      <Link href="/faults/new">
        <Button
          icon={HeroIcons.PlusIcon}
          className="w-max"
          variant={Variant.secondaryFilled}
          size={isMobile ? 'small' : 'normal'}
        >
          {t('faults:add_fault')}
        </Button>
      </Link>
    </div>
  );

  const props = {
    actions,
    pageNumber: page,
    limit: pageSize,
    columns,
    faults: allFaults.map((fault) => ({
      ...fault,
      simple: {
        possibleCauses: <Text data-tip={fault.simple?.possibleCauses}>{fault.simple?.possibleCauses}</Text>,
        whatToDo: <Text data-tip={fault.simple?.whatToDo}>{fault.simple?.whatToDo}</Text>,
      },
      advanced: {
        possibleCauses: <Text data-tip={fault.advanced?.possibleCauses}>{fault.advanced?.possibleCauses}</Text>,
        whatToDo: <Text data-tip={fault.advanced?.whatToDo}>{fault.advanced?.whatToDo}</Text>,
      },
      actions: (
        <TrashIcon
          className="h-6 w-6 text-blue-800 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            handleDeleteFault(fault.faultCode);
          }}
        />
      ),
    })),
    total,
    loading,
    productFamily,
    searchFault: textSearch,
    onChangePageNumber: handleChangePageNumber,
    onChangeCountPerPage: setPageSize,
    onChangeColumns: handleChangeColumns,
    onDeleteFault: handleDeleteFault,
    handleSearchFault: setSearchTermDebounced,
  };

  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
}

export default function FaultsContainer({ productFamily }: Props): JSX.Element {
  const faultsContextValue = useFaultsContextValue();

  return (
    <FaultsContext.Provider value={faultsContextValue}>
      <Faults productFamily={productFamily} />
    </FaultsContext.Provider>
  );
}
