import React, { ChangeEvent, RefObject } from 'react';

import { HeroIcons } from '../style/heroicons';

export enum InputType {
  textArea = 'textArea',
  button = 'button',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimeLocal = 'datetime-local',
  email = 'email',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  month = 'month',
  number = 'number',
  password = 'password',
  radio = 'radio',
  range = 'range',
  reset = 'reset',
  search = 'search',
  submit = 'submit',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week',
}

export enum Border {
  none = 'none',
  outline = 'outline',
  bottom = 'bottom',
}

export interface InputProps {
  'data-testid'?: string;
  'data-pwid'?: string;
  ref?: RefObject<HTMLInputElement>;
  label?: string;
  leftIcon?: HeroIcons | JSX.Element;
  rightIcon?: HeroIcons | JSX.Element;
  tooltip?: string;
  placeholder?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  loading?: boolean;
  revealErrorMessage?: boolean;
  value?: string | number;
  className?: string | string[];
  inputClassName?: string | string[];
  type?: InputType;
  name?: string;
  disabled?: boolean;
  hideErrorSection?: boolean;
  border?: Border;
  transparent?: boolean;
  min?: number | string;
  max?: number | string;
  step?: number | 'any';
  clearable?: boolean;
  precision?: number;
  helpText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeValue?: (value: string | number) => void;
  onPressEnter?: (e: React.FormEvent) => void;
  onPressEscape?: (e: React.FormEvent) => void;
  onBlur?: () => void;
  onClickRightIcon?: () => void;
}
