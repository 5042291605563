import { PlusOutlined } from '@ant-design/icons';
import { Modal, Skeleton, Space, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import type { UploadProgressEvent, UploadRequestError, UploadRequestOption } from 'rc-upload/es/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Image, UploadImageResponse } from '../types';
import { MAX_UPLOAD_AMOUNT, MAX_UPLOAD_IMG_WIDTH, S3_URL, SMARTVIEW_API_URL } from '../util/constants';

interface ImageManagerProps {
  imagesList: Image[];
  loading?: boolean;
  disabled?: boolean;
  setImagesList: (list: Image[]) => void;
  handleError?: (filename: string, error: Error) => void;
  handleLoading: () => void;
  systemId: string;
  'data-pwid'?: string;
}

const apiUrl: string = process.env.REACT_APP_SMARTVIEW_API_URL || SMARTVIEW_API_URL;
const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const ImageManager: React.FunctionComponent<ImageManagerProps> = ({
  imagesList,
  systemId,
  loading,
  disabled,
  setImagesList,
  handleLoading,
  handleError,
  'data-pwid': dataPwId = 'image-manager',
}: ImageManagerProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { t } = useTranslation('translations');

  useEffect(() => {
    const initialFileList: UploadFile<string>[] = imagesList.map((image) => {
      return {
        uid: image.fullSize,
        name: image.fullSize,
        status: 'done',
        url: `${S3_URL}/${image.fullSize}`,
        thumbUrl: `${S3_URL}/${image.thumbnail}`,
        crossOrgin: 'use-credentials',
        // Need the props to be lowercase, this is used later
        linkProps: { image, ['data-pwid']: 'image-id' },
      };
    });
    setFileList(initialFileList);
  }, [systemId, imagesList]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url) file.url = await getBase64(file.originFileObj as RcFile);

    setPreviewImage(file.url);
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = useCallback(
    ({ fileList: newFileList, file }) => {
      setFileList(newFileList);

      if (file.status === 'uploading') return handleLoading();

      if (file.status === 'error' && handleError) handleError(file.name, file.error);

      const anyStillLoading = newFileList.some(({ status }: { status: string }) => status === 'uploading');
      // Wait till all images are done uploading so the page does not re-render and the
      // form status stays loading. Error state will not affect setting new list
      if (!anyStillLoading) {
        setImagesList(
          newFileList
            .filter(({ status }: UploadFile<UploadImageResponse>) => {
              return status !== 'removed' && status !== 'error';
            })
            .map((file: UploadFile<UploadImageResponse>) => {
              if (file.response) return { ...file.response.filePaths };

              return file.linkProps.image;
            }),
        );
      }
    },
    [handleError, handleLoading, setImagesList],
  );

  const handleUpload = useCallback(function (options: UploadRequestOption<UploadImageResponse>) {
    const { file, action, method, onProgress, onError, onSuccess } = options;
    const xhr = new XMLHttpRequest();
    xhr.open(method, action);
    xhr.withCredentials = true;
    xhr.setRequestHeader('Content-Type', (file as RcFile).type);
    xhr.onload = function onload() {
      // allow success when 2xx status
      if (xhr.status < 200 || xhr.status >= 300) {
        let err;
        if (xhr.response.responseType === 'json') {
          const uploadErr = JSON.parse(xhr.responseText);
          err = new Error(uploadErr.error) as UploadRequestError;
        } else {
          err = new Error(xhr.responseText) as UploadRequestError;
        }

        err.status = xhr.status;
        err.method = method;
        err.url = action;

        if (onError) return onError(err);
      }

      if (onSuccess) return onSuccess(JSON.parse(xhr.response), xhr);
    };
    if (onProgress) {
      xhr.upload.onprogress = (e: UploadProgressEvent) => {
        if (Number(e.total) > 0) {
          e.percent = (Number(e.loaded) / Number(e.total)) * 100;
        }
        onProgress(e);
      };
    }
    if (onError) {
      xhr.onerror = onError;
    }
    xhr.send(file);
  }, []);

  const handleBeforeUpload = useCallback(async (file: RcFile) => {
    try {
      const sourceBlob = file;
      const image = await createImageBitmap(sourceBlob);

      let scaleW;
      let scaleH;
      if (image.width > image.height) {
        scaleW = image.width > MAX_UPLOAD_IMG_WIDTH ? MAX_UPLOAD_IMG_WIDTH : image.width;
        scaleH = (image.height * scaleW) / image.width;
      } else {
        scaleH = image.height > MAX_UPLOAD_IMG_WIDTH ? MAX_UPLOAD_IMG_WIDTH : image.height;
        scaleW = (image.width * scaleH) / image.height;
      }

      const canvas = new OffscreenCanvas(scaleW, scaleH);
      const context = canvas.getContext('2d');
      if (context) context.drawImage(image, 0, 0, scaleW, scaleH);

      const result = await canvas.convertToBlob({
        type: 'image/jpeg',
        quality: 0.8,
      });

      image.close();
      return result;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
      return file;
    }
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined rev={{}} />
      <div>{t('Upload')}</div>
    </div>
  );

  if (loading) {
    return (
      <Space>
        <Skeleton.Image active={true} />
        <Skeleton.Image active={true} />
        <Skeleton.Image active={true} />
        <Skeleton.Image active={true} />
      </Space>
    );
  }
  return (
    <>
      <Upload
        action={`${apiUrl}/systems/actionlogs/${systemId}/img?thumb=true&pinky=true`}
        withCredentials={true}
        listType="picture-card"
        fileList={fileList}
        accept=".jpg, .jpeg, .png"
        maxCount={MAX_UPLOAD_AMOUNT}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={handleUpload}
        multiple
        beforeUpload={handleBeforeUpload}
        data-pwid={`${dataPwId}-upload`}
      >
        {fileList.length < MAX_UPLOAD_AMOUNT && !disabled && uploadButton}
      </Upload>
      <Modal open={previewOpen} footer={null} onCancel={handleCancel} width="fit-content">
        <img alt={`action-log-${systemId}`} src={previewImage} />
      </Modal>
    </>
  );
};

export default ImageManager;
