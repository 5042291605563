import { AxiosError } from 'axios';
import {
  BarController,
  BarElement,
  Chart,
  Decimation,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
// import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { downloadSystemData, fetchSystemDataKeys } from '../../../adapters/api/systems/data';
import {
  Card,
  IndicatorTier,
  ListItem,
  PageHeader,
  SearchableList,
  Select,
  Signal,
} from '../../../ComponentLibrary/src';
import DataFilter from '../../../components/Data/Filter';
import { usePageContext } from '../../../components/Page';
import TabNavigation from '../../../components/System/TabNavigation';
import StatusIndicator from '../../../components/Systems/StatusIndicator';
import { Interval, SystemsContext } from '../../../context/Systems';
import { useMobile, useSetDocumentTitle } from '../../../hooks';
import { OptionId } from '../../../JsonApi/src/types';
import { parseSignalStrength } from '../util';
import { DataChart } from './DataChart';
import {
  calculateDateRange,
  calculateMinInterval,
  getDefaultIntervalForQuickRange,
  listItemLabelSort,
  useDataSearchParams,
} from './util';

// Initialize Chart Colors
const chartColors: {
  maxColorCharts: number;
  colors: Map<string, string[]>;
} = {
  maxColorCharts: 1,
  colors: new Map(),
};

export default function Data(): JSX.Element {
  useEffect(() => {
    Chart.register(
      LineController,
      LineElement,
      PointElement,
      LinearScale,
      TimeScale,
      Title,
      Decimation,
      Tooltip,
      zoomPlugin,
      Legend,
      BarController,
      BarElement,
      // CrosshairPlugin,
    );
  }, []);

  const { sysId } = useParams();
  const { system, getSystem, summitInfo, getSystemData, systemData } = useContext(SystemsContext);
  useSetDocumentTitle(`${sysId} | Data`);

  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();
  const [dataSources, setDataSources] = useState<ListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [systemLoading, setSystemLoading] = useState(true);
  const [displayedInterval, setDisplayedInterval] = useState<Interval | undefined>();
  const isMobile = useMobile();
  const navigate = useNavigate();
  const onFailHandler = ({ response }: AxiosError) => {
    if (response?.status === 402 || response?.status === 403) {
      navigate(`/systems/${sysId}`);
    }
  };
  const { chartControls, setDataSearch } = useDataSearchParams();

  useEffect(() => {
    setTitle('');
    setBreadcrumbs();
    setScrollable(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sysId) {
      getSystem({
        sysId,
        fetchSummit: true,
        project: ['stats.state', 'site.name', 'subscriptionStatus'],
        onFail: onFailHandler,
      }).then(() => setSystemLoading(false));

      fetchSystemDataKeys(sysId, onFailHandler).then((keys) => {
        setDataSources(keys?.sort(listItemLabelSort) || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId]);

  const updateChartData = useCallback(() => {
    if (sysId) {
      const types = Object.keys(chartControls.chartSources);
      if (types.length) {
        let range = chartControls.dateRange;

        if (chartControls.quickRange) {
          range = calculateDateRange(chartControls.quickRange, chartControls.dateRange);
        }

        setLoading(true);
        getSystemData(
          sysId,
          types,
          range.from as Date,
          range.to as Date,
          chartControls.dataInterval as Interval,
          onFailHandler,
        ).then((response) => {
          setLoading(false);
          const minInterval = calculateMinInterval(
            chartControls.dataInterval,
            (response as { minInterval: Interval })?.minInterval,
          );
          setDisplayedInterval(minInterval);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sysId, chartControls]);

  useEffect(() => {
    updateChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartControls]);

  const handleSelectDataSource = useCallback(
    (value: OptionId, axis: 1 | 2 | undefined) => {
      const newChartSources = { ...chartControls.chartSources };
      if (value) {
        if (axis) {
          newChartSources[value?.toString() ?? ''] = axis;
        } else {
          delete newChartSources[value?.toString() ?? ''];
        }
        setDataSearch({
          chartSources: newChartSources,
          dateRange: chartControls.dateRange,
          interval: chartControls.dataInterval as Interval,
          quickRange: chartControls.quickRange,
        });
      }
    },
    [
      chartControls.chartSources,
      chartControls.dataInterval,
      chartControls.dateRange,
      chartControls.quickRange,
      setDataSearch,
    ],
  );

  const handleMultiSelectDataSource = (
    options: {
      value: string | number | null;
      label: string;
    }[],
    axisToAdd: 1 | 2 | undefined,
  ) => {
    const newChartSources = { ...chartControls.chartSources };
    // clear data from chart
    for (const [datum, axis] of Object.entries(newChartSources)) {
      if (axis === axisToAdd) delete newChartSources[datum];
    }
    // add new data to chart
    for (const option of options) {
      newChartSources[option.value as string] = axisToAdd as 1 | 2;
    }
    setDataSearch({
      chartSources: newChartSources,
      dateRange: chartControls.dateRange,
      interval: chartControls.dataInterval as Interval,
      quickRange: chartControls.quickRange,
    });
  };

  const handleChangeExport = (selectedItem: string) => {
    if (!sysId) return;
    downloadSystemData({
      sysId,
      types: selectedItem !== 'all' ? Object.keys(chartControls.chartSources) : undefined,
      startDate: chartControls.dateRange.from as string,
      endDate: chartControls.dateRange.to as string,
      interval: chartControls.dataInterval as Interval,
    });
  };

  const downloadImage = () => {
    const canvas = document.getElementsByTagName('canvas')?.[0];
    if (canvas) {
      const imageLink = document.createElement('a');
      imageLink.download = `${sysId}-chart-${new Date().getTime()}`;
      imageLink.href = canvas.toDataURL('image/png', 1);
      imageLink.click();
    }
  };

  const signalStrength = useMemo(() => parseSignalStrength(summitInfo), [summitInfo]);

  const searchableList = useMemo(
    function searchableList() {
      return (
        <div className="flex-1 w-full flex flex-row gap-4 min-h-[27rem]">
          <SearchableList
            className="w-96"
            options={dataSources.sort(listItemLabelSort).map((dataSource) => {
              const chart = chartControls.chartSources[dataSource.id as string];
              dataSource.chartSelectAxis = chart;
              return dataSource;
            })}
            onSelect={handleSelectDataSource}
            value={'userLoadP'}
            highlightOnSelected={false}
            chartSelectEnabled
            selectionColors={chartColors.colors}
            data-pwid="system-data-page-searchable-list"
          />
          <Card className="flex-1 overflow-hidden flex flex-col">
            <div id="legend"></div>
            {systemData && (
              <DataChart
                loading={loading}
                isMobile={isMobile}
                chartSources={chartControls.chartSources}
                dateRange={chartControls.dateRange}
                dataSources={dataSources}
                dataInterval={chartControls.dataInterval}
                systemData={systemData}
                chartColors={chartColors}
              />
            )}
          </Card>
        </div>
      );
    },
    [chartControls, dataSources, handleSelectDataSource, isMobile, loading, systemData],
  );
  return (
    <div className={`h-full flex flex-col ${isMobile ? 'overflow-y-auto min-h-max' : ''}`}>
      <PageHeader
        className={isMobile ? 'p-2' : 'p-4'}
        title={sysId}
        subtitle={systemLoading ? '' : system?.site?.name}
        breadcrumbs={[{ text: 'Systems', href: '/systems' }, { text: sysId ?? '' }]}
        leftComponent={
          <>
            <StatusIndicator system={system} tier={IndicatorTier.two} isLoading={systemLoading} />
          </>
        }
        rightComponent={
          <>
            <Signal signalStrength={signalStrength} isLoading={systemLoading} className="h-6 w-6" />
          </>
        }
        bottomBorder
      >
        <TabNavigation
          sysId={sysId}
          pageSelected="data"
          subscriptionStatus={systemLoading ? '' : system?.subscriptionStatus || ''}
        />
      </PageHeader>
      <div
        className={`flex-1 ${isMobile ? 'pt-2 px-2 mb-16' : 'p-4 overflow-y-auto'} flex flex-col gap-4`}
        data-pwid="system-data-page-content"
      >
        <DataFilter
          hasSelectedDataSources={Object.keys(chartControls.chartSources).length > 0}
          dateRange={chartControls.dateRange}
          setDateRange={(payload) => {
            setDataSearch({
              chartSources: chartControls.chartSources,
              dateRange: payload,
              interval: chartControls.dataInterval as Interval,
              quickRange: undefined,
            });
          }}
          onChangeExport={handleChangeExport}
          onRefresh={updateChartData}
          loading={loading}
          dataInterval={displayedInterval}
          minInterval={systemData?.minInterval}
          setDataInterval={(interval) => {
            setDataSearch({
              chartSources: chartControls.chartSources,
              dateRange: chartControls.dateRange,
              interval: interval as Interval,
              quickRange: chartControls.quickRange,
            });
          }}
          onDownloadImage={downloadImage}
          quickRange={chartControls.quickRange}
          setQuickRange={(quickRange) => {
            setDataSearch({
              chartSources: chartControls.chartSources,
              dateRange: calculateDateRange(quickRange, chartControls.dateRange),
              interval: getDefaultIntervalForQuickRange(quickRange),
              quickRange,
            });
          }}
        />
        {isMobile && (
          <>
            <Select
              options={dataSources.map((option) => ({
                value: option.id?.toString() ?? '',
                label: option.label?.toString() ?? '',
              }))}
              onChangeValue={(values) =>
                handleMultiSelectDataSource(
                  values as {
                    value: string | number | null;
                    label: string;
                  }[],
                  1,
                )
              }
              value={Object.entries(chartControls.chartSources)
                .filter(([, chart]) => chart === 1)
                .map(([src]) => src)}
              placeholder="Add data to left axis ..."
              menuPlacement="bottom"
              clearable
              isMulti
              hideErrorSection
              data-pwid="system-data-page-left-axis-select"
            />
            <Select
              options={dataSources.map((option) => ({
                value: option.id?.toString() ?? '',
                label: option.label?.toString() ?? '',
              }))}
              onChangeValue={(values) =>
                handleMultiSelectDataSource(
                  values as {
                    value: string | number | null;
                    label: string;
                  }[],
                  2,
                )
              }
              value={Object.entries(chartControls.chartSources)
                .filter(([, chart]) => chart === 2)
                .map(([src]) => src)}
              placeholder="Add data to right axis..."
              menuPlacement="bottom"
              hideErrorSection
              clearable
              isMulti
              data-pwid="system-data-page-right-axis-select"
            />
          </>
        )}
        {isMobile ? (
          <Card className="flex-1 flex flex-col">
            <div id="legend"></div>
            {systemData && (
              <DataChart
                loading={loading}
                isMobile={isMobile}
                chartSources={chartControls.chartSources}
                dateRange={chartControls.dateRange}
                dataSources={dataSources}
                dataInterval={chartControls.dataInterval}
                systemData={systemData}
                chartColors={chartColors}
              />
            )}
          </Card>
        ) : (
          searchableList
        )}
      </div>
    </div>
  );
}
