import { Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchSearchDistributors } from '../../../adapters/api/distributors';
import {
  AsyncSelect,
  Button,
  FilterKey,
  HeroIcons,
  Loading,
  Pill,
  PillVariant,
  ReactSelectOption,
} from '../../../ComponentLibrary/src';
import { generateDistributorColor } from '../../../pages/Assets/util';
import { Distributor } from '../../../types';

interface DistributorsProps {
  showAdd: boolean;
  canEdit: boolean;
  parentName?: string;
  selectedDistributors?: {
    _id: string;
    name: string;
    label?: string;
  }[];
  parentDistributors?: Distributor[];
  onAdd?: (distributorId: string | null) => Promise<void>;
  onRemove?: (distributor: Distributor) => Promise<void>;
  'data-pwid'?: string;
}

export default function Distributors({
  selectedDistributors,
  onAdd,
  onRemove,
  showAdd,
  canEdit,
  parentName,
  parentDistributors,
  'data-pwid': dataPwId = 'distributors',
}: DistributorsProps): JSX.Element {
  const { t } = useTranslation(['assets', 'translate']);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [newDistributor, setNewDistributor] = useState<ReactSelectOption>();

  const isDistributorSyncedWithParent = useCallback(
    (dist: Distributor) => {
      if (!parentDistributors) return true;
      if (parentDistributors.length === 0) return false;

      return parentDistributors.some((parentDist) => parentDist._id === dist._id);
    },
    [parentDistributors],
  );

  const handleRemove = useCallback(
    async (distributor: Distributor) => {
      if (canEdit && onRemove) {
        setIsSaving(true);
        await onRemove(distributor);
        setIsSaving(false);
      }
    },
    [canEdit, onRemove],
  );

  const distributors = useMemo(() => {
    return selectedDistributors?.map((dist: Distributor) => {
      const isSyncedWithParent = isDistributorSyncedWithParent(dist);
      return (
        <div key={dist?._id} data-tip={isSyncedWithParent ? '' : t('unsynced_distributor')}>
          <Pill
            color={generateDistributorColor(dist?._id, isSyncedWithParent)}
            className={`shadow${isSyncedWithParent ? '' : ' line-through text-gray-50'}`}
            rightIcon={canEdit ? HeroIcons.XCircleIcon : undefined}
            propagateRightIconClickEvent
            onClick={(e) => e.preventDefault()}
            onClickRightIcon={() => handleRemove(dist)}
            data-pwid={`${dataPwId}-${dist?.name}`}
          >
            {dist?.name}
          </Pill>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistributors, canEdit]);

  const getDistributors = useCallback(
    async (searchTerm) => {
      if (parentDistributors) {
        return parentDistributors.map((dist: Distributor) => {
          return {
            value: dist._id,
            label: dist.name,
          };
        });
      }

      const response = await fetchSearchDistributors({
        searchTerm,
        filterKeys: [
          {
            id: '_id',
            label: 'id',
            selected: true,
            exclusionMode: true,
            selectedValues: selectedDistributors?.map((d) => ({
              id: d._id,
              label: d.label ?? d.name,
            })),
          } as FilterKey,
        ],
        count: true,
        countPerPage: 10,
      });

      return response;
    },
    [selectedDistributors, parentDistributors],
  );

  const handleAdd = useCallback(async () => {
    if (newDistributor && onAdd) {
      setIsSaving(true);
      setShowDropdown(false);
      await onAdd(newDistributor.value as string);
      setNewDistributor({ label: '', value: '' });
      setIsSaving(false);
    }
  }, [newDistributor, onAdd]);

  const handleFilter = useCallback(
    (dist: ReactSelectOption) => {
      const found = selectedDistributors?.find(({ _id }: Distributor) => _id === dist.value);
      return found === undefined;
    },
    [selectedDistributors],
  );

  return (
    <div
      className="flex flex-row gap-2 items-center flex-wrap"
      onClick={(e) => e.stopPropagation()}
      data-pwid={dataPwId}
    >
      {distributors}
      {isSaving && <Loading type="small" />}
      {showAdd && canEdit && (
        <Pill
          variant={PillVariant.secondary}
          rightIcon={HeroIcons.PlusIcon}
          tooltip={t('assign_distributor')}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowDropdown(true);
          }}
          data-pwid={`add-distributor-pill-${parentName}`}
        />
      )}
      {showAdd && showDropdown && (
        <Modal
          title={t('select_distributor', { for: parentName })}
          centered
          open
          onCancel={() => setShowDropdown(false)}
          footer={[
            <Button
              key="ok"
              containerClassName="flex justify-end"
              disabled={!newDistributor}
              onClick={handleAdd}
              data-pwid="save-button"
            >
              {t('Save')}
            </Button>,
          ]}
          data-pwid="add-distributor-modal"
        >
          <AsyncSelect
            onLoadOptions={getDistributors}
            onChange={(options) => setNewDistributor(options ?? { label: '', value: '' })}
            filterOption={handleFilter}
            value={newDistributor}
            searchable={!parentDistributors?.length}
            menuPlacement="bottom"
            hideErrorSection
            data-pwid="add-distributor-async-select"
          />
        </Modal>
      )}
    </div>
  );
}
