import '../style/react-date-range/styles.css'; // main css file
import '../style/react-date-range/theme/default.css'; // theme css file

import { XIcon } from '@heroicons/react/outline';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import Skeleton from 'react-loading-skeleton';

import { Input } from '../Input';
import { HeroIcons } from '../style/heroicons';
import { Side } from '../types';
import { appendClassProps } from '../util';
import { useOutsideAlerter } from '../util/hooks';
import { DatePickerProps } from './index.types';

/**
 - Use DatePicker to select a date
 */
export const DatePicker: React.FC<DatePickerProps> = ({
  clearable = false,
  loading = false,
  side,
  date,
  label,
  tooltip,
  disabled,
  className,
  onChange,
  minDate,
  maxDate,
  errorMessage,
  revealErrorMessage,
  hideErrorSection,
  helpText,
  'data-pwid': dataPwid = 'date-picker',
  'data-testid': dataTestId = 'datePicker',
}: DatePickerProps) => {
  const wrapperRef = useRef(null);
  const [inputDate, setInputDate] = useState(date ? new Date(date)?.toLocaleDateString() : undefined);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setInputDate(date ? new Date(date)?.toLocaleDateString() : undefined);
  }, [date]);

  useEffect(() => {
    if (showMenu && !date) onChange(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  const handleClickWrapper = () => {
    setShowMenu(false);
  };

  useOutsideAlerter(wrapperRef, handleClickWrapper);

  const handleClickDateIcon = () => {
    if (disabled) return;
    setShowMenu(true);
  };

  const handleChangeInput = (date: string | number) => {
    setInputDate(date as string);
  };

  const handleBlurInput = () => {
    if (inputDate && DateTime.fromJSDate(new Date(inputDate)).isValid) {
      const newDate = new Date(inputDate);
      onChange(newDate);
      setInputDate(newDate.toLocaleDateString());
    } else {
      setInputDate('');
    }
  };

  const handleChange = (date: Date) => {
    setInputDate((date as Date).toLocaleDateString());
    onChange(date as Date);
  };

  const handleClear = () => {
    setInputDate('');
    onChange(undefined);
  };

  return (
    <div className={`relative${appendClassProps(className)}`} data-testid={dataTestId} data-pwid={dataPwid}>
      <div className="w-full flex flex-row items-center gap-2">
        {loading ? (
          <Skeleton containerClassName="w-full" height={'34px'} />
        ) : (
          <>
            <Input
              label={label}
              tooltip={tooltip}
              className="w-full"
              rightIcon={disabled ? undefined : HeroIcons.CalendarIcon}
              onClickRightIcon={handleClickDateIcon}
              onChangeValue={handleChangeInput}
              onBlur={handleBlurInput}
              value={inputDate || ''}
              hideErrorSection={hideErrorSection}
              revealErrorMessage={revealErrorMessage}
              errorMessage={errorMessage}
              helpText={helpText}
              disabled={disabled}
              data-pwid={`${dataPwid}-input`}
              data-testid={`${dataTestId}-input`}
            />
            {clearable && (
              <XIcon
                className={`text-red-600 w-6 h-6 cursor-pointer${label ? ' relative -bottom-3' : ''}`}
                onClick={handleClear}
                data-pwid={`${dataPwid}-clear`}
                data-testid={`${dataTestId}-clear`}
              />
            )}
          </>
        )}
      </div>

      <div
        className={`shadow-md z-10 text-blue-800 flex flex-col gap-2 absolute top-8 ${
          side === Side.right ? 'right-0' : 'left-0'
        } rounded-tl-sm rounded-b-sm ${showMenu ? '' : ' hidden'}`}
        ref={wrapperRef}
      >
        {date && (
          <Calendar
            onChange={handleChange}
            date={typeof date === 'string' ? new Date(date) : date}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </div>
    </div>
  );
};

export * from './index.types';
