import { XIcon } from '@heroicons/react/solid';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, HeroIcons, Text, TextType, Variant } from '../../../ComponentLibrary/src';
import { Type } from '../../../ComponentLibrary/src/Button';
import { NotificationMode } from '../../../types';
import { MenuItem } from '../types';
import EmptyList from './EmptyList';

export interface SelectedListProps {
  listItems: MenuItem[];
  mode: NotificationMode;
  handleShowModal: (selectedItem: MenuItem) => void;
  handleRemoveItem: (itemId: string) => void;
  handleCheckbox: (item: MenuItem, parent?: MenuItem) => void;
}

const SelectedList: React.FunctionComponent<SelectedListProps> = ({
  listItems,
  mode,
  handleShowModal,
  handleRemoveItem,
  handleCheckbox,
}) => {
  const { t } = useTranslation('translation');
  const unallocated = useMemo(() => {
    return listItems.filter(({ isSystem }) => isSystem);
  }, [listItems]);

  const allocated = useMemo(() => {
    return listItems.filter(({ isSystem }) => !isSystem);
  }, [listItems]);
  /**
   * Build selected list of orgs, sites, and systems recursively, and display a message when list is empty
   */
  const buildSelectedList = useCallback(
    (items: MenuItem[], addedPadding: number, parent?: MenuItem) => {
      return items.map((item) => {
        return (
          <div key={item.id} className={`selected-asset-notification ml-${2 + addedPadding}`}>
            <div
              className={`flex flex-row justify-between ${mode === NotificationMode.Whitelist ? 'allow' : 'ignore'}`}
            >
              <div className="flex flex-row items-center gap-2">
                {item.isSystem && item.hasKnownModel ? (
                  <img src={item.thumbnail} className="w-8 h-8" />
                ) : (
                  <item.Icon className="w-5 h-5" />
                )}
                <Text type={TextType.h6} data-pwid={`label-${item.label}`}>
                  {item.label}
                </Text>
                {!item.isSystem && !item.selected && (
                  <Button
                    size="small"
                    type={Type.button}
                    icon={HeroIcons.PlusIcon}
                    variant={Variant.secondaryFilled}
                    onClick={() => handleShowModal(item)}
                  />
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {!item.isSystem && (
                  <Checkbox
                    label={t('All')}
                    id={item.id}
                    checked={item.selected}
                    onChangeValue={() => handleCheckbox(item, parent)}
                    hideErrorSection
                  />
                )}
                <XIcon className="w-5 h-5" onClick={() => handleRemoveItem(item.id)} />
              </div>
            </div>
            {!item.selected && item.subItems && buildSelectedList(item.subItems, 4, parent ? parent : item)}
          </div>
        );
      });
    },
    [mode, handleShowModal, handleRemoveItem, handleCheckbox, t],
  );
  return (
    <div className="ml-4">
      {listItems.length ? (
        <>
          {buildSelectedList(unallocated, 0)}
          {buildSelectedList(allocated, 0)}
        </>
      ) : (
        <EmptyList mode={mode} />
      )}
    </div>
  );
};

export default SelectedList;
