import { CloudUploadIcon, LightningBoltIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonTabs, HeroIcons, Input, InputType, List, Pagination } from '../../ComponentLibrary/src';
import { FaultSeverity } from '../../types';
import { FaultsProps } from './types';

export default function MobileFaults({
  actions,
  pageNumber,
  limit,
  faults,
  total,
  loading,
  productFamily,
  searchFault = '',
  handleSearchFault,
  onChangePageNumber,
  onDeleteFault,
}: FaultsProps): JSX.Element {
  const [search, setSearch] = useState<string>(searchFault);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('faults');

  // show loading and scroll to top of table when filter/pagination changes
  useEffect(() => {
    tableContainerRef.current?.scrollTo({
      top: 0,
      left: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, limit, searchFault]);

  const handleSearch = useCallback(
    (search: string | number) => {
      setSearch(search as string);
      handleSearchFault(search as string);
    },
    [setSearch, handleSearchFault],
  );

  return (
    <div ref={tableContainerRef} className="flex-1 flex flex-col overflow-y-auto mb-12">
      <div className="flex">
        <Input
          onChangeValue={handleSearch}
          value={search}
          leftIcon={HeroIcons.SearchIcon}
          hideErrorSection
          clearable={true}
          data-pwid="faults-search-input"
          placeholder={t('search_placeholder')}
          type={InputType.text}
        />
        {actions}
      </div>
      <List
        className="p-2 mb-16"
        items={faults.map((fault) => {
          let thumbnail;
          switch (fault.severity) {
            case FaultSeverity.info:
              thumbnail = HeroIcons.InformationCircleIcon;
              break;
            default:
              thumbnail = HeroIcons.ExclamationCircleIcon;
              break;
          }
          return {
            title: fault.faultCode.toString(),
            thumbnail,
            description: fault.description,
            indicator2: (
              <TrashIcon
                className="w-6 h-6"
                onClick={() => {
                  if (onDeleteFault) onDeleteFault(fault.faultCode as number);
                }}
              />
            ),
            // properties
            className: 'bg-white',
            linkUrl: `/faults/${fault.productFamily}/${fault.faultCode}`,
          };
        })}
        loadingCount={loading ? 10 : undefined}
      />
      <div className="absolute bottom-0 left-0 right-0 z-900">
        <Pagination
          pageNumber={pageNumber}
          countPerPage={limit}
          totalCount={total || 0}
          onChangePageNumber={onChangePageNumber}
          mobile
          className="mb-16"
        />
        <ButtonTabs
          className="h-16"
          options={[
            {
              title: t('powerGen'),
              selected: productFamily === 'PowerGen',
              mobileIcon: <LightningBoltIcon className="h-6 w-6" />,
              href: '/faults/PowerGen',
            },
            {
              title: t('cap'),
              selected: productFamily === 'CAP',
              mobileIcon: <CloudUploadIcon className="h-6 w-6" />,
              href: '/faults/CAP',
            },
          ]}
        />
      </div>
    </div>
  );
}
