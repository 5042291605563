import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { postFault, putFault } from '../../adapters/api/faults';
import {
  Card,
  Form,
  FormSave,
  FormStatus,
  Input,
  InputType,
  Select,
  SelectValue,
  TextArea,
} from '../../ComponentLibrary/src';
import { usePageContext } from '../../components/Page';
import { AuthContext } from '../../context/Auth';
import { FaultsContext, useFaultsContextValue } from '../../context/Faults';
import { Fault, FaultSeverity, ProductFamily } from '../../types';
import { PERMISSIONS } from '../../util/constants';

function EditFault(): JSX.Element {
  const navigate = useNavigate();
  const { hasPermissions } = useContext(AuthContext);
  const { faultCode, productFamily: productFamilyParam } = useParams();
  const { getFault, fault } = useContext(FaultsContext);
  const [newFaultCode, setNewFaultCode] = useState(
    !isNaN(parseInt(faultCode as string)) ? parseInt(faultCode as string) : 0,
  );
  const [description, setDescription] = useState('');
  const [severity, setSeverity] = useState<FaultSeverity>();
  const [productFamily, setProductFamily] = useState<ProductFamily>(productFamilyParam as ProductFamily);
  const [simpleCauses, setSimpleCauses] = useState('');
  const [simpleSolutions, setSimpleSolutions] = useState('');
  const [advancedCauses, setAdvancedCauses] = useState('');
  const [advancedSolutions, setAdvancedSolutions] = useState('');
  const [formStatus, setFormStatus] = useState<FormStatus | undefined>();

  const { setTitle, setBreadcrumbs, setScrollable } = usePageContext();

  const createMode = faultCode === undefined;

  const enforcePermissions = useCallback(() => {
    const kickUserOut = () => {
      toast('You do not have access to this page.', {
        type: toast.TYPE.ERROR,
      });
      navigate('/');
    };
    if (!hasPermissions(PERMISSIONS.dashboard.faults.update)) {
      kickUserOut();
    }
  }, [hasPermissions, navigate]);

  useEffect(() => {
    setTitle('');
    setScrollable(true);
    setBreadcrumbs([
      { text: 'Faults', href: `/faults` },
      { text: `${createMode ? 'New ' : ''}Fault ${createMode ? '' : faultCode}${createMode ? '' : ' [Edit]'}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode, faultCode]);

  useEffect(() => {
    if (!createMode)
      getFault(parseInt((faultCode ?? '').toString()), productFamilyParam as ProductFamily).finally(() => {
        enforcePermissions();
      });
  }, [createMode, enforcePermissions, faultCode, getFault, productFamilyParam]);

  const resetFaultState = useCallback(() => {
    setNewFaultCode(fault?.faultCode || 0);
    setDescription(fault?.description || '');
    setSeverity(fault?.severity);
    setSimpleCauses(fault?.simple?.possibleCauses || '');
    setSimpleSolutions(fault?.simple?.whatToDo || '');
    setAdvancedCauses(fault?.advanced?.possibleCauses || '');
    setAdvancedSolutions(fault?.advanced?.whatToDo || '');
  }, [
    fault?.advanced?.possibleCauses,
    fault?.advanced?.whatToDo,
    fault?.description,
    fault?.faultCode,
    fault?.severity,
    fault?.simple?.possibleCauses,
    fault?.simple?.whatToDo,
  ]);

  useEffect(() => {
    resetFaultState();
  }, [fault, resetFaultState]);

  const handleSaveFault = () => {
    setFormStatus('loading');
    const newFault: Fault = {
      faultCode: newFaultCode,
      description,
      severity,
      simple: {
        possibleCauses: simpleCauses,
        whatToDo: simpleSolutions,
      },
      advanced: {
        possibleCauses: advancedCauses,
        whatToDo: advancedSolutions,
      },
      productFamily,
    };
    if (createMode) {
      postFault(newFault)
        .then(() => navigate(-1))
        .catch((err) => {
          toast(err?.response?.data?.error ?? err.message, { type: 'error' });
        });
    } else {
      putFault(parseInt(faultCode ?? ''), productFamily, newFault)
        .then(() => {
          setFormStatus('success');
          getFault(parseInt((faultCode ?? '').toString()), productFamilyParam as ProductFamily);
        })
        .catch((err) => {
          toast(err?.response?.data?.error ?? err.message, { type: 'error' });
        });
    }
  };

  const handleCancelSaveFault = () => {
    setFormStatus(undefined);
    resetFaultState();
    navigate(-1);
  };

  const handleChangeFaultCode = (value: string | number) => {
    setFormStatus('dirty');
    setNewFaultCode(parseInt(value.toString()));
  };

  const handleChangeDescription = (value: string | number) => {
    setFormStatus('dirty');
    setDescription(value.toString());
  };

  const handleChangeSimpleCauses = (value: string | number) => {
    setFormStatus('dirty');
    setSimpleCauses(value.toString());
  };

  const handleChangeSimpleSolutions = (value: string | number) => {
    setFormStatus('dirty');
    setSimpleSolutions(value.toString());
  };

  const handleChangeAdvancedCauses = (value: string | number) => {
    setFormStatus('dirty');
    setAdvancedCauses(value.toString());
  };

  const handleChangeAdvancedSolutions = (value: string | number) => {
    setFormStatus('dirty');
    setAdvancedSolutions(value.toString());
  };

  const handleChangeSeverity = (value: SelectValue) => {
    setFormStatus('dirty');
    setSeverity(value?.toString() as FaultSeverity);
  };

  const handleChangeProductFamily = (value: SelectValue) => {
    setFormStatus('dirty');
    setProductFamily((value?.toString() ?? 'PowerGen') as ProductFamily);
  };

  return (
    <div className="flex flex-col flex-1 gap-6 md:w-3/4 m-4">
      <Card className="flex flex-row gap-2 flex-wrap md:flex-nowrap">
        <Form preventDefault onSubmit={handleSaveFault} className="w-full">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row gap-2 items-center flex-wrap">
              <Input
                className="flex-auto"
                tooltip="Unique identifier determined by the firmware"
                onChangeValue={handleChangeFaultCode}
                label="Fault Code"
                type={InputType.number}
                value={newFaultCode.toString()}
                disabled={!createMode}
                hideErrorSection
              />
              <Select
                className="flex-auto"
                options={[
                  {
                    value: 'PowerGen',
                    label: 'PowerGen',
                  },
                  {
                    value: 'CAP',
                    label: 'CAP',
                  },
                ]}
                onChangeValue={handleChangeProductFamily}
                tooltip="To which family of products this fault belongs"
                label="Product Family"
                menuPlacement="bottom"
                value={productFamily}
                disabled={!createMode}
                hideErrorSection
                clearable={false}
              />
              <Select
                className="flex-auto"
                options={Object.entries(FaultSeverity).map(([, severity]) => ({
                  value: severity,
                  label: severity,
                }))}
                onChangeValue={handleChangeSeverity}
                tooltip="Severity of the fault (determined by the fault code value)"
                label="Severity"
                menuPlacement="bottom"
                value={severity}
                disabled={productFamily === 'PowerGen'}
                hideErrorSection
                clearable={false}
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                className="flex-1"
                tooltip="Description of the fault"
                label="Fault Description"
                value={description}
                onChangeValue={handleChangeDescription}
                hideErrorSection
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                className="flex-1"
                tooltip="Simple possible causes"
                label="Simple Causes"
                value={simpleCauses}
                onChangeValue={handleChangeSimpleCauses}
                hideErrorSection
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                className="flex-1"
                tooltip="Simple possible solutions"
                label="Simple Solutions"
                value={simpleSolutions}
                onChangeValue={handleChangeSimpleSolutions}
                hideErrorSection
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                className="flex-1"
                tooltip="Advanced possible causes"
                label="Advanced Causes"
                value={advancedCauses}
                onChangeValue={handleChangeAdvancedCauses}
                hideErrorSection
              />
            </div>
            <div className="flex flex-row gap-2 items-center flex-wrap w-full">
              <TextArea
                className="flex-1"
                tooltip="Advanced possible solutions"
                label="Advanced Solutions"
                value={advancedSolutions}
                onChangeValue={handleChangeAdvancedSolutions}
                hideErrorSection
              />
            </div>
          </div>
          <FormSave
            className="self-end"
            onCancel={handleCancelSaveFault}
            mode={createMode ? 'create' : 'edit'}
            status={formStatus}
          />
        </Form>
      </Card>
    </div>
  );
}

export default function EditFaultContainer(): JSX.Element {
  const faultsContextValue = useFaultsContextValue();

  return (
    <FaultsContext.Provider value={faultsContextValue}>
      <EditFault />
    </FaultsContext.Provider>
  );
}
