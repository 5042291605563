import { CubeIcon, LocationMarkerIcon, OfficeBuildingIcon } from '@heroicons/react/solid';

import cap3m from '../../image/CAP3M-thumbnail-cc.png';
import cap3Nano from '../../image/CAP3Nano-thumbnail-cc.png';
import cap3Nano2 from '../../image/CAP3Nano2-thumbnail-cc.png';
import cap3V from '../../image/CAP3V-thumbnail-cc.png';
import pg from '../../image/PG5650-thumbnail-cc.png';
import { MenuItem } from './types';

const knownModels = ['PowerGen 1200', 'PowerGen 5650', 'PowerGen', 'CAP M', 'CAP Nano2', 'CAP V', 'CAP Nano', 'CAP V5'];

export default class NotificationMenuItem implements MenuItem {
  id: string;
  label: string;
  type: string;
  model?: string;
  searchLabel?: string;
  selected: boolean;
  subItems: NotificationMenuItem[] = [];

  constructor({
    id,
    label,
    type,
    selected,
    subItems,
    model,
    searchLabel,
  }: {
    id: string;
    label: string;
    type: string;
    selected: boolean;
    subItems?: NotificationMenuItem[];
    model?: string;
    searchLabel?: string;
  }) {
    this.id = id;
    this.label = label;
    this.type = type;
    this.selected = selected;
    this.model = model;
    this.searchLabel = searchLabel;
    this.subItems = subItems ?? [];
  }

  get Icon(): React.FC<React.ComponentProps<'svg'>> {
    if (this.type === 'org') {
      return OfficeBuildingIcon;
    } else if (this.type === 'site') {
      return LocationMarkerIcon;
    } else {
      return CubeIcon;
    }
  }

  get parsedSearchLabel(): string {
    if (this.searchLabel && this.searchLabel.indexOf('→') > 0) {
      const [org, site, system] = this.searchLabel.split(' → ');
      if (this.isSystem) {
        // Check if it's org system
        if (system) {
          return system;
        } else {
          return site;
        }
      } else if (this.isSite) {
        return site;
      } else {
        return org;
      }
    }
    return this.label;
  }

  get isSystem(): boolean {
    return this.type === 'system';
  }

  get isSite(): boolean {
    return this.type === 'site';
  }

  get thumbnail(): string {
    let thumbnail = 'None';

    switch (this.model) {
      case 'PowerGen 1200':
      case 'PowerGen 5650':
      case 'PowerGen':
        thumbnail = pg;
        break;
      case 'CAP M':
        thumbnail = cap3m;
        break;
      case 'CAP Nano2':
        thumbnail = cap3Nano2;
        break;
      case 'CAP V5':
      case 'CAP V':
        thumbnail = cap3V;
        break;
      case 'CAP Nano':
        thumbnail = cap3Nano;
        break;
      default:
        break;
    }
    return thumbnail;
  }

  get hasKnownModel(): boolean {
    return knownModels.includes(this.model ?? '');
  }

  public isIndeterminate(): boolean {
    return (
      !this.selected && this.subItems.some((item: NotificationMenuItem) => item.selected || item.isIndeterminate())
    );
  }

  public toggleSelected(): NotificationMenuItem {
    return this.clone({ selected: !this.selected });
  }

  public filterSelectedSubItems(): NotificationMenuItem {
    const subItems = this.subItems
      .filter((item): boolean => item.selected || item.isIndeterminate())
      .map((item): MenuItem => item.filterSelectedSubItems());
    return this.clone({ subItems });
  }

  private clone(overwrites: Partial<MenuItem>): NotificationMenuItem {
    const clone = { ...this, ...overwrites };
    return new NotificationMenuItem(clone);
  }
}
