import 'chartjs-adapter-luxon';
import 'react-loading-skeleton/dist/skeleton.css';

import { ExternalLinkIcon } from '@heroicons/react/outline';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Decimation,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card } from '../Card';
import { KeyValue } from '../KeyValue';
import { Label } from '../Label';
import colors from '../style/colors';
import { Text, TextType } from '../Text';
import { appendClassProps } from '../util';
import { BarChart } from './BarChart';
import { MetricProps } from './index.types';
import { LineChart } from './LineChart';
import { displayNumber } from './util';

/**
 - Use card as a generic UI wrapper for visual organization
 */
export const Metric: React.FC<MetricProps> = ({
  windowMode = false,
  transparent = false,
  showPlaceholder = false,
  numberFormatOptions,
  lineColor = colors.emerald['500'],
  value,
  prefix,
  units,
  description,
  tooltip,
  chartData,
  externalLink,
  loading,
  className,
  dataIcon,
  dataIconSide,
  topBorderColor,
  content = null,
  'data-pwid': dataPwId = 'metric',
  isCumulative,
}: MetricProps) => {
  useEffect(() => {
    Chart.register(
      LineController,
      LineElement,
      PointElement,
      LinearScale,
      TimeScale,
      Title,
      Decimation,
      Tooltip,
      CategoryScale,
      LinearScale,
      PointElement,
      Title,
      Tooltip,
      Filler,
      Legend,
      BarController,
      BarElement,
    );
  }, []);

  const handleClickLink = () => {
    window.open(externalLink, '_blank')?.focus();
  };

  const displayChartData = useMemo(() => {
    if (!chartData) return false;

    const { x, y } = chartData;
    return x !== undefined && y !== undefined && x.length > 0 && y.length > 0;
  }, [chartData]);

  lineColor = !displayChartData && showPlaceholder ? colors.gray['300'] : lineColor;

  const display = displayNumber({
    num: value,
    numberFormatOptions,
    units,
  });

  if (display.value === 'NaN' || value === undefined) display.value = value?.toString() || '?';
  let topBorderClass = '';
  if (topBorderColor) topBorderClass = ' border-t-[1rem]';
  if (topBorderColor === 'green') {
    topBorderClass += ' border-emerald-500';
  } else if (topBorderColor === 'yellow') {
    topBorderClass += ' border-amber-700';
  } else if (topBorderColor === 'gray') {
    topBorderClass += ' border-gray-500';
  }
  const chart = useMemo(() => {
    if (isCumulative) {
      return <BarChart chartData={chartData} lineColor={lineColor} showPlaceholder={showPlaceholder} />;
    }
    return <LineChart chartData={chartData} lineColor={lineColor} showPlaceholder={showPlaceholder} />;
  }, [chartData, isCumulative, lineColor, showPlaceholder]);

  return (
    <Card
      data-testid="metric"
      className={`gap-2 flex ${
        dataIconSide === 'left' ? '' : 'justify-center'
      } items-center min-h-[8rem]${topBorderClass}${appendClassProps(className)}`}
      transparent={transparent}
      windowMode={windowMode}
      data-pwid={dataPwId}
    >
      {dataIconSide === 'left' && <div className="w-20 h-20 text-blue-800 p-4">{dataIcon}</div>}
      <div className="flex-1 h-full flex flex-col gap-2 items-center">
        <ExternalLinkIcon
          className={`self-end -my-2 cursor-pointer h-6 w-6 text-blue-800 ${!externalLink ? 'invisible' : ''}`}
          onClick={handleClickLink}
        />
        <div className="flex flex-row gap-2 items-center">
          {prefix && (
            <Text type={TextType.custom} overrideColor className={`text-xl text-blue-800`}>
              {loading ? <Skeleton className="w-4" /> : prefix}
            </Text>
          )}
          <Text
            type={TextType.custom}
            overrideColor
            className={`text-3xl md:text-5xl font-semibold text-blue-800`}
            data-pwid="value"
          >
            {loading ? (
              <div className="w-24">
                <Skeleton />
              </div>
            ) : (
              display.value
            )}
          </Text>
          {(display.suffix || units) && (
            <Text type={TextType.custom} overrideColor className={`text-2xl font-light text-blue-800`}>
              {display.suffix}
              {units}
            </Text>
          )}
        </div>
        {tooltip ? (
          <KeyValue label={description} tooltip={tooltip} windowMode={windowMode} />
        ) : (
          <Label data-pwid={description}>{description}</Label>
        )}
        {displayChartData && (
          <div className="min-h-0 flex-1 relative w-full chart-canvas">
            {showPlaceholder && !displayChartData && (
              <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                <Text type={TextType.custom} overrideColor className={`text-2xl font-light text-gray-400`}>
                  No Data
                </Text>
              </div>
            )}
            {loading ? <Skeleton className="w-full h-full" /> : chart}
          </div>
        )}
        {!dataIconSide && dataIcon && <div className="flex-1 text-blue-800 p-4">{dataIcon}</div>}
        {content}
      </div>
    </Card>
  );
};

export * from './index.types';
