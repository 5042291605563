import { CubeIcon } from '@heroicons/react/outline';
import { ExclamationIcon, PencilIcon, XIcon } from '@heroicons/react/solid';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { patchSystem } from '../../../adapters/api/systems';
import { Checkbox, Link, Loading, Text } from '../../../ComponentLibrary/src';
import { AuthContext } from '../../../context/Auth';
import { Distributor, System } from '../../../types';
import { PERMISSIONS } from '../../../util/constants';
import Distributors from './Distributors';

interface SystemProps {
  system: System;
  showRemove: boolean;
  disabled?: boolean;
  bulkMove?: boolean;
  isSaving?: boolean;
  error?: string;
  parentDistributors?: Distributor[];
  onChecked?: (checked: boolean) => void;
  handleUndoSystem?: (system: System) => void;
  refreshData: () => void;
  setIsSaving?: (isSaving: boolean) => void;
  checked?: boolean;
  'data-pwid'?: string;
}

export default function SystemListItem({
  system,
  showRemove,
  disabled,
  bulkMove = false,
  isSaving,
  error,
  onChecked,
  handleUndoSystem,
  parentDistributors,
  refreshData,
  setIsSaving,
  checked,
  'data-pwid': dataPwid,
}: SystemProps): JSX.Element {
  const { hasPermissions } = useContext(AuthContext);
  const { t } = useTranslation('assets');

  const handleOnChangeValue = useCallback(
    (checked: boolean) => {
      if (onChecked) onChecked(checked);
    },
    [onChecked],
  );

  const handleRemoveDistributor = useCallback(
    async ({ name }: Distributor) => {
      if (confirm(t('confirm_remove_distributor', { distributor: name, place: system.sysId }))) {
        await patchSystem({
          sysId: system.sysId,
          distributorId: null,
        });
        await refreshData();
      }
    },
    [system.sysId, refreshData, t],
  );

  const handleAddDistributor = useCallback(
    async (distributorId: string | null) => {
      return patchSystem({
        sysId: system.sysId,
        distributorId: distributorId,
      }).then(refreshData);
    },
    [system.sysId, refreshData],
  );

  const handleRemoveSystem = useCallback(() => {
    if (confirm(t('confirm_deallocate_system', { system: system.sysId }))) {
      if (setIsSaving) setIsSaving(true);

      patchSystem({
        sysId: system.sysId,
        orgId: null,
        siteId: null,
      })
        .then(refreshData)
        .catch(() => {
          if (setIsSaving) setIsSaving(false);
        });
    }
  }, [system, refreshData, t, setIsSaving]);

  const textColor = useMemo(() => (disabled ? 'text-gray-400' : 'text-blue-800'), [disabled]);
  return (
    <React.Fragment>
      <div className="flex flex-row gap-2 items-center h-11">
        {bulkMove && onChecked && (
          <Checkbox
            checked={!!checked}
            disabled={disabled}
            onChangeValue={handleOnChangeValue}
            className={`${textColor} w-5 h-5 cursor-pointer`}
          />
        )}
        {error && (
          <div data-tip={error}>
            <ExclamationIcon className="text-red-600 w-5 h-5" />
          </div>
        )}
        <CubeIcon className={`w-5 h-5 ${textColor}`} />
        <Text overrideColor={true} textClassName={textColor} data-pwid={`${dataPwid}-${system.sysId}`}>
          {system.sysId}
        </Text>
        <Distributors
          selectedDistributors={system.distributor ? [system.distributor] : undefined}
          parentDistributors={parentDistributors}
          parentName={system.sysId}
          onAdd={handleAddDistributor}
          onRemove={handleRemoveDistributor}
          showAdd={!system.distributor}
          canEdit={hasPermissions(PERMISSIONS.dashboard.systems.updateDistributor) && !bulkMove}
          data-pwid={`${system.sysId}-distributors`}
        />
        {isSaving && <Loading type="small" />}
      </div>
      <div className="flex flex-1 flex-row gap-1 justify-end">
        {!bulkMove && hasPermissions(PERMISSIONS.dashboard.systems.update) && (
          <Link href={`/systems/${system.sysId}/edit`}>
            <PencilIcon
              data-tip={t('edit_asset', { asset: system.sysId })}
              className={`${textColor} w-5 h-5 cursor-pointer`}
            />
          </Link>
        )}
        {!bulkMove && showRemove && (
          <XIcon
            className={`${textColor} w-5 h-5 cursor-pointer`}
            onClick={handleRemoveSystem}
            data-tip={t('deallocate', { system: system.sysId })}
          />
        )}
        {handleUndoSystem && (
          <XIcon className="w-5 h-5 cursor-pointer" onClick={() => handleUndoSystem(system)} data-tip={t('Undo')} />
        )}
      </div>
    </React.Fragment>
  );
}
